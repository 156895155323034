import { BarChart, PieChart } from "@mui/x-charts";
import { DashboardData } from "../../../services/swagger";
import { Box, Paper, Typography, useTheme } from "@mui/material";

interface DashboardStatusOverviewProps {
  data: DashboardData;
}

export default function DashboardStatusOverview(
  props: DashboardStatusOverviewProps
) {
  const { data } = props;
  const statusOverview = data.statusOverview;
  const theme = useTheme();
  return (
    <Paper sx={{ bgcolor: "background.default" }}>
      <Typography
        color="primary"
        variant="h3"
        fontSize={20}
        fontWeight="bold"
        pb={1}
        pt={4}
        pl={4}
      >
        Tickets Status
      </Typography>
      <Box width={1} mb={2} pl={8}>
        <BarChart
          layout="horizontal"
          series={[
            {
              data: statusOverview.map((status) => status.count),
            },
          ]}
          barLabel="value"
          sx={{}}
          yAxis={[
            {
              tickSize: 1,
              data: statusOverview.map((status) => status.label),
              position: "right",
              scaleType: "band",
              colorMap: {
                type: "ordinal",
                colors: [theme.palette.divider, theme.palette.primary.main],
              },
              disableTicks: true,
            },
          ]}
          borderRadius={8}
          xAxis={[{ tickMinStep: 1, disableTicks: true }]}
          height={300}
        />
      </Box>
    </Paper>
  );
}
