import { Dialog, Stack } from "@mui/material";
import { useState } from "react";
import { DropDownItem } from "../molecules/Dropdown";
import FormInput from "../molecules/FormInput";
import useRefresh from "../../hooks/useRefresh";
import FormTemplate from "../templates/FormTemplate";
import { UserRole } from "../../services/swagger";
import { UserService } from "../../services/api";

interface CreateUserDialogProps {
  open: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
}

export const roles: DropDownItem[] = [
  { name: "User", value: "user" },
  { name: "Agent", value: "agent" },
  { name: "Admin", value: "admin" },
];

export default function CreateUserDialog(props: CreateUserDialogProps) {
  const refresh = useRefresh();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState<UserRole>("user");

  const handleSubmit = async () => {
    const res = await UserService.createUser({
      createUserBody: {
        email,
        name,
        phone,
        company,
        password,
        role,
        username,
      },
    });
    if (res?.data?.success) {
      refresh();
      props.onClose?.();
    }
  };
  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <FormTemplate
        title="Create User"
        onSubmit={handleSubmit}
        onCancel={props.onClose}
      >
        <Stack spacing={2} minWidth={500}>
          <FormInput
            field="role"
            label="Role"
            onChange={(v) => {
              setRole(v as UserRole);
            }}
            type="list"
            required
            value={role}
            list={roles}
          />
          <FormInput
            field="email"
            label="Email"
            onChange={(v) => {
              setEmail(v as string);
            }}
            placeholder="ex. john.doe@email.com"
            type="string"
            inputType="email"
            value={email}
            required
          />
          <FormInput
            field="username"
            label="Username"
            value={username}
            placeholder="Username"
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setUsername(v);
            }}
          />
          <FormInput
            field="company"
            label="Company"
            value={company}
            placeholder="Company"
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setCompany(v);
            }}
          />
          <FormInput
            field="phone"
            label="Phone"
            value={phone}
            placeholder="Phone"
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setPhone(v);
            }}
          />
          <FormInput
            field="name"
            label="Full Name"
            value={name}
            placeholder="Full Name"
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setName(v);
            }}
          />
          <FormInput
            field="password"
            label="Password"
            value={password}
            placeholder="Password"
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setPassword(v);
            }}
          />
        </Stack>
      </FormTemplate>
    </Dialog>
  );
}
