import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import FormInput from "../../molecules/FormInput";
import { FormEvent, useEffect, useState } from "react";
import { Stack } from "@mui/system";
import {
  Department,
  Ticket,
  TicketPriorityEnum,
  TicketStatusEnum,
} from "../../../services/swagger";
import { DropDownItem } from "../../molecules/Dropdown";
import { useGlobalContext } from "../../../contexts/Global";
import AvatarBanner from "../../molecules/AvatarBanner";
import { DepartmentService, TicketService } from "../../../services/api";
import useConfirm from "../../../hooks/useConfirm";
import useRefresh from "../../../hooks/useRefresh";
import Authorized from "../../atoms/Authorized";
import { useNavigate } from "react-router";
import DeleteButton from "../../atoms/DeleteButton";

const priorities: DropDownItem[] = [
  { name: "Low", value: "low" },
  { name: "Medium", value: "medium" },
  { name: "High", value: "high" },
];

const statuses: DropDownItem[] = [
  { name: "Not reviewed", value: "Not reviewed" },
  { name: "Incomplete", value: "Incomplete" },
  { name: "Pending confirmation", value: "Pending confirmation" },
  { name: "Complete", value: "Complete" },
];

interface TicketInfoProps {
  ticket: Ticket;
}

export default function TicketInfo(props: TicketInfoProps) {
  const navigate = useNavigate();
  const { context } = useGlobalContext();
  const { ticket } = props;
  const [title, setTitle] = useState(ticket.title);
  const [location, setLocation] = useState(ticket.city || ticket.location);
  const [department, setDepartment] = useState<string>(
    ticket.department as unknown as string
  );
  const [priority, setPriority] = useState<TicketPriorityEnum>(ticket.priority);
  const [status, setStatus] = useState<TicketStatusEnum>(ticket.status);
  const [departments, setDepartments] = useState<Department[]>([]);
  const { confirm, ConfirmDialog } = useConfirm();
  const refresh = useRefresh();

  const loadDepartments = async () => {
    const res = await DepartmentService.getDepartmentMiniList();
    if (!res?.data?.data) return;
    setDepartments(res.data.data);
  };
  useEffect(() => {
    loadDepartments();
  }, []);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    confirm(async () => {
      if (!ticket?._id) return;
      const res = await TicketService.updateTicket({
        id: ticket._id,
        updateTicketBody: {
          location,
          priority,
          status,
          title,
          departmentId: department,
        },
      });
      if (res?.data?.success) refresh();
    });
  };

  const handleDelete = async () => {
    if (!ticket?._id) return;
    const res = await TicketService.deleteTicket({
      id: ticket._id,
    });
    if (res.data.success) navigate(-1);
  };

  return (
    <Paper
      sx={{ p: 2, bgcolor: "background.default" }}
      component="form"
      onSubmit={handleSubmit}
    >
      <ConfirmDialog />
      <Stack spacing={2}>
        <Box>
          <AvatarBanner size={72}>{context.user?.name || ""}</AvatarBanner>
          <Typography fontSize="large" textAlign="center" fontWeight={600}>
            {ticket.user?.name || ticket.person}
          </Typography>
          <Typography align="center" color="text.secondary">
            {ticket.user?.company || ticket.company}
          </Typography>
        </Box>
        <Divider />
        <FormInput
          role="agent"
          field="title"
          label="Title"
          type="string"
          placeholder="New Title"
          onChange={(v) => setTitle(v as string)}
          value={title}
          required
        />
        <FormInput
          role="agent"
          field="status"
          label="Status"
          onChange={(v) => setStatus(v as TicketStatusEnum)}
          type="list"
          value={status}
          list={statuses}
          required
        />
        <FormInput
          role="agent"
          field="priority"
          label="Priority"
          onChange={(v) => setPriority(v as TicketPriorityEnum)}
          type="list"
          value={priority}
          list={priorities}
          required
        />
        <FormInput
          role="agent"
          field="departmentId"
          label="Department"
          type="list"
          list={departments.map((department) => {
            return {
              name: department.name,
              value: department._id || "",
            };
          })}
          onChange={(v) => setDepartment(v as string)}
          value={department}
          required
        />
        <FormInput
          role="agent"
          field="location"
          label="Location"
          type="string"
          placeholder="ex. Office"
          onChange={(v) => setLocation(v as string)}
          value={location}
        />
        <Authorized role="agent">
          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="success"
              type="submit"
              sx={{ flexGrow: 1 }}
            >
              Update
            </Button>
            <Authorized role="admin">
              <DeleteButton
                avatarProps={{
                  sx: { bgcolor: "error.main", height: 35, width: 35 },
                }}
                onClick={() => confirm(handleDelete)}
              />
            </Authorized>
          </Stack>
        </Authorized>
      </Stack>
    </Paper>
  );
}
