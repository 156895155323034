import { Box, Button, Divider, Paper, Typography } from "@mui/material";
import FormInput from "../../molecules/FormInput";
import { useEffect, useState } from "react";
import { Stack } from "@mui/system";
import { Department, TicketPriorityEnum } from "../../../services/swagger";
import { DropDownItem } from "../../molecules/Dropdown";
import { useGlobalContext } from "../../../contexts/Global";
import AvatarBanner from "../../molecules/AvatarBanner";
import { DepartmentService } from "../../../services/api";

const priorities: DropDownItem[] = [
  { name: "Low", value: "low" },
  { name: "Medium", value: "medium" },
  { name: "High", value: "high" },
];

export interface TicketSideFields {
  location: string;
  voucher: string;
  priority: TicketPriorityEnum;
  departmentId: string;
}

interface TicketSideProps {
  onChange: (fields: TicketSideFields) => void;
}

export default function TicketSide(props: TicketSideProps) {
  const { context } = useGlobalContext();
  const [voucher, setVoucher] = useState("");
  const [location, setLocation] = useState("");
  const [priority, setPriority] = useState<TicketPriorityEnum>("medium");
  const [departmentId, setDepartmentId] = useState("");
  const [departments, setDepartments] = useState<Department[]>([]);

  const loadDepartments = async () => {
    const res = await DepartmentService.getDepartmentMiniList();
    if (!res?.data?.data) return;
    setDepartments(res.data.data);
  };

  useEffect(() => {
    props.onChange({
      location,
      voucher,
      priority,
      departmentId,
    });
  }, [voucher, priority, location, departmentId]);

  useEffect(() => {
    loadDepartments();
  }, []);

  return (
    <Paper sx={{ p: 2, bgcolor: "background.default" }}>
      <Stack spacing={2}>
        <Box>
          <AvatarBanner size={72}>{context.user?.name || ""}</AvatarBanner>
          <Typography fontSize="large" textAlign="center" fontWeight={600}>
            {context.user?.name}
          </Typography>
          <Typography align="center" color="text.secondary">
            {context.user?.company}
          </Typography>
        </Box>
        <Divider />
        <FormInput
          field="priority"
          label="Priority"
          onChange={(v) => setPriority(v as TicketPriorityEnum)}
          type="list"
          value={priority}
          list={priorities}
          required
        />
        <FormInput
          field="location"
          label="Location"
          type="string"
          placeholder="ex. Office"
          onChange={(v) => setLocation(v as string)}
          value={location}
        />
        <FormInput
          field="departmentId"
          label="Department"
          type="list"
          list={departments.map((department) => {
            return {
              name: department.name,
              value: department._id || "",
            };
          })}
          onChange={(v) => setDepartmentId(v as string)}
          value={departmentId}
          required
        />
        <FormInput
          field="voucher"
          label="Voucher"
          onChange={(v) => setVoucher(v as TicketPriorityEnum)}
          type="string"
          value={voucher}
          placeholder="XXXX-XXXX-XXXX-XXXX"
        />
        <Button variant="contained" color="success" type="submit">
          Create Ticket
        </Button>
      </Stack>
    </Paper>
  );
}
