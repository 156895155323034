interface FormatMillisecondsOptions {
  ignoreMilliseconds?: boolean;
}

export function formatMilliseconds(
  milliseconds: number,
  options?: FormatMillisecondsOptions
) {
  let ms = milliseconds % 1000;
  let seconds = Math.floor((milliseconds / 1000) % 60);
  let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
  let days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

  let formattedTime = "";

  if (days > 0) {
    formattedTime += `${days}:`;
  }

  if (hours > 0 || days > 0) {
    formattedTime += `${hours.toString()}:`;
  }

  if (minutes > 0 || hours > 0 || days > 0) {
    formattedTime += `${minutes.toString()}:`;
  }

  if (seconds > 0 || minutes > 0 || hours > 0 || days > 0) {
    formattedTime += `${seconds.toString()}`;
  }

  if (!options?.ignoreMilliseconds)
    formattedTime += `.${ms.toString().padStart(3, "0")}`;

  return formattedTime;
}
