import { useNavigate, useParams } from "react-router";
import ManagementTemplate from "../../templates/ManagementTemplate";
import { Divider, Stack, Typography } from "@mui/material";
import { Filter, User, UserRole } from "../../../services/swagger";
import { useEffect, useState } from "react";
import { UserService } from "../../../services/api";
import useRefresh from "../../../hooks/useRefresh";
import FormInput from "../../molecules/FormInput";
import FormTemplate from "../../templates/FormTemplate";
import { roles } from "../../organisms/CreateUserDialog";
import useConfirm from "../../../hooks/useConfirm";

export default function EditUserPage() {
  const { _id } = useParams();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState<UserRole>("user");
  const [user, setUser] = useState<User | null>();

  const loadUser = async () => {
    const filters: Filter[] = [
      { field: "_id", operator: "eq", type: "id", value: _id },
    ];
    const res = await UserService.getUserList({
      limit: 1,
      skip: 0,
      filters: JSON.stringify(filters),
    });
    if (res?.data?.data?.docs?.length === 1) setUser(res.data.data.docs[0]);
  };

  const refresh = useRefresh(loadUser);

  useEffect(() => {
    if (!user) return;
    setName(user.name);
    setCompany(user.company);
    setPhone(user.phone);
    setRole(user.role);
  }, [user]);

  useEffect(() => {
    loadUser();
  }, []);

  const handleCancel = () => {
    navigate("/m/user");
  };

  const handleSubmit = async () => {
    if (!_id) return;
    const res = await UserService.updateUser({
      id: _id,
      updateUserBody: {
        name,
        company,
        phone,
        password: newPassword,
        role,
      },
    });
    if (res?.data?.success) navigate("/m/user");
  };

  const { confirm: confirmSubmit, ConfirmDialog } = useConfirm();

  return (
    <ManagementTemplate paper>
      <ConfirmDialog />
      <FormTemplate
        title={`Edit ${user?.name}`}
        onSubmit={() => confirmSubmit(handleSubmit)}
        onCancel={handleCancel}
      >
        <Stack spacing={2}>
          <FormInput
            field="role"
            label="Role"
            onChange={(v) => {
              setRole(v as UserRole);
            }}
            type="list"
            required
            value={role}
            list={roles}
          />
          <FormInput
            field="email"
            label="Email"
            onChange={() => {}}
            placeholder="ex. john.doe@email.com"
            type="string"
            inputType="email"
            readOnly
            value={user?.email}
            required
          />
          <FormInput
            field="username"
            label="Username"
            readOnly
            value={user?.username}
            placeholder="Username"
            required
            type="string"
            onChange={() => {}}
          />
          <FormInput
            field="name"
            label="Full Name"
            value={name}
            placeholder="Full Name"
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setName(v);
            }}
          />
          <FormInput
            field="company"
            label="Company"
            value={company}
            placeholder="Company"
            required
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setCompany(v);
            }}
          />
          <FormInput
            field="phone"
            label="Phone"
            value={phone}
            placeholder="Phone"
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setPhone(v);
            }}
          />
          <FormInput
            field="password"
            label="New Password"
            value={newPassword}
            placeholder="New Password"
            type="string"
            onChange={(v) => {
              if (typeof v === "string") setNewPassword(v);
            }}
          />
        </Stack>
      </FormTemplate>
    </ManagementTemplate>
  );
}
