/* tslint:disable */
/* eslint-disable */
/**
 * support
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * A citation within the message that points to a specific quote from a specific File associated with the assistant or the message. Generated when the assistant uses the \"file_search\" tool to search files.
 * @export
 * @interface Annotation
 */
export interface Annotation {
    /**
     * 
     * @type {number}
     * @memberof Annotation
     */
    'end_index': number;
    /**
     * 
     * @type {FileCitationAnnotationFileCitation}
     * @memberof Annotation
     */
    'file_citation': FileCitationAnnotationFileCitation;
    /**
     * 
     * @type {number}
     * @memberof Annotation
     */
    'start_index': number;
    /**
     * The text in the message content that needs to be replaced.
     * @type {string}
     * @memberof Annotation
     */
    'text': string;
    /**
     * Always `file_citation`.
     * @type {string}
     * @memberof Annotation
     */
    'type': AnnotationTypeEnum;
    /**
     * 
     * @type {FilePathAnnotationFilePath}
     * @memberof Annotation
     */
    'file_path': FilePathAnnotationFilePath;
}

export const AnnotationTypeEnum = {
    Citation: 'file_citation',
    Path: 'file_path'
} as const;

export type AnnotationTypeEnum = typeof AnnotationTypeEnum[keyof typeof AnnotationTypeEnum];

/**
 * 
 * @export
 * @interface AppSettings
 */
export interface AppSettings {
    /**
     * 
     * @type {SMTPSettings}
     * @memberof AppSettings
     */
    'smtp': SMTPSettings;
    /**
     * 
     * @type {FirewallSettings}
     * @memberof AppSettings
     */
    'firewall': FirewallSettings;
    /**
     * 
     * @type {OpenAISettings}
     * @memberof AppSettings
     */
    'openai': OpenAISettings;
    /**
     * 
     * @type {SAPSettings}
     * @memberof AppSettings
     */
    'sap': SAPSettings;
    /**
     * 
     * @type {FTPSettings}
     * @memberof AppSettings
     */
    'ftp': FTPSettings;
    /**
     * 
     * @type {AppSettingsForwardEmails}
     * @memberof AppSettings
     */
    'forwardEmails': AppSettingsForwardEmails;
    /**
     * 
     * @type {AppSettingsMessages}
     * @memberof AppSettings
     */
    'messages': AppSettingsMessages;
}
/**
 * 
 * @export
 * @interface AppSettingsForwardEmails
 */
export interface AppSettingsForwardEmails {
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'vouchers': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'subscriptions': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'sap': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'qnap': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'users': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'firewall': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'itemReminder': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'items': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'tasks': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'customerTickets': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppSettingsForwardEmails
     */
    'tickets': Array<string>;
}
/**
 * 
 * @export
 * @interface AppSettingsMessages
 */
export interface AppSettingsMessages {
    /**
     * 
     * @type {VoucherMessages}
     * @memberof AppSettingsMessages
     */
    'vouchers': VoucherMessages;
    /**
     * 
     * @type {SubscriptionMessages}
     * @memberof AppSettingsMessages
     */
    'subscriptions': SubscriptionMessages;
    /**
     * 
     * @type {SapMessages}
     * @memberof AppSettingsMessages
     */
    'sap': SapMessages;
    /**
     * 
     * @type {QnapMessages}
     * @memberof AppSettingsMessages
     */
    'qnap': QnapMessages;
    /**
     * 
     * @type {UserMessages}
     * @memberof AppSettingsMessages
     */
    'users': UserMessages;
    /**
     * 
     * @type {ItemMessages}
     * @memberof AppSettingsMessages
     */
    'items': ItemMessages;
    /**
     * 
     * @type {FirewallMessages}
     * @memberof AppSettingsMessages
     */
    'firewall': FirewallMessages;
    /**
     * 
     * @type {TaskMessages}
     * @memberof AppSettingsMessages
     */
    'tasks': TaskMessages;
    /**
     * 
     * @type {TicketMessages}
     * @memberof AppSettingsMessages
     */
    'tickets': TicketMessages;
}
/**
 * 
 * @export
 * @interface Attachment
 */
export interface Attachment {
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'name': string;
    /**
     * 
     * @type {AttachmentType}
     * @memberof Attachment
     */
    'type': AttachmentType;
    /**
     * 
     * @type {string}
     * @memberof Attachment
     */
    'path': string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const AttachmentType = {
    Image: 'image',
    Pdf: 'pdf',
    Other: 'other'
} as const;

export type AttachmentType = typeof AttachmentType[keyof typeof AttachmentType];


/**
 * 
 * @export
 * @interface AuthorizationData
 */
export interface AuthorizationData {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationData
     */
    'token': string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizationData
     */
    'mustActivate': boolean;
    /**
     * 
     * @type {User}
     * @memberof AuthorizationData
     */
    'user': User;
}
/**
 * 
 * @export
 * @interface CreateAskAiBody
 */
export interface CreateAskAiBody {
    /**
     * 
     * @type {string}
     * @memberof CreateAskAiBody
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAskAiBody
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateAssistantBody
 */
export interface CreateAssistantBody {
    /**
     * 
     * @type {string}
     * @memberof CreateAssistantBody
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof CreateAssistantBody
     */
    'threadId': string;
}
/**
 * 
 * @export
 * @interface CreateDepartmentBody
 */
export interface CreateDepartmentBody {
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDepartmentBody
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateDepartmentBody
     */
    'users': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDepartmentBody
     */
    'administrative': boolean;
}
/**
 * 
 * @export
 * @interface CreateEventBody
 */
export interface CreateEventBody {
    /**
     * 
     * @type {string}
     * @memberof CreateEventBody
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventBody
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventBody
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventBody
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventBody
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof CreateEventBody
     */
    'ticketId': string;
}
/**
 * 
 * @export
 * @interface CreateItemBody
 */
export interface CreateItemBody {
    /**
     * 
     * @type {string}
     * @memberof CreateItemBody
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof CreateItemBody
     */
    'type': CreateItemBodyTypeEnum;
    /**
     * 
     * @type {CreateItemBodyLocation}
     * @memberof CreateItemBody
     */
    'location'?: CreateItemBodyLocation;
    /**
     * 
     * @type {string}
     * @memberof CreateItemBody
     */
    'docRef': string;
    /**
     * 
     * @type {CreateItemBodyHandIn}
     * @memberof CreateItemBody
     */
    'handIn'?: CreateItemBodyHandIn;
}

export const CreateItemBodyTypeEnum = {
    Invoice: 'invoice',
    Delivery: 'delivery'
} as const;

export type CreateItemBodyTypeEnum = typeof CreateItemBodyTypeEnum[keyof typeof CreateItemBodyTypeEnum];

/**
 * 
 * @export
 * @interface CreateItemBodyHandIn
 */
export interface CreateItemBodyHandIn {
    /**
     * 
     * @type {string}
     * @memberof CreateItemBodyHandIn
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface CreateItemBodyLocation
 */
export interface CreateItemBodyLocation {
    /**
     * 
     * @type {string}
     * @memberof CreateItemBodyLocation
     */
    'city': string;
    /**
     * 
     * @type {number}
     * @memberof CreateItemBodyLocation
     */
    'latitude': number;
    /**
     * 
     * @type {number}
     * @memberof CreateItemBodyLocation
     */
    'longitude': number;
    /**
     * 
     * @type {string}
     * @memberof CreateItemBodyLocation
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface CreateNotificationSubscriptionBody
 */
export interface CreateNotificationSubscriptionBody {
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationSubscriptionBody
     */
    'browserId': string;
    /**
     * 
     * @type {CreateNotificationSubscriptionBodySubscription}
     * @memberof CreateNotificationSubscriptionBody
     */
    'subscription': CreateNotificationSubscriptionBodySubscription;
}
/**
 * 
 * @export
 * @interface CreateNotificationSubscriptionBodySubscription
 */
export interface CreateNotificationSubscriptionBodySubscription {
    /**
     * 
     * @type {CreateNotificationSubscriptionBodySubscriptionKeys}
     * @memberof CreateNotificationSubscriptionBodySubscription
     */
    'keys'?: CreateNotificationSubscriptionBodySubscriptionKeys;
    /**
     * 
     * @type {number}
     * @memberof CreateNotificationSubscriptionBodySubscription
     */
    'expirationTime'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationSubscriptionBodySubscription
     */
    'endpoint'?: string;
}
/**
 * 
 * @export
 * @interface CreateNotificationSubscriptionBodySubscriptionKeys
 */
export interface CreateNotificationSubscriptionBodySubscriptionKeys {
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationSubscriptionBodySubscriptionKeys
     */
    'auth': string;
    /**
     * 
     * @type {string}
     * @memberof CreateNotificationSubscriptionBodySubscriptionKeys
     */
    'p256dh': string;
}
/**
 * 
 * @export
 * @interface CreateRecordBody
 */
export interface CreateRecordBody {
    /**
     * 
     * @type {string}
     * @memberof CreateRecordBody
     */
    'type': CreateRecordBodyTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateRecordBody
     */
    'ip': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRecordBody
     */
    'subnet': string;
}

export const CreateRecordBodyTypeEnum = {
    Blacklist: 'blacklist',
    Whitelist: 'whitelist'
} as const;

export type CreateRecordBodyTypeEnum = typeof CreateRecordBodyTypeEnum[keyof typeof CreateRecordBodyTypeEnum];

/**
 * 
 * @export
 * @interface CreateRegisteredUser200Response
 */
export interface CreateRegisteredUser200Response {
    /**
     * 
     * @type {string}
     * @memberof CreateRegisteredUser200Response
     */
    'message': string;
    /**
     * 
     * @type {CreateUser200ResponseAnyOf1Data}
     * @memberof CreateRegisteredUser200Response
     */
    'data': CreateUser200ResponseAnyOf1Data;
    /**
     * 
     * @type {object}
     * @memberof CreateRegisteredUser200Response
     */
    'error': object;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRegisteredUser200Response
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface CreateRegisteredUser200ResponseAnyOf
 */
export interface CreateRegisteredUser200ResponseAnyOf {
    /**
     * 
     * @type {any}
     * @memberof CreateRegisteredUser200ResponseAnyOf
     */
    'message'?: any;
    /**
     * 
     * @type {any}
     * @memberof CreateRegisteredUser200ResponseAnyOf
     */
    'data'?: any;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof CreateRegisteredUser200ResponseAnyOf
     */
    'error': GetTicketServiceCallDeliveries200ResponseError;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRegisteredUser200ResponseAnyOf
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface CreateRegisteredUser200ResponseAnyOf1
 */
export interface CreateRegisteredUser200ResponseAnyOf1 {
    /**
     * 
     * @type {any}
     * @memberof CreateRegisteredUser200ResponseAnyOf1
     */
    'error'?: any;
    /**
     * 
     * @type {string}
     * @memberof CreateRegisteredUser200ResponseAnyOf1
     */
    'message': string;
    /**
     * 
     * @type {CreateUser200ResponseAnyOf1Data}
     * @memberof CreateRegisteredUser200ResponseAnyOf1
     */
    'data': CreateUser200ResponseAnyOf1Data;
    /**
     * 
     * @type {boolean}
     * @memberof CreateRegisteredUser200ResponseAnyOf1
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface CreateRegisteredUserBody
 */
export interface CreateRegisteredUserBody {
    /**
     * 
     * @type {string}
     * @memberof CreateRegisteredUserBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRegisteredUserBody
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRegisteredUserBody
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRegisteredUserBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRegisteredUserBody
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRegisteredUserBody
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface CreateServiceCallBody
 */
export interface CreateServiceCallBody {
    /**
     * 
     * @type {string}
     * @memberof CreateServiceCallBody
     */
    'contactCode': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceCallBody
     */
    'customerNo': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceCallBody
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceCallBody
     */
    'problemType': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceCallBody
     */
    'callType': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceCallBody
     */
    'technician': string;
    /**
     * 
     * @type {string}
     * @memberof CreateServiceCallBody
     */
    'priority': string;
}
/**
 * 
 * @export
 * @interface CreateSetupBody
 */
export interface CreateSetupBody {
    /**
     * 
     * @type {CreateSetupBodySettings}
     * @memberof CreateSetupBody
     */
    'settings': CreateSetupBodySettings;
    /**
     * 
     * @type {CreateSetupBodyAdmin}
     * @memberof CreateSetupBody
     */
    'admin': CreateSetupBodyAdmin;
}
/**
 * 
 * @export
 * @interface CreateSetupBodyAdmin
 */
export interface CreateSetupBodyAdmin {
    /**
     * 
     * @type {string}
     * @memberof CreateSetupBodyAdmin
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSetupBodyAdmin
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSetupBodyAdmin
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSetupBodyAdmin
     */
    'email': string;
}
/**
 * 
 * @export
 * @interface CreateSetupBodySettings
 */
export interface CreateSetupBodySettings {
    /**
     * 
     * @type {SAPSettings}
     * @memberof CreateSetupBodySettings
     */
    'sap'?: SAPSettings;
    /**
     * 
     * @type {FTPSettings}
     * @memberof CreateSetupBodySettings
     */
    'ftp'?: FTPSettings;
    /**
     * 
     * @type {OpenAISettings}
     * @memberof CreateSetupBodySettings
     */
    'openAi'?: OpenAISettings;
    /**
     * 
     * @type {SMTPSettings}
     * @memberof CreateSetupBodySettings
     */
    'smtp'?: SMTPSettings;
}
/**
 * 
 * @export
 * @interface CreateSummarizeBody
 */
export interface CreateSummarizeBody {
    /**
     * 
     * @type {string}
     * @memberof CreateSummarizeBody
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof CreateSummarizeBody
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateTaskBody
 */
export interface CreateTaskBody {
    /**
     * 
     * @type {string}
     * @memberof CreateTaskBody
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTaskBody
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface CreateTaskUserBody
 */
export interface CreateTaskUserBody {
    /**
     * 
     * @type {string}
     * @memberof CreateTaskUserBody
     */
    'userId': string;
}
/**
 * 
 * @export
 * @interface CreateTicketBody
 */
export interface CreateTicketBody {
    /**
     * 
     * @type {string}
     * @memberof CreateTicketBody
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketBody
     */
    'details': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketBody
     */
    'location': string;
    /**
     * 
     * @type {TicketPriorityEnum}
     * @memberof CreateTicketBody
     */
    'priority': TicketPriorityEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketBody
     */
    'departmentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketBody
     */
    'voucherId'?: string;
}


/**
 * 
 * @export
 * @interface CreateTicketResponseBody
 */
export interface CreateTicketResponseBody {
    /**
     * 
     * @type {string}
     * @memberof CreateTicketResponseBody
     */
    'ticketId': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTicketResponseBody
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface CreateTranslateBody
 */
export interface CreateTranslateBody {
    /**
     * 
     * @type {string}
     * @memberof CreateTranslateBody
     */
    'language': string;
    /**
     * 
     * @type {string}
     * @memberof CreateTranslateBody
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface CreateUser200Response
 */
export interface CreateUser200Response {
    /**
     * 
     * @type {CreateUser200ResponseAnyOf1Data}
     * @memberof CreateUser200Response
     */
    'data': CreateUser200ResponseAnyOf1Data;
    /**
     * 
     * @type {string}
     * @memberof CreateUser200Response
     */
    'message': string;
    /**
     * 
     * @type {object}
     * @memberof CreateUser200Response
     */
    'error': object;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUser200Response
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface CreateUser200ResponseAnyOf
 */
export interface CreateUser200ResponseAnyOf {
    /**
     * 
     * @type {any}
     * @memberof CreateUser200ResponseAnyOf
     */
    'data'?: any;
    /**
     * 
     * @type {any}
     * @memberof CreateUser200ResponseAnyOf
     */
    'message'?: any;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof CreateUser200ResponseAnyOf
     */
    'error': GetTicketServiceCallDeliveries200ResponseError;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUser200ResponseAnyOf
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface CreateUser200ResponseAnyOf1
 */
export interface CreateUser200ResponseAnyOf1 {
    /**
     * 
     * @type {any}
     * @memberof CreateUser200ResponseAnyOf1
     */
    'error'?: any;
    /**
     * 
     * @type {CreateUser200ResponseAnyOf1Data}
     * @memberof CreateUser200ResponseAnyOf1
     */
    'data': CreateUser200ResponseAnyOf1Data;
    /**
     * 
     * @type {string}
     * @memberof CreateUser200ResponseAnyOf1
     */
    'message': string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUser200ResponseAnyOf1
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface CreateUser200ResponseAnyOf1Data
 */
export interface CreateUser200ResponseAnyOf1Data {
    /**
     * 
     * @type {string}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'activationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'passwordChangeCode'?: string;
    /**
     * 
     * @type {Array<UserPushSubscriptionsInner>}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'pushSubscriptions': Array<UserPushSubscriptionsInner>;
    /**
     * 
     * @type {string}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'name': string;
    /**
     * 
     * @type {UserRole}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'role': UserRole;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateUser200ResponseAnyOf1Data
     */
    'deleted': boolean;
}


/**
 * 
 * @export
 * @interface CreateUserBody
 */
export interface CreateUserBody {
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserBody
     */
    'password': string;
    /**
     * 
     * @type {UserRole}
     * @memberof CreateUserBody
     */
    'role': UserRole;
}


/**
 * 
 * @export
 * @interface CreateVoucherBody
 */
export interface CreateVoucherBody {
    /**
     * 
     * @type {number}
     * @memberof CreateVoucherBody
     */
    'timeLimit': number;
}
/**
 * 
 * @export
 * @interface DashboardData
 */
export interface DashboardData {
    /**
     * 
     * @type {Array<ItemCount>}
     * @memberof DashboardData
     */
    'ticketCount': Array<ItemCount>;
    /**
     * 
     * @type {number}
     * @memberof DashboardData
     */
    'unsolvedTickets': number;
    /**
     * 
     * @type {number}
     * @memberof DashboardData
     */
    'solvedTickets': number;
    /**
     * 
     * @type {Array<ItemCount>}
     * @memberof DashboardData
     */
    'statusOverview': Array<ItemCount>;
    /**
     * 
     * @type {number}
     * @memberof DashboardData
     */
    'averageResolutionTime': number;
    /**
     * 
     * @type {number}
     * @memberof DashboardData
     */
    'averageFirstResponseTime': number;
    /**
     * 
     * @type {Array<Task>}
     * @memberof DashboardData
     */
    'activeTasks': Array<Task>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DateRange = {
    Week: 'week',
    Month: 'month',
    Year: 'year',
    AllTime: 'all_time'
} as const;

export type DateRange = typeof DateRange[keyof typeof DateRange];


/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'description': string;
    /**
     * 
     * @type {Array<LogUser>}
     * @memberof Department
     */
    'users': Array<LogUser>;
    /**
     * 
     * @type {boolean}
     * @memberof Department
     */
    'administrative': boolean;
    /**
     * 
     * @type {number}
     * @memberof Department
     */
    'date': number;
}
/**
 * 
 * @export
 * @interface EmailSetting
 */
export interface EmailSetting {
    /**
     * 
     * @type {boolean}
     * @memberof EmailSetting
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailSetting
     */
    'subject': string;
    /**
     * 
     * @type {string}
     * @memberof EmailSetting
     */
    'from': string;
    /**
     * 
     * @type {string}
     * @memberof EmailSetting
     */
    'direct': string;
    /**
     * 
     * @type {string}
     * @memberof EmailSetting
     */
    'forward': string;
}
/**
 * 
 * @export
 * @interface FTPSettings
 */
export interface FTPSettings {
    /**
     * 
     * @type {boolean}
     * @memberof FTPSettings
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof FTPSettings
     */
    'host': string;
    /**
     * 
     * @type {number}
     * @memberof FTPSettings
     */
    'port': number;
    /**
     * 
     * @type {string}
     * @memberof FTPSettings
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof FTPSettings
     */
    'password': string;
    /**
     * 
     * @type {boolean}
     * @memberof FTPSettings
     */
    'secure': boolean;
    /**
     * 
     * @type {string}
     * @memberof FTPSettings
     */
    'uploadPath': string;
}
/**
 * A citation within the message that points to a specific quote from a specific File associated with the assistant or the message. Generated when the assistant uses the \"file_search\" tool to search files.
 * @export
 * @interface FileCitationAnnotation
 */
export interface FileCitationAnnotation {
    /**
     * 
     * @type {number}
     * @memberof FileCitationAnnotation
     */
    'end_index': number;
    /**
     * 
     * @type {FileCitationAnnotationFileCitation}
     * @memberof FileCitationAnnotation
     */
    'file_citation': FileCitationAnnotationFileCitation;
    /**
     * 
     * @type {number}
     * @memberof FileCitationAnnotation
     */
    'start_index': number;
    /**
     * The text in the message content that needs to be replaced.
     * @type {string}
     * @memberof FileCitationAnnotation
     */
    'text': string;
    /**
     * Always `file_citation`.
     * @type {string}
     * @memberof FileCitationAnnotation
     */
    'type': FileCitationAnnotationTypeEnum;
}

export const FileCitationAnnotationTypeEnum = {
    FileCitation: 'file_citation'
} as const;

export type FileCitationAnnotationTypeEnum = typeof FileCitationAnnotationTypeEnum[keyof typeof FileCitationAnnotationTypeEnum];

/**
 * 
 * @export
 * @interface FileCitationAnnotationFileCitation
 */
export interface FileCitationAnnotationFileCitation {
    /**
     * The ID of the specific File the citation is from.
     * @type {string}
     * @memberof FileCitationAnnotationFileCitation
     */
    'file_id': string;
}
/**
 * Describes a file, directory or symbolic link.
 * @export
 * @interface FileInfo
 */
export interface FileInfo {
    /**
     * 
     * @type {string}
     * @memberof FileInfo
     */
    'name': string;
    /**
     * 
     * @type {FileType}
     * @memberof FileInfo
     */
    'type': FileType;
    /**
     * 
     * @type {number}
     * @memberof FileInfo
     */
    'size': number;
    /**
     * Unparsed, raw modification date as a string.  If `modifiedAt` is undefined, the FTP server you\'re connected to doesn\'t support the more modern MLSD command for machine-readable directory listings. The older command LIST is then used returning results that vary a lot between servers as the format hasn\'t been standardized. Here, directory listings and especially modification dates were meant to be human-readable first.  Be careful when still trying to parse this by yourself. Parsing dates from listings using LIST is unreliable. This library decides to offer parsed dates only when they\'re absolutely reliable and safe to use e.g. for comparisons.
     * @type {string}
     * @memberof FileInfo
     */
    'rawModifiedAt': string;
    /**
     * Parsed modification date.  Available if the FTP server supports the MLSD command. Only MLSD guarantees dates than can be reliably parsed with the correct timezone and a resolution down to seconds. See `rawModifiedAt` property for the unparsed date that is always available.
     * @type {string}
     * @memberof FileInfo
     */
    'modifiedAt'?: string;
    /**
     * 
     * @type {UnixPermissions}
     * @memberof FileInfo
     */
    'permissions'?: UnixPermissions;
    /**
     * Hard link count if available.
     * @type {number}
     * @memberof FileInfo
     */
    'hardLinkCount'?: number;
    /**
     * Link name for symbolic links if available.
     * @type {string}
     * @memberof FileInfo
     */
    'link'?: string;
    /**
     * Unix group if available.
     * @type {string}
     * @memberof FileInfo
     */
    'group'?: string;
    /**
     * Unix user if available.
     * @type {string}
     * @memberof FileInfo
     */
    'user'?: string;
    /**
     * Unique ID if available.
     * @type {string}
     * @memberof FileInfo
     */
    'uniqueID'?: string;
}


/**
 * A URL for the file that\'s generated when the assistant used the `code_interpreter` tool to generate a file.
 * @export
 * @interface FilePathAnnotation
 */
export interface FilePathAnnotation {
    /**
     * 
     * @type {number}
     * @memberof FilePathAnnotation
     */
    'end_index': number;
    /**
     * 
     * @type {FilePathAnnotationFilePath}
     * @memberof FilePathAnnotation
     */
    'file_path': FilePathAnnotationFilePath;
    /**
     * 
     * @type {number}
     * @memberof FilePathAnnotation
     */
    'start_index': number;
    /**
     * The text in the message content that needs to be replaced.
     * @type {string}
     * @memberof FilePathAnnotation
     */
    'text': string;
    /**
     * Always `file_path`.
     * @type {string}
     * @memberof FilePathAnnotation
     */
    'type': FilePathAnnotationTypeEnum;
}

export const FilePathAnnotationTypeEnum = {
    FilePath: 'file_path'
} as const;

export type FilePathAnnotationTypeEnum = typeof FilePathAnnotationTypeEnum[keyof typeof FilePathAnnotationTypeEnum];

/**
 * 
 * @export
 * @interface FilePathAnnotationFilePath
 */
export interface FilePathAnnotationFilePath {
    /**
     * The ID of the file that was generated.
     * @type {string}
     * @memberof FilePathAnnotationFilePath
     */
    'file_id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const FileType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;

export type FileType = typeof FileType[keyof typeof FileType];


/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'field': string;
    /**
     * 
     * @type {FilterValueType}
     * @memberof Filter
     */
    'type': FilterValueType;
    /**
     * 
     * @type {Operator}
     * @memberof Filter
     */
    'operator': Operator;
    /**
     * 
     * @type {any}
     * @memberof Filter
     */
    'value': any;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const FilterValueType = {
    Id: 'id',
    String: 'string',
    Number: 'number',
    Boolean: 'boolean',
    List: 'list',
    Date: 'date'
} as const;

export type FilterValueType = typeof FilterValueType[keyof typeof FilterValueType];


/**
 * 
 * @export
 * @interface FirewallMessages
 */
export interface FirewallMessages {
    /**
     * 
     * @type {EmailSetting}
     * @memberof FirewallMessages
     */
    'onBlacklist': EmailSetting;
}
/**
 * 
 * @export
 * @interface FirewallRecord
 */
export interface FirewallRecord {
    /**
     * 
     * @type {string}
     * @memberof FirewallRecord
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof FirewallRecord
     */
    'date': number;
    /**
     * 
     * @type {string}
     * @memberof FirewallRecord
     */
    'ip': string;
    /**
     * 
     * @type {string}
     * @memberof FirewallRecord
     */
    'subnet': string;
    /**
     * 
     * @type {string}
     * @memberof FirewallRecord
     */
    'type': FirewallRecordTypeEnum;
}

export const FirewallRecordTypeEnum = {
    Blacklist: 'blacklist',
    Whitelist: 'whitelist'
} as const;

export type FirewallRecordTypeEnum = typeof FirewallRecordTypeEnum[keyof typeof FirewallRecordTypeEnum];

/**
 * 
 * @export
 * @interface FirewallSettings
 */
export interface FirewallSettings {
    /**
     * 
     * @type {boolean}
     * @memberof FirewallSettings
     */
    'enabled': boolean;
    /**
     * 
     * @type {number}
     * @memberof FirewallSettings
     */
    'maximumAttempts': number;
}
/**
 * 
 * @export
 * @interface GetEventListQuery
 */
export interface GetEventListQuery {
    /**
     * 
     * @type {string}
     * @memberof GetEventListQuery
     */
    'fromDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetEventListQuery
     */
    'toDate': string;
    /**
     * 
     * @type {string}
     * @memberof GetEventListQuery
     */
    'search': string;
    /**
     * 
     * @type {string}
     * @memberof GetEventListQuery
     */
    'maxResults': string;
}
/**
 * 
 * @export
 * @interface GetFilterInterface200Response
 */
export interface GetFilterInterface200Response {
    /**
     * 
     * @type {string}
     * @memberof GetFilterInterface200Response
     */
    'field': string;
    /**
     * 
     * @type {FilterValueType}
     * @memberof GetFilterInterface200Response
     */
    'type': FilterValueType;
    /**
     * 
     * @type {Operator}
     * @memberof GetFilterInterface200Response
     */
    'operator': Operator;
    /**
     * 
     * @type {object}
     * @memberof GetFilterInterface200Response
     */
    'value': object;
    /**
     * 
     * @type {string}
     * @memberof GetFilterInterface200Response
     */
    'name': string;
    /**
     * 
     * @type {FilterValueType}
     * @memberof GetFilterInterface200Response
     */
    'valueType': FilterValueType;
    /**
     * 
     * @type {Array<QueryFilterTemplateDefaultInner>}
     * @memberof GetFilterInterface200Response
     */
    'default'?: Array<QueryFilterTemplateDefaultInner>;
    /**
     * 
     * @type {boolean}
     * @memberof GetFilterInterface200Response
     */
    'sortable'?: boolean;
    /**
     * 
     * @type {SortDirection}
     * @memberof GetFilterInterface200Response
     */
    'defaultSortDirection'?: SortDirection;
    /**
     * 
     * @type {Array<QueryFilterTemplateListOptionsInner>}
     * @memberof GetFilterInterface200Response
     */
    'listOptions'?: Array<QueryFilterTemplateListOptionsInner>;
}


/**
 * 
 * @export
 * @interface GetTicketServiceCallDeliveries200Response
 */
export interface GetTicketServiceCallDeliveries200Response {
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof GetTicketServiceCallDeliveries200Response
     */
    'error': GetTicketServiceCallDeliveries200ResponseError;
    /**
     * 
     * @type {boolean}
     * @memberof GetTicketServiceCallDeliveries200Response
     */
    'success': boolean;
}
/**
 * 
 * @export
 * @interface GetTicketServiceCallDeliveries200ResponseError
 */
export interface GetTicketServiceCallDeliveries200ResponseError {
    /**
     * 
     * @type {string}
     * @memberof GetTicketServiceCallDeliveries200ResponseError
     */
    'message': string;
    /**
     * 
     * @type {number}
     * @memberof GetTicketServiceCallDeliveries200ResponseError
     */
    'code': number;
}
/**
 * 
 * @export
 * @interface HandIn
 */
export interface HandIn {
    /**
     * 
     * @type {string}
     * @memberof HandIn
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof HandIn
     */
    'date': number;
    /**
     * 
     * @type {LogUser}
     * @memberof HandIn
     */
    'user': LogUser;
    /**
     * 
     * @type {string}
     * @memberof HandIn
     */
    'comment': string;
}
/**
 * 
 * @export
 * @interface ImageFile
 */
export interface ImageFile {
    /**
     * The [File](https://platform.openai.com/docs/api-reference/files) ID of the image in the message content. Set `purpose=\"vision\"` when uploading the File if you need to later display the file content.
     * @type {string}
     * @memberof ImageFile
     */
    'file_id': string;
    /**
     * Specifies the detail level of the image if specified by the user. `low` uses fewer tokens, you can opt in to high resolution using `high`.
     * @type {string}
     * @memberof ImageFile
     */
    'detail'?: ImageFileDetailEnum;
}

export const ImageFileDetailEnum = {
    Auto: 'auto',
    Low: 'low',
    High: 'high'
} as const;

export type ImageFileDetailEnum = typeof ImageFileDetailEnum[keyof typeof ImageFileDetailEnum];

/**
 * References an image [File](https://platform.openai.com/docs/api-reference/files) in the content of a message.
 * @export
 * @interface ImageFileContentBlock
 */
export interface ImageFileContentBlock {
    /**
     * 
     * @type {ImageFile}
     * @memberof ImageFileContentBlock
     */
    'image_file': ImageFile;
    /**
     * Always `image_file`.
     * @type {string}
     * @memberof ImageFileContentBlock
     */
    'type': ImageFileContentBlockTypeEnum;
}

export const ImageFileContentBlockTypeEnum = {
    ImageFile: 'image_file'
} as const;

export type ImageFileContentBlockTypeEnum = typeof ImageFileContentBlockTypeEnum[keyof typeof ImageFileContentBlockTypeEnum];

/**
 * 
 * @export
 * @interface ImageURL
 */
export interface ImageURL {
    /**
     * The external URL of the image, must be a supported image types: jpeg, jpg, png, gif, webp.
     * @type {string}
     * @memberof ImageURL
     */
    'url': string;
    /**
     * Specifies the detail level of the image. `low` uses fewer tokens, you can opt in to high resolution using `high`. Default value is `auto`
     * @type {string}
     * @memberof ImageURL
     */
    'detail'?: ImageURLDetailEnum;
}

export const ImageURLDetailEnum = {
    Auto: 'auto',
    Low: 'low',
    High: 'high'
} as const;

export type ImageURLDetailEnum = typeof ImageURLDetailEnum[keyof typeof ImageURLDetailEnum];

/**
 * References an image URL in the content of a message.
 * @export
 * @interface ImageURLContentBlock
 */
export interface ImageURLContentBlock {
    /**
     * 
     * @type {ImageURL}
     * @memberof ImageURLContentBlock
     */
    'image_url': ImageURL;
    /**
     * The type of the content part.
     * @type {string}
     * @memberof ImageURLContentBlock
     */
    'type': ImageURLContentBlockTypeEnum;
}

export const ImageURLContentBlockTypeEnum = {
    ImageUrl: 'image_url'
} as const;

export type ImageURLContentBlockTypeEnum = typeof ImageURLContentBlockTypeEnum[keyof typeof ImageURLContentBlockTypeEnum];

/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'description': string;
    /**
     * 
     * @type {LogUser}
     * @memberof Item
     */
    'user': LogUser;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'type': ItemTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Item
     */
    'date': number;
    /**
     * 
     * @type {Location}
     * @memberof Item
     */
    'location': Location;
    /**
     * 
     * @type {string}
     * @memberof Item
     */
    'docRef': string;
    /**
     * 
     * @type {HandIn}
     * @memberof Item
     */
    'handIn'?: HandIn;
    /**
     * 
     * @type {boolean}
     * @memberof Item
     */
    'warned': boolean;
}

export const ItemTypeEnum = {
    Invoice: 'invoice',
    Delivery: 'delivery'
} as const;

export type ItemTypeEnum = typeof ItemTypeEnum[keyof typeof ItemTypeEnum];

/**
 * 
 * @export
 * @interface ItemCount
 */
export interface ItemCount {
    /**
     * 
     * @type {string}
     * @memberof ItemCount
     */
    'label': string;
    /**
     * 
     * @type {number}
     * @memberof ItemCount
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface ItemMessages
 */
export interface ItemMessages {
    /**
     * 
     * @type {EmailSetting}
     * @memberof ItemMessages
     */
    'onCreate': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof ItemMessages
     */
    'onHandIn': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof ItemMessages
     */
    'onReminder': EmailSetting;
}
/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    'latitude': number;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'city': LocationCityEnum;
}

export const LocationCityEnum = {
    Lefkoa: 'Lefkoşa',
    Girne: 'Girne',
    Skele: 'İskele',
    Gazimausa: 'Gazimağusa',
    Lefke: 'Lefke',
    Gzelyurt: 'Güzelyurt'
} as const;

export type LocationCityEnum = typeof LocationCityEnum[keyof typeof LocationCityEnum];

/**
 * 
 * @export
 * @interface Log
 */
export interface Log {
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'username'?: string;
    /**
     * 
     * @type {LogUser}
     * @memberof Log
     */
    'user'?: LogUser;
    /**
     * 
     * @type {LogModel}
     * @memberof Log
     */
    'model'?: LogModel;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'object'?: string;
    /**
     * 
     * @type {string}
     * @memberof Log
     */
    'action': string;
    /**
     * 
     * @type {number}
     * @memberof Log
     */
    'date'?: number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const LogModel = {
    Ticket: 'ticket',
    User: 'user',
    Voucher: 'voucher',
    Item: 'item',
    Department: 'department'
} as const;

export type LogModel = typeof LogModel[keyof typeof LogModel];


/**
 * 
 * @export
 * @interface LogUser
 */
export interface LogUser {
    /**
     * 
     * @type {string}
     * @memberof LogUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LogUser
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof LogUser
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof LogUser
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof LogUser
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof LogUser
     */
    'activationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof LogUser
     */
    'passwordChangeCode'?: string;
    /**
     * 
     * @type {Array<UserPushSubscriptionsInner>}
     * @memberof LogUser
     */
    'pushSubscriptions': Array<UserPushSubscriptionsInner>;
    /**
     * 
     * @type {string}
     * @memberof LogUser
     */
    'name': string;
    /**
     * 
     * @type {UserRole}
     * @memberof LogUser
     */
    'role': UserRole;
    /**
     * 
     * @type {boolean}
     * @memberof LogUser
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LogUser
     */
    'deleted': boolean;
}


/**
 * 
 * @export
 * @interface LoginBody
 */
export interface LoginBody {
    /**
     * 
     * @type {string}
     * @memberof LoginBody
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof LoginBody
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface NotificationInstance
 */
export interface NotificationInstance {
    /**
     * 
     * @type {string}
     * @memberof NotificationInstance
     */
    '_id'?: string;
    /**
     * 
     * @type {NotificationInstanceNotification}
     * @memberof NotificationInstance
     */
    'notification': NotificationInstanceNotification;
    /**
     * 
     * @type {Array<User>}
     * @memberof NotificationInstance
     */
    'users': Array<User>;
    /**
     * 
     * @type {number}
     * @memberof NotificationInstance
     */
    'date': number;
}
/**
 * 
 * @export
 * @interface NotificationInstanceNotification
 */
export interface NotificationInstanceNotification {
    /**
     * 
     * @type {string}
     * @memberof NotificationInstanceNotification
     */
    'body': string;
    /**
     * 
     * @type {string}
     * @memberof NotificationInstanceNotification
     */
    'title': string;
}
/**
 * References an image [File](https://platform.openai.com/docs/api-reference/files) in the content of a message.
 * @export
 * @interface OpenAIBetaThreadsMessagesMessageContent
 */
export interface OpenAIBetaThreadsMessagesMessageContent {
    /**
     * 
     * @type {ImageFile}
     * @memberof OpenAIBetaThreadsMessagesMessageContent
     */
    'image_file': ImageFile;
    /**
     * Always `image_file`.
     * @type {string}
     * @memberof OpenAIBetaThreadsMessagesMessageContent
     */
    'type': OpenAIBetaThreadsMessagesMessageContentTypeEnum;
    /**
     * 
     * @type {ImageURL}
     * @memberof OpenAIBetaThreadsMessagesMessageContent
     */
    'image_url': ImageURL;
    /**
     * 
     * @type {Text}
     * @memberof OpenAIBetaThreadsMessagesMessageContent
     */
    'text': Text;
    /**
     * 
     * @type {string}
     * @memberof OpenAIBetaThreadsMessagesMessageContent
     */
    'refusal': string;
}

export const OpenAIBetaThreadsMessagesMessageContentTypeEnum = {
    ImageFile: 'image_file',
    ImageUrl: 'image_url',
    Text: 'text',
    Refusal: 'refusal'
} as const;

export type OpenAIBetaThreadsMessagesMessageContentTypeEnum = typeof OpenAIBetaThreadsMessagesMessageContentTypeEnum[keyof typeof OpenAIBetaThreadsMessagesMessageContentTypeEnum];

/**
 * 
 * @export
 * @interface OpenAISettings
 */
export interface OpenAISettings {
    /**
     * 
     * @type {boolean}
     * @memberof OpenAISettings
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof OpenAISettings
     */
    'apiKey': string;
    /**
     * 
     * @type {string}
     * @memberof OpenAISettings
     */
    'organizationId': string;
    /**
     * 
     * @type {string}
     * @memberof OpenAISettings
     */
    'assistantId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Operator = {
    Eq: 'eq',
    Ne: 'ne',
    Lt: 'lt',
    Lte: 'lte',
    Gt: 'gt',
    Gte: 'gte',
    Regex: 'regex'
} as const;

export type Operator = typeof Operator[keyof typeof Operator];


/**
 * 
 * @export
 * @interface OutputDTO
 */
export interface OutputDTO {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTO
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTO
     */
    'message'?: string;
    /**
     * 
     * @type {any}
     * @memberof OutputDTO
     */
    'data'?: any;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTO
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOAppSettings
 */
export interface OutputDTOAppSettings {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOAppSettings
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOAppSettings
     */
    'message'?: string;
    /**
     * 
     * @type {AppSettings}
     * @memberof OutputDTOAppSettings
     */
    'data'?: AppSettings;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOAppSettings
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOAuthorizationData
 */
export interface OutputDTOAuthorizationData {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOAuthorizationData
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOAuthorizationData
     */
    'message'?: string;
    /**
     * 
     * @type {AuthorizationData}
     * @memberof OutputDTOAuthorizationData
     */
    'data'?: AuthorizationData;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOAuthorizationData
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOBoolean
 */
export interface OutputDTOBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOBoolean
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOBoolean
     */
    'message'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOBoolean
     */
    'data'?: boolean;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOBoolean
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTODashboardData
 */
export interface OutputDTODashboardData {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTODashboardData
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTODashboardData
     */
    'message'?: string;
    /**
     * 
     * @type {DashboardData}
     * @memberof OutputDTODashboardData
     */
    'data'?: DashboardData;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTODashboardData
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTODepartment
 */
export interface OutputDTODepartment {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTODepartment
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTODepartment
     */
    'message'?: string;
    /**
     * 
     * @type {Department}
     * @memberof OutputDTODepartment
     */
    'data'?: Department;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTODepartment
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTODepartmentArray
 */
export interface OutputDTODepartmentArray {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTODepartmentArray
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTODepartmentArray
     */
    'message'?: string;
    /**
     * 
     * @type {Array<Department>}
     * @memberof OutputDTODepartmentArray
     */
    'data'?: Array<Department>;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTODepartmentArray
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOFileInfoArray
 */
export interface OutputDTOFileInfoArray {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOFileInfoArray
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOFileInfoArray
     */
    'message'?: string;
    /**
     * 
     * @type {Array<FileInfo>}
     * @memberof OutputDTOFileInfoArray
     */
    'data'?: Array<FileInfo>;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOFileInfoArray
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOFirewallRecord
 */
export interface OutputDTOFirewallRecord {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOFirewallRecord
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOFirewallRecord
     */
    'message'?: string;
    /**
     * 
     * @type {FirewallRecord}
     * @memberof OutputDTOFirewallRecord
     */
    'data'?: FirewallRecord;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOFirewallRecord
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOItem
 */
export interface OutputDTOItem {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOItem
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOItem
     */
    'message'?: string;
    /**
     * 
     * @type {Item}
     * @memberof OutputDTOItem
     */
    'data'?: Item;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOItem
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTONotificationInstance
 */
export interface OutputDTONotificationInstance {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTONotificationInstance
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTONotificationInstance
     */
    'message'?: string;
    /**
     * 
     * @type {NotificationInstance}
     * @memberof OutputDTONotificationInstance
     */
    'data'?: NotificationInstance;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTONotificationInstance
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOOpenAIBetaThreadsMessagesMessageContentArray
 */
export interface OutputDTOOpenAIBetaThreadsMessagesMessageContentArray {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOOpenAIBetaThreadsMessagesMessageContentArray
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOOpenAIBetaThreadsMessagesMessageContentArray
     */
    'message'?: string;
    /**
     * 
     * @type {Array<OpenAIBetaThreadsMessagesMessageContent>}
     * @memberof OutputDTOOpenAIBetaThreadsMessagesMessageContentArray
     */
    'data'?: Array<OpenAIBetaThreadsMessagesMessageContent>;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOOpenAIBetaThreadsMessagesMessageContentArray
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOPaginatedListDepartment
 */
export interface OutputDTOPaginatedListDepartment {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOPaginatedListDepartment
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOPaginatedListDepartment
     */
    'message'?: string;
    /**
     * 
     * @type {PaginatedListDepartment}
     * @memberof OutputDTOPaginatedListDepartment
     */
    'data'?: PaginatedListDepartment;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOPaginatedListDepartment
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOPaginatedListFirewallRecord
 */
export interface OutputDTOPaginatedListFirewallRecord {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOPaginatedListFirewallRecord
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOPaginatedListFirewallRecord
     */
    'message'?: string;
    /**
     * 
     * @type {PaginatedListFirewallRecord}
     * @memberof OutputDTOPaginatedListFirewallRecord
     */
    'data'?: PaginatedListFirewallRecord;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOPaginatedListFirewallRecord
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOPaginatedListItem
 */
export interface OutputDTOPaginatedListItem {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOPaginatedListItem
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOPaginatedListItem
     */
    'message'?: string;
    /**
     * 
     * @type {PaginatedListItem}
     * @memberof OutputDTOPaginatedListItem
     */
    'data'?: PaginatedListItem;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOPaginatedListItem
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOPaginatedListLog
 */
export interface OutputDTOPaginatedListLog {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOPaginatedListLog
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOPaginatedListLog
     */
    'message'?: string;
    /**
     * 
     * @type {PaginatedListLog}
     * @memberof OutputDTOPaginatedListLog
     */
    'data'?: PaginatedListLog;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOPaginatedListLog
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOPaginatedListNotificationInstance
 */
export interface OutputDTOPaginatedListNotificationInstance {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOPaginatedListNotificationInstance
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOPaginatedListNotificationInstance
     */
    'message'?: string;
    /**
     * 
     * @type {PaginatedListNotificationInstance}
     * @memberof OutputDTOPaginatedListNotificationInstance
     */
    'data'?: PaginatedListNotificationInstance;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOPaginatedListNotificationInstance
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOPaginatedListSubscription
 */
export interface OutputDTOPaginatedListSubscription {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOPaginatedListSubscription
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOPaginatedListSubscription
     */
    'message'?: string;
    /**
     * 
     * @type {PaginatedListSubscription}
     * @memberof OutputDTOPaginatedListSubscription
     */
    'data'?: PaginatedListSubscription;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOPaginatedListSubscription
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOPaginatedListTicket
 */
export interface OutputDTOPaginatedListTicket {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOPaginatedListTicket
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOPaginatedListTicket
     */
    'message'?: string;
    /**
     * 
     * @type {PaginatedListTicket}
     * @memberof OutputDTOPaginatedListTicket
     */
    'data'?: PaginatedListTicket;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOPaginatedListTicket
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOPaginatedListUser
 */
export interface OutputDTOPaginatedListUser {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOPaginatedListUser
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOPaginatedListUser
     */
    'message'?: string;
    /**
     * 
     * @type {PaginatedListUser}
     * @memberof OutputDTOPaginatedListUser
     */
    'data'?: PaginatedListUser;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOPaginatedListUser
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOPaginatedListVoucher
 */
export interface OutputDTOPaginatedListVoucher {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOPaginatedListVoucher
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOPaginatedListVoucher
     */
    'message'?: string;
    /**
     * 
     * @type {PaginatedListVoucher}
     * @memberof OutputDTOPaginatedListVoucher
     */
    'data'?: PaginatedListVoucher;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOPaginatedListVoucher
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOQueryFilterTemplateArray
 */
export interface OutputDTOQueryFilterTemplateArray {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOQueryFilterTemplateArray
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOQueryFilterTemplateArray
     */
    'message'?: string;
    /**
     * 
     * @type {Array<QueryFilterTemplate>}
     * @memberof OutputDTOQueryFilterTemplateArray
     */
    'data'?: Array<QueryFilterTemplate>;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOQueryFilterTemplateArray
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOString
 */
export interface OutputDTOString {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOString
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOString
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOString
     */
    'data'?: string;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOString
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOSubscription
 */
export interface OutputDTOSubscription {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOSubscription
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOSubscription
     */
    'message'?: string;
    /**
     * 
     * @type {Subscription}
     * @memberof OutputDTOSubscription
     */
    'data'?: Subscription;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOSubscription
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOTask
 */
export interface OutputDTOTask {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOTask
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOTask
     */
    'message'?: string;
    /**
     * 
     * @type {Task}
     * @memberof OutputDTOTask
     */
    'data'?: Task;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOTask
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOTicket
 */
export interface OutputDTOTicket {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOTicket
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOTicket
     */
    'message'?: string;
    /**
     * 
     * @type {Ticket}
     * @memberof OutputDTOTicket
     */
    'data'?: Ticket;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOTicket
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOUser
 */
export interface OutputDTOUser {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOUser
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOUser
     */
    'message'?: string;
    /**
     * 
     * @type {User}
     * @memberof OutputDTOUser
     */
    'data'?: User;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOUser
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface OutputDTOVoucher
 */
export interface OutputDTOVoucher {
    /**
     * 
     * @type {boolean}
     * @memberof OutputDTOVoucher
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof OutputDTOVoucher
     */
    'message'?: string;
    /**
     * 
     * @type {Voucher}
     * @memberof OutputDTOVoucher
     */
    'data'?: Voucher;
    /**
     * 
     * @type {GetTicketServiceCallDeliveries200ResponseError}
     * @memberof OutputDTOVoucher
     */
    'error'?: GetTicketServiceCallDeliveries200ResponseError;
}
/**
 * 
 * @export
 * @interface PaginatedListAny
 */
export interface PaginatedListAny {
    /**
     * 
     * @type {Array<any>}
     * @memberof PaginatedListAny
     */
    'docs': Array<any>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListAny
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PaginatedListDepartment
 */
export interface PaginatedListDepartment {
    /**
     * 
     * @type {Array<Department>}
     * @memberof PaginatedListDepartment
     */
    'docs': Array<Department>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListDepartment
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PaginatedListFirewallRecord
 */
export interface PaginatedListFirewallRecord {
    /**
     * 
     * @type {Array<FirewallRecord>}
     * @memberof PaginatedListFirewallRecord
     */
    'docs': Array<FirewallRecord>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListFirewallRecord
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PaginatedListItem
 */
export interface PaginatedListItem {
    /**
     * 
     * @type {Array<Item>}
     * @memberof PaginatedListItem
     */
    'docs': Array<Item>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListItem
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PaginatedListLog
 */
export interface PaginatedListLog {
    /**
     * 
     * @type {Array<Log>}
     * @memberof PaginatedListLog
     */
    'docs': Array<Log>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListLog
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PaginatedListNotificationInstance
 */
export interface PaginatedListNotificationInstance {
    /**
     * 
     * @type {Array<NotificationInstance>}
     * @memberof PaginatedListNotificationInstance
     */
    'docs': Array<NotificationInstance>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListNotificationInstance
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PaginatedListSubscription
 */
export interface PaginatedListSubscription {
    /**
     * 
     * @type {Array<Subscription>}
     * @memberof PaginatedListSubscription
     */
    'docs': Array<Subscription>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListSubscription
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PaginatedListTicket
 */
export interface PaginatedListTicket {
    /**
     * 
     * @type {Array<Ticket>}
     * @memberof PaginatedListTicket
     */
    'docs': Array<Ticket>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListTicket
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PaginatedListUser
 */
export interface PaginatedListUser {
    /**
     * 
     * @type {Array<User>}
     * @memberof PaginatedListUser
     */
    'docs': Array<User>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListUser
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PaginatedListVoucher
 */
export interface PaginatedListVoucher {
    /**
     * 
     * @type {Array<Voucher>}
     * @memberof PaginatedListVoucher
     */
    'docs': Array<Voucher>;
    /**
     * 
     * @type {number}
     * @memberof PaginatedListVoucher
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface PaginatedQuery
 */
export interface PaginatedQuery {
    /**
     * 
     * @type {string}
     * @memberof PaginatedQuery
     */
    'sortField'?: string;
    /**
     * 
     * @type {SortDirection}
     * @memberof PaginatedQuery
     */
    'sortDirection'?: SortDirection;
    /**
     * 
     * @type {string}
     * @memberof PaginatedQuery
     */
    'filters'?: string;
    /**
     * 
     * @type {number}
     * @memberof PaginatedQuery
     */
    'skip': number;
    /**
     * 
     * @type {number}
     * @memberof PaginatedQuery
     */
    'limit': number;
}


/**
 * From T, pick a set of properties whose keys are in the union K
 * @export
 * @interface PickLeanDocumentTExcludeKeyofLeanDocumentTExcludeKeyofDocumentIdOrIdOrVOr24isSingleNested
 */
export interface PickLeanDocumentTExcludeKeyofLeanDocumentTExcludeKeyofDocumentIdOrIdOrVOr24isSingleNested {
    /**
     * 
     * @type {object}
     * @memberof PickLeanDocumentTExcludeKeyofLeanDocumentTExcludeKeyofDocumentIdOrIdOrVOr24isSingleNested
     */
    '_id'?: object;
    /**
     * This documents __v.
     * @type {any}
     * @memberof PickLeanDocumentTExcludeKeyofLeanDocumentTExcludeKeyofDocumentIdOrIdOrVOr24isSingleNested
     */
    '__v'?: any;
    /**
     * The string version of this documents _id.
     * @type {any}
     * @memberof PickLeanDocumentTExcludeKeyofLeanDocumentTExcludeKeyofDocumentIdOrIdOrVOr24isSingleNested
     */
    'id'?: any;
}
/**
 * 
 * @export
 * @interface PushSubscriptionJSON
 */
export interface PushSubscriptionJSON {
    /**
     * 
     * @type {string}
     * @memberof PushSubscriptionJSON
     */
    'endpoint'?: string;
    /**
     * 
     * @type {number}
     * @memberof PushSubscriptionJSON
     */
    'expirationTime'?: number | null;
    /**
     * Construct a type with a set of properties K of type T
     * @type {object}
     * @memberof PushSubscriptionJSON
     */
    'keys'?: object;
}
/**
 * 
 * @export
 * @interface QnapMessages
 */
export interface QnapMessages {
    /**
     * 
     * @type {EmailSetting}
     * @memberof QnapMessages
     */
    'onFileUpload': EmailSetting;
}
/**
 * 
 * @export
 * @interface QueryFilterTemplate
 */
export interface QueryFilterTemplate {
    /**
     * 
     * @type {string}
     * @memberof QueryFilterTemplate
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof QueryFilterTemplate
     */
    'field': string;
    /**
     * 
     * @type {FilterValueType}
     * @memberof QueryFilterTemplate
     */
    'valueType': FilterValueType;
    /**
     * 
     * @type {Array<QueryFilterTemplateDefaultInner>}
     * @memberof QueryFilterTemplate
     */
    'default'?: Array<QueryFilterTemplateDefaultInner>;
    /**
     * 
     * @type {boolean}
     * @memberof QueryFilterTemplate
     */
    'sortable'?: boolean;
    /**
     * 
     * @type {SortDirection}
     * @memberof QueryFilterTemplate
     */
    'defaultSortDirection'?: SortDirection;
    /**
     * 
     * @type {Array<QueryFilterTemplateListOptionsInner>}
     * @memberof QueryFilterTemplate
     */
    'listOptions'?: Array<QueryFilterTemplateListOptionsInner>;
}


/**
 * 
 * @export
 * @interface QueryFilterTemplateDefaultInner
 */
export interface QueryFilterTemplateDefaultInner {
    /**
     * 
     * @type {any}
     * @memberof QueryFilterTemplateDefaultInner
     */
    'value': any;
    /**
     * 
     * @type {Operator}
     * @memberof QueryFilterTemplateDefaultInner
     */
    'operator': Operator;
}


/**
 * 
 * @export
 * @interface QueryFilterTemplateListOptionsInner
 */
export interface QueryFilterTemplateListOptionsInner {
    /**
     * 
     * @type {string}
     * @memberof QueryFilterTemplateListOptionsInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof QueryFilterTemplateListOptionsInner
     */
    'name': string;
}
/**
 * The refusal content generated by the assistant.
 * @export
 * @interface RefusalContentBlock
 */
export interface RefusalContentBlock {
    /**
     * 
     * @type {string}
     * @memberof RefusalContentBlock
     */
    'refusal': string;
    /**
     * Always `refusal`.
     * @type {string}
     * @memberof RefusalContentBlock
     */
    'type': RefusalContentBlockTypeEnum;
}

export const RefusalContentBlockTypeEnum = {
    Refusal: 'refusal'
} as const;

export type RefusalContentBlockTypeEnum = typeof RefusalContentBlockTypeEnum[keyof typeof RefusalContentBlockTypeEnum];

/**
 * 
 * @export
 * @interface SAPSettings
 */
export interface SAPSettings {
    /**
     * 
     * @type {boolean}
     * @memberof SAPSettings
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof SAPSettings
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SAPSettings
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SAPSettings
     */
    'database': string;
    /**
     * 
     * @type {string}
     * @memberof SAPSettings
     */
    'server': string;
}
/**
 * 
 * @export
 * @interface SMTPSettings
 */
export interface SMTPSettings {
    /**
     * 
     * @type {boolean}
     * @memberof SMTPSettings
     */
    'enabled': boolean;
    /**
     * 
     * @type {string}
     * @memberof SMTPSettings
     */
    'server': string;
    /**
     * 
     * @type {number}
     * @memberof SMTPSettings
     */
    'port': number;
    /**
     * 
     * @type {boolean}
     * @memberof SMTPSettings
     */
    'secure': boolean;
    /**
     * 
     * @type {string}
     * @memberof SMTPSettings
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof SMTPSettings
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof SMTPSettings
     */
    'from': string;
}
/**
 * 
 * @export
 * @interface SapMessages
 */
export interface SapMessages {
    /**
     * 
     * @type {EmailSetting}
     * @memberof SapMessages
     */
    'onServiceCallCreate': EmailSetting;
}
/**
 * 
 * @export
 * @interface ServiceCall
 */
export interface ServiceCall {
    /**
     * 
     * @type {string}
     * @memberof ServiceCall
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceCall
     */
    'date'?: number;
    /**
     * 
     * @type {string}
     * @memberof ServiceCall
     */
    'customer_no': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceCall
     */
    'contact_no': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceCall
     */
    'file_no': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceCall
     */
    'call_id': string;
    /**
     * 
     * @type {Array<ServiceExpense>}
     * @memberof ServiceCall
     */
    'expenses': Array<ServiceExpense>;
    /**
     * 
     * @type {Array<ServiceTime>}
     * @memberof ServiceCall
     */
    'times': Array<ServiceTime>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ServiceCall
     */
    'workers': Array<number>;
}
/**
 * 
 * @export
 * @interface ServiceExpense
 */
export interface ServiceExpense {
    /**
     * 
     * @type {string}
     * @memberof ServiceExpense
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceExpense
     */
    'date': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceExpense
     */
    'item_no': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceExpense
     */
    'description': string;
    /**
     * 
     * @type {number}
     * @memberof ServiceExpense
     */
    'quantity': number;
    /**
     * 
     * @type {string}
     * @memberof ServiceExpense
     */
    'serial_no': string;
}
/**
 * 
 * @export
 * @interface ServiceTime
 */
export interface ServiceTime {
    /**
     * 
     * @type {string}
     * @memberof ServiceTime
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceTime
     */
    'date': number;
    /**
     * 
     * @type {boolean}
     * @memberof ServiceTime
     */
    'work': boolean;
    /**
     * 
     * @type {string}
     * @memberof ServiceTime
     */
    'start': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTime
     */
    'end': string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTime
     */
    'remarks': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ServiceTime
     */
    'workers': Array<number>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SortDirection = {
    Asc: 'asc',
    Desc: 'desc',
    None: 'none'
} as const;

export type SortDirection = typeof SortDirection[keyof typeof SortDirection];


/**
 * 
 * @export
 * @interface Subscription
 */
export interface Subscription {
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    'orcp': number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    'invoice': number;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    'customer': number;
    /**
     * 
     * @type {string}
     * @memberof Subscription
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof Subscription
     */
    'endDate': number;
    /**
     * 
     * @type {boolean}
     * @memberof Subscription
     */
    'executed': boolean;
    /**
     * 
     * @type {Array<SubscriptionWarningsInner>}
     * @memberof Subscription
     */
    'warnings': Array<SubscriptionWarningsInner>;
    /**
     * 
     * @type {Array<UserUpdate>}
     * @memberof Subscription
     */
    'usersUpdated': Array<UserUpdate>;
}
/**
 * 
 * @export
 * @interface SubscriptionMessages
 */
export interface SubscriptionMessages {
    /**
     * 
     * @type {EmailSetting}
     * @memberof SubscriptionMessages
     */
    'onSubscriptionReminder': EmailSetting;
}
/**
 * 
 * @export
 * @interface SubscriptionWarningsInner
 */
export interface SubscriptionWarningsInner {
    /**
     * 
     * @type {boolean}
     * @memberof SubscriptionWarningsInner
     */
    'done': boolean;
    /**
     * 
     * @type {number}
     * @memberof SubscriptionWarningsInner
     */
    'time': number;
    /**
     * 
     * @type {string}
     * @memberof SubscriptionWarningsInner
     */
    '_id'?: string;
}
/**
 * 
 * @export
 * @interface Task
 */
export interface Task {
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    '_id'?: string;
    /**
     * 
     * @type {TaskTicket}
     * @memberof Task
     */
    'ticket': TaskTicket;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'description': string;
    /**
     * 
     * @type {Array<LogUser>}
     * @memberof Task
     */
    'users': Array<LogUser>;
    /**
     * 
     * @type {Array<TaskAssignment>}
     * @memberof Task
     */
    'assignments': Array<TaskAssignment>;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'startDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'endDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'cancelDate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Task
     */
    'cancelled': boolean;
    /**
     * 
     * @type {string}
     * @memberof Task
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof Task
     */
    'date'?: number;
}
/**
 * 
 * @export
 * @interface TaskAssignment
 */
export interface TaskAssignment {
    /**
     * 
     * @type {string}
     * @memberof TaskAssignment
     */
    '_id'?: string;
    /**
     * 
     * @type {TicketUser}
     * @memberof TaskAssignment
     */
    'user': TicketUser;
    /**
     * 
     * @type {string}
     * @memberof TaskAssignment
     */
    'type': TaskAssignmentTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof TaskAssignment
     */
    'date': number;
}

export const TaskAssignmentTypeEnum = {
    Assigned: 'assigned',
    Unassigned: 'unassigned'
} as const;

export type TaskAssignmentTypeEnum = typeof TaskAssignmentTypeEnum[keyof typeof TaskAssignmentTypeEnum];

/**
 * 
 * @export
 * @interface TaskMessages
 */
export interface TaskMessages {
    /**
     * 
     * @type {EmailSetting}
     * @memberof TaskMessages
     */
    'onCreate': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TaskMessages
     */
    'onUpdate': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TaskMessages
     */
    'onDelete': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TaskMessages
     */
    'onAssignment': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TaskMessages
     */
    'onUnassignment': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TaskMessages
     */
    'onCancellation': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TaskMessages
     */
    'onStart': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TaskMessages
     */
    'onEnd': EmailSetting;
}
/**
 * 
 * @export
 * @interface TaskTicket
 */
export interface TaskTicket {
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskTicket
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof TaskTicket
     */
    'date': number;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'person': string;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'company': string;
    /**
     * 
     * @type {TicketDepartment}
     * @memberof TaskTicket
     */
    'department': TicketDepartment;
    /**
     * 
     * @type {TicketStatusEnum}
     * @memberof TaskTicket
     */
    'status': TicketStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'city'?: string;
    /**
     * 
     * @type {TicketPriorityEnum}
     * @memberof TaskTicket
     */
    'priority': TicketPriorityEnum;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'details': string;
    /**
     * 
     * @type {Array<TicketResponse>}
     * @memberof TaskTicket
     */
    'responses': Array<TicketResponse>;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'messageId': string;
    /**
     * 
     * @type {ServiceCall}
     * @memberof TaskTicket
     */
    'service_call'?: ServiceCall;
    /**
     * 
     * @type {Array<TicketTasksInner>}
     * @memberof TaskTicket
     */
    'tasks': Array<TicketTasksInner>;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'event'?: string;
    /**
     * 
     * @type {number}
     * @memberof TaskTicket
     */
    'closeDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof TaskTicket
     */
    'lastUpdate': number;
    /**
     * 
     * @type {Array<UserUpdate>}
     * @memberof TaskTicket
     */
    'usersUpdated': Array<UserUpdate>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof TaskTicket
     */
    'attachments': Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof TaskTicket
     */
    'voucherId'?: string;
    /**
     * 
     * @type {TicketUser}
     * @memberof TaskTicket
     */
    'user'?: TicketUser;
    /**
     * 
     * @type {boolean}
     * @memberof TaskTicket
     */
    'customer': boolean;
}


/**
 * 
 * @export
 * @interface Text
 */
export interface Text {
    /**
     * 
     * @type {Array<Annotation>}
     * @memberof Text
     */
    'annotations': Array<Annotation>;
    /**
     * The data that makes up the text.
     * @type {string}
     * @memberof Text
     */
    'value': string;
}
/**
 * The text content that is part of a message.
 * @export
 * @interface TextContentBlock
 */
export interface TextContentBlock {
    /**
     * 
     * @type {Text}
     * @memberof TextContentBlock
     */
    'text': Text;
    /**
     * Always `text`.
     * @type {string}
     * @memberof TextContentBlock
     */
    'type': TextContentBlockTypeEnum;
}

export const TextContentBlockTypeEnum = {
    Text: 'text'
} as const;

export type TextContentBlockTypeEnum = typeof TextContentBlockTypeEnum[keyof typeof TextContentBlockTypeEnum];

/**
 * 
 * @export
 * @interface Ticket
 */
export interface Ticket {
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'date': number;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'person': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'number': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'company': string;
    /**
     * 
     * @type {TicketDepartment}
     * @memberof Ticket
     */
    'department': TicketDepartment;
    /**
     * 
     * @type {TicketStatusEnum}
     * @memberof Ticket
     */
    'status': TicketStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'city'?: string;
    /**
     * 
     * @type {TicketPriorityEnum}
     * @memberof Ticket
     */
    'priority': TicketPriorityEnum;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'details': string;
    /**
     * 
     * @type {Array<TicketResponse>}
     * @memberof Ticket
     */
    'responses': Array<TicketResponse>;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'messageId': string;
    /**
     * 
     * @type {ServiceCall}
     * @memberof Ticket
     */
    'service_call'?: ServiceCall;
    /**
     * 
     * @type {Array<TicketTasksInner>}
     * @memberof Ticket
     */
    'tasks': Array<TicketTasksInner>;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'event'?: string;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'closeDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof Ticket
     */
    'lastUpdate': number;
    /**
     * 
     * @type {Array<UserUpdate>}
     * @memberof Ticket
     */
    'usersUpdated': Array<UserUpdate>;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof Ticket
     */
    'attachments': Array<Attachment>;
    /**
     * 
     * @type {string}
     * @memberof Ticket
     */
    'voucherId'?: string;
    /**
     * 
     * @type {TicketUser}
     * @memberof Ticket
     */
    'user'?: TicketUser;
    /**
     * 
     * @type {boolean}
     * @memberof Ticket
     */
    'customer': boolean;
}


/**
 * 
 * @export
 * @interface TicketDepartment
 */
export interface TicketDepartment {
    /**
     * 
     * @type {string}
     * @memberof TicketDepartment
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketDepartment
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TicketDepartment
     */
    'description': string;
    /**
     * 
     * @type {Array<LogUser>}
     * @memberof TicketDepartment
     */
    'users': Array<LogUser>;
    /**
     * 
     * @type {boolean}
     * @memberof TicketDepartment
     */
    'administrative': boolean;
    /**
     * 
     * @type {number}
     * @memberof TicketDepartment
     */
    'date': number;
}
/**
 * 
 * @export
 * @interface TicketMessages
 */
export interface TicketMessages {
    /**
     * 
     * @type {EmailSetting}
     * @memberof TicketMessages
     */
    'onClose': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TicketMessages
     */
    'onCreate': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TicketMessages
     */
    'onStatusChange': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TicketMessages
     */
    'onResponse': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof TicketMessages
     */
    'onTicketReminder': EmailSetting;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TicketPriorityEnum = {
    High: 'high',
    Medium: 'medium',
    Low: 'low'
} as const;

export type TicketPriorityEnum = typeof TicketPriorityEnum[keyof typeof TicketPriorityEnum];


/**
 * 
 * @export
 * @interface TicketResponse
 */
export interface TicketResponse {
    /**
     * 
     * @type {string}
     * @memberof TicketResponse
     */
    '_id'?: string;
    /**
     * 
     * @type {number}
     * @memberof TicketResponse
     */
    'date'?: number;
    /**
     * 
     * @type {User}
     * @memberof TicketResponse
     */
    'user': User;
    /**
     * 
     * @type {string}
     * @memberof TicketResponse
     */
    'content'?: string;
    /**
     * 
     * @type {Array<Attachment>}
     * @memberof TicketResponse
     */
    'attachments': Array<Attachment>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TicketStatusEnum = {
    NotReviewed: 'Not reviewed',
    Incomplete: 'Incomplete',
    PendingConfirmation: 'Pending confirmation',
    Complete: 'Complete'
} as const;

export type TicketStatusEnum = typeof TicketStatusEnum[keyof typeof TicketStatusEnum];


/**
 * 
 * @export
 * @interface TicketTasksInner
 */
export interface TicketTasksInner {
    /**
     * 
     * @type {string}
     * @memberof TicketTasksInner
     */
    '_id'?: string;
    /**
     * 
     * @type {TaskTicket}
     * @memberof TicketTasksInner
     */
    'ticket': TaskTicket;
    /**
     * 
     * @type {string}
     * @memberof TicketTasksInner
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof TicketTasksInner
     */
    'description': string;
    /**
     * 
     * @type {Array<LogUser>}
     * @memberof TicketTasksInner
     */
    'users': Array<LogUser>;
    /**
     * 
     * @type {Array<TaskAssignment>}
     * @memberof TicketTasksInner
     */
    'assignments': Array<TaskAssignment>;
    /**
     * 
     * @type {number}
     * @memberof TicketTasksInner
     */
    'startDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketTasksInner
     */
    'endDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof TicketTasksInner
     */
    'cancelDate'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TicketTasksInner
     */
    'cancelled': boolean;
    /**
     * 
     * @type {string}
     * @memberof TicketTasksInner
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof TicketTasksInner
     */
    'date'?: number;
}
/**
 * 
 * @export
 * @interface TicketUser
 */
export interface TicketUser {
    /**
     * 
     * @type {string}
     * @memberof TicketUser
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof TicketUser
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof TicketUser
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof TicketUser
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof TicketUser
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof TicketUser
     */
    'activationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof TicketUser
     */
    'passwordChangeCode'?: string;
    /**
     * 
     * @type {Array<UserPushSubscriptionsInner>}
     * @memberof TicketUser
     */
    'pushSubscriptions': Array<UserPushSubscriptionsInner>;
    /**
     * 
     * @type {string}
     * @memberof TicketUser
     */
    'name': string;
    /**
     * 
     * @type {UserRole}
     * @memberof TicketUser
     */
    'role': UserRole;
    /**
     * 
     * @type {boolean}
     * @memberof TicketUser
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TicketUser
     */
    'deleted': boolean;
}


/**
 * 
 * @export
 * @interface UnixPermissions
 */
export interface UnixPermissions {
    /**
     * 
     * @type {number}
     * @memberof UnixPermissions
     */
    'user': number;
    /**
     * 
     * @type {number}
     * @memberof UnixPermissions
     */
    'group': number;
    /**
     * 
     * @type {number}
     * @memberof UnixPermissions
     */
    'world': number;
}
/**
 * 
 * @export
 * @interface UpdateDepartmentBody
 */
export interface UpdateDepartmentBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateDepartmentBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDepartmentBody
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateDepartmentBody
     */
    'users': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDepartmentBody
     */
    'administrative': boolean;
}
/**
 * 
 * @export
 * @interface UpdateItemBody
 */
export interface UpdateItemBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateItemBody
     */
    'description': string;
    /**
     * 
     * @type {CreateItemBodyLocation}
     * @memberof UpdateItemBody
     */
    'location'?: CreateItemBodyLocation;
    /**
     * 
     * @type {string}
     * @memberof UpdateItemBody
     */
    'docRef': string;
}
/**
 * 
 * @export
 * @interface UpdateItemHandInBody
 */
export interface UpdateItemHandInBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateItemHandInBody
     */
    'comment': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateItemHandInBody
     */
    'undo': boolean;
}
/**
 * 
 * @export
 * @interface UpdateMyUserBody
 */
export interface UpdateMyUserBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateMyUserBody
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyUserBody
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyUserBody
     */
    'company': string;
}
/**
 * 
 * @export
 * @interface UpdateServiceExpensesBody
 */
export interface UpdateServiceExpensesBody {
    /**
     * 
     * @type {Array<ServiceExpense>}
     * @memberof UpdateServiceExpensesBody
     */
    'expenses': Array<ServiceExpense>;
}
/**
 * 
 * @export
 * @interface UpdateServiceTimesBody
 */
export interface UpdateServiceTimesBody {
    /**
     * 
     * @type {Array<ServiceTime>}
     * @memberof UpdateServiceTimesBody
     */
    'times': Array<ServiceTime>;
}
/**
 * 
 * @export
 * @interface UpdateServiceWorkersBody
 */
export interface UpdateServiceWorkersBody {
    /**
     * 
     * @type {Array<ServiceTime>}
     * @memberof UpdateServiceWorkersBody
     */
    'times': Array<ServiceTime>;
}
/**
 * 
 * @export
 * @interface UpdateTaskBody
 */
export interface UpdateTaskBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskBody
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskBody
     */
    'description': string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTaskBody
     */
    'cancelled': boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskBody
     */
    'comment': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateTaskBody
     */
    'startDate'?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateTaskBody
     */
    'endDate'?: number;
}
/**
 * 
 * @export
 * @interface UpdateTaskStatusBody
 */
export interface UpdateTaskStatusBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateTaskStatusBody
     */
    'status': UpdateTaskStatusBodyStatusEnum;
}

export const UpdateTaskStatusBodyStatusEnum = {
    Start: 'start',
    End: 'end'
} as const;

export type UpdateTaskStatusBodyStatusEnum = typeof UpdateTaskStatusBodyStatusEnum[keyof typeof UpdateTaskStatusBodyStatusEnum];

/**
 * 
 * @export
 * @interface UpdateTicketBody
 */
export interface UpdateTicketBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateTicketBody
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTicketBody
     */
    'location': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTicketBody
     */
    'departmentId': string;
    /**
     * 
     * @type {TicketPriorityEnum}
     * @memberof UpdateTicketBody
     */
    'priority': TicketPriorityEnum;
    /**
     * 
     * @type {TicketStatusEnum}
     * @memberof UpdateTicketBody
     */
    'status': TicketStatusEnum;
}


/**
 * 
 * @export
 * @interface UpdateTicketDetailsBody
 */
export interface UpdateTicketDetailsBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateTicketDetailsBody
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface UpdateTicketResponseBody
 */
export interface UpdateTicketResponseBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateTicketResponseBody
     */
    'content': string;
}
/**
 * 
 * @export
 * @interface UpdateUserBody
 */
export interface UpdateUserBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    'phone': string;
    /**
     * 
     * @type {UserRole}
     * @memberof UpdateUserBody
     */
    'role': UserRole;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserBody
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface UpdateUserPasswordBody
 */
export interface UpdateUserPasswordBody {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordBody
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordBody
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface UpdateVoucherLimitBody
 */
export interface UpdateVoucherLimitBody {
    /**
     * 
     * @type {number}
     * @memberof UpdateVoucherLimitBody
     */
    'minutes': number;
}
/**
 * 
 * @export
 * @interface UpdateVoucherUsageBody
 */
export interface UpdateVoucherUsageBody {
    /**
     * 
     * @type {number}
     * @memberof UpdateVoucherUsageBody
     */
    'timeToUse': number;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'company': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'activationCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'passwordChangeCode'?: string;
    /**
     * 
     * @type {Array<UserPushSubscriptionsInner>}
     * @memberof User
     */
    'pushSubscriptions': Array<UserPushSubscriptionsInner>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'name': string;
    /**
     * 
     * @type {UserRole}
     * @memberof User
     */
    'role': UserRole;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'active': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    'deleted': boolean;
}


/**
 * 
 * @export
 * @interface UserMessages
 */
export interface UserMessages {
    /**
     * 
     * @type {EmailSetting}
     * @memberof UserMessages
     */
    'onCreate': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof UserMessages
     */
    'onRegister': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof UserMessages
     */
    'onUpdate': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof UserMessages
     */
    'onPasswordChangeRequest': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof UserMessages
     */
    'onPasswordChanged': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof UserMessages
     */
    'onDelete': EmailSetting;
}
/**
 * 
 * @export
 * @interface UserPushSubscriptionsInner
 */
export interface UserPushSubscriptionsInner {
    /**
     * 
     * @type {string}
     * @memberof UserPushSubscriptionsInner
     */
    'endpoint'?: string;
    /**
     * 
     * @type {number}
     * @memberof UserPushSubscriptionsInner
     */
    'expirationTime'?: number | null;
    /**
     * Construct a type with a set of properties K of type T
     * @type {object}
     * @memberof UserPushSubscriptionsInner
     */
    'keys'?: object;
    /**
     * 
     * @type {string}
     * @memberof UserPushSubscriptionsInner
     */
    'browserId': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const UserRole = {
    User: 'user',
    Agent: 'agent',
    Admin: 'admin'
} as const;

export type UserRole = typeof UserRole[keyof typeof UserRole];


/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * 
     * @type {string}
     * @memberof UserUpdate
     */
    '_id'?: string;
    /**
     * 
     * @type {LogUser}
     * @memberof UserUpdate
     */
    'user': LogUser;
    /**
     * 
     * @type {number}
     * @memberof UserUpdate
     */
    'lastUpdate': number;
}
/**
 * 
 * @export
 * @interface Voucher
 */
export interface Voucher {
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    '_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof Voucher
     */
    'id': string;
    /**
     * 
     * @type {TaskTicket}
     * @memberof Voucher
     */
    'ticket': TaskTicket;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    'timeLimit': number;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    'timeUsed': number;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    'dateCreated': number;
    /**
     * 
     * @type {number}
     * @memberof Voucher
     */
    'dateEnd': number;
    /**
     * 
     * @type {VoucherEmails}
     * @memberof Voucher
     */
    'emails': VoucherEmails;
}
/**
 * 
 * @export
 * @interface VoucherEmails
 */
export interface VoucherEmails {
    /**
     * 
     * @type {VoucherEmailsDateEnd}
     * @memberof VoucherEmails
     */
    'dateEnd': VoucherEmailsDateEnd;
    /**
     * 
     * @type {VoucherEmailsBeforeOneWeek}
     * @memberof VoucherEmails
     */
    'beforeOneWeek': VoucherEmailsBeforeOneWeek;
    /**
     * 
     * @type {VoucherEmailsBeforeOneWeek}
     * @memberof VoucherEmails
     */
    'beforeOneMonth': VoucherEmailsBeforeOneWeek;
    /**
     * 
     * @type {VoucherEmailsBeforeOneWeek}
     * @memberof VoucherEmails
     */
    'beforeTwoMonths': VoucherEmailsBeforeOneWeek;
}
/**
 * 
 * @export
 * @interface VoucherEmailsBeforeOneWeek
 */
export interface VoucherEmailsBeforeOneWeek {
    /**
     * 
     * @type {number}
     * @memberof VoucherEmailsBeforeOneWeek
     */
    'date': number;
    /**
     * 
     * @type {boolean}
     * @memberof VoucherEmailsBeforeOneWeek
     */
    'done': boolean;
}
/**
 * 
 * @export
 * @interface VoucherEmailsDateEnd
 */
export interface VoucherEmailsDateEnd {
    /**
     * 
     * @type {boolean}
     * @memberof VoucherEmailsDateEnd
     */
    'done': boolean;
}
/**
 * 
 * @export
 * @interface VoucherMessages
 */
export interface VoucherMessages {
    /**
     * 
     * @type {EmailSetting}
     * @memberof VoucherMessages
     */
    'onCreate': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof VoucherMessages
     */
    'onDelete': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof VoucherMessages
     */
    'onPartialUse': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof VoucherMessages
     */
    'onFullUse': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof VoucherMessages
     */
    'onTicketAssign': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof VoucherMessages
     */
    'onApproachingExpiry': EmailSetting;
    /**
     * 
     * @type {EmailSetting}
     * @memberof VoucherMessages
     */
    'onExpiry': EmailSetting;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LoginBody} loginBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginBody: LoginBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginBody' is not null or undefined
            assertParamExists('login', 'loginBody', loginBody)
            const localVarPath = `/api/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSession(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSession(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.getSession']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {LoginBody} loginBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginBody: LoginBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOAuthorizationData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(loginBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['AuthenticationApi.login']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(options?: AxiosRequestConfig): AxiosPromise<OutputDTOUser> {
            return localVarFp.getSession(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthenticationApiLoginRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(requestParameters: AuthenticationApiLoginRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOAuthorizationData> {
            return localVarFp.login(requestParameters.loginBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for login operation in AuthenticationApi.
 * @export
 * @interface AuthenticationApiLoginRequest
 */
export interface AuthenticationApiLoginRequest {
    /**
     * 
     * @type {LoginBody}
     * @memberof AuthenticationApiLogin
     */
    readonly loginBody: LoginBody
}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public getSession(options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).getSession(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthenticationApiLoginRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public login(requestParameters: AuthenticationApiLoginRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).login(requestParameters.loginBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateEventBody} createEventBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent: async (createEventBody: CreateEventBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createEventBody' is not null or undefined
            assertParamExists('createEvent', 'createEventBody', createEventBody)
            const localVarPath = `/api/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createEventBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent: async (eventId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('getEvent', 'eventId', eventId)
            const localVarPath = `/api/calendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (eventId !== undefined) {
                localVarQueryParameter['eventId'] = eventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} search 
         * @param {string} maxResults 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventList: async (fromDate: string, toDate: string, search: string, maxResults: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fromDate' is not null or undefined
            assertParamExists('getEventList', 'fromDate', fromDate)
            // verify required parameter 'toDate' is not null or undefined
            assertParamExists('getEventList', 'toDate', toDate)
            // verify required parameter 'search' is not null or undefined
            assertParamExists('getEventList', 'search', search)
            // verify required parameter 'maxResults' is not null or undefined
            assertParamExists('getEventList', 'maxResults', maxResults)
            const localVarPath = `/api/calendar/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fromDate !== undefined) {
                localVarQueryParameter['fromDate'] = (fromDate as any instanceof Date) ?
                    (fromDate as any).toISOString() :
                    fromDate;
            }

            if (toDate !== undefined) {
                localVarQueryParameter['toDate'] = (toDate as any instanceof Date) ?
                    (toDate as any).toISOString() :
                    toDate;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (maxResults !== undefined) {
                localVarQueryParameter['maxResults'] = maxResults;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateEventBody} createEventBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createEvent(createEventBody: CreateEventBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createEvent(createEventBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarApi.createEvent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} eventId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEvent(eventId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEvent(eventId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarApi.getEvent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} fromDate 
         * @param {string} toDate 
         * @param {string} search 
         * @param {string} maxResults 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEventList(fromDate: string, toDate: string, search: string, maxResults: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEventList(fromDate, toDate, search, maxResults, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['CalendarApi.getEventList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarApiFp(configuration)
    return {
        /**
         * 
         * @param {CalendarApiCreateEventRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEvent(requestParameters: CalendarApiCreateEventRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.createEvent(requestParameters.createEventBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalendarApiGetEventRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEvent(requestParameters: CalendarApiGetEventRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getEvent(requestParameters.eventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CalendarApiGetEventListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventList(requestParameters: CalendarApiGetEventListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getEventList(requestParameters.fromDate, requestParameters.toDate, requestParameters.search, requestParameters.maxResults, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createEvent operation in CalendarApi.
 * @export
 * @interface CalendarApiCreateEventRequest
 */
export interface CalendarApiCreateEventRequest {
    /**
     * 
     * @type {CreateEventBody}
     * @memberof CalendarApiCreateEvent
     */
    readonly createEventBody: CreateEventBody
}

/**
 * Request parameters for getEvent operation in CalendarApi.
 * @export
 * @interface CalendarApiGetEventRequest
 */
export interface CalendarApiGetEventRequest {
    /**
     * 
     * @type {string}
     * @memberof CalendarApiGetEvent
     */
    readonly eventId: string
}

/**
 * Request parameters for getEventList operation in CalendarApi.
 * @export
 * @interface CalendarApiGetEventListRequest
 */
export interface CalendarApiGetEventListRequest {
    /**
     * 
     * @type {string}
     * @memberof CalendarApiGetEventList
     */
    readonly fromDate: string

    /**
     * 
     * @type {string}
     * @memberof CalendarApiGetEventList
     */
    readonly toDate: string

    /**
     * 
     * @type {string}
     * @memberof CalendarApiGetEventList
     */
    readonly search: string

    /**
     * 
     * @type {string}
     * @memberof CalendarApiGetEventList
     */
    readonly maxResults: string
}

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     * 
     * @param {CalendarApiCreateEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public createEvent(requestParameters: CalendarApiCreateEventRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).createEvent(requestParameters.createEventBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarApiGetEventRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getEvent(requestParameters: CalendarApiGetEventRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getEvent(requestParameters.eventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalendarApiGetEventListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getEventList(requestParameters: CalendarApiGetEventListRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getEventList(requestParameters.fromDate, requestParameters.toDate, requestParameters.search, requestParameters.maxResults, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DashboardApi - axios parameter creator
 * @export
 */
export const DashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {DateRange} dateRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard: async (dateRange: DateRange, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'dateRange' is not null or undefined
            assertParamExists('getDashboard', 'dateRange', dateRange)
            const localVarPath = `/api/dashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (dateRange !== undefined) {
                localVarQueryParameter['dateRange'] = dateRange;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardApi - functional programming interface
 * @export
 */
export const DashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {DateRange} dateRange 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDashboard(dateRange: DateRange, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTODashboardData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDashboard(dateRange, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DashboardApi.getDashboard']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DashboardApi - factory interface
 * @export
 */
export const DashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {DashboardApiGetDashboardRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDashboard(requestParameters: DashboardApiGetDashboardRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTODashboardData> {
            return localVarFp.getDashboard(requestParameters.dateRange, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getDashboard operation in DashboardApi.
 * @export
 * @interface DashboardApiGetDashboardRequest
 */
export interface DashboardApiGetDashboardRequest {
    /**
     * 
     * @type {DateRange}
     * @memberof DashboardApiGetDashboard
     */
    readonly dateRange: DateRange
}

/**
 * DashboardApi - object-oriented interface
 * @export
 * @class DashboardApi
 * @extends {BaseAPI}
 */
export class DashboardApi extends BaseAPI {
    /**
     * 
     * @param {DashboardApiGetDashboardRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardApi
     */
    public getDashboard(requestParameters: DashboardApiGetDashboardRequest, options?: AxiosRequestConfig) {
        return DashboardApiFp(this.configuration).getDashboard(requestParameters.dateRange, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DepartmentsApi - axios parameter creator
 * @export
 */
export const DepartmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateDepartmentBody} createDepartmentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartment: async (createDepartmentBody: CreateDepartmentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDepartmentBody' is not null or undefined
            assertParamExists('createDepartment', 'createDepartmentBody', createDepartmentBody)
            const localVarPath = `/api/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDepartmentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDepartment', 'id', id)
            const localVarPath = `/api/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartment: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDepartment', 'id', id)
            const localVarPath = `/api/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentList: async (skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getDepartmentList', 'skip', skip)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getDepartmentList', 'limit', limit)
            const localVarPath = `/api/departments/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentListQuery: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/departments/list/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentMiniList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/departments/list/mini`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDepartmentBody} updateDepartmentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartment: async (id: string, updateDepartmentBody: UpdateDepartmentBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDepartment', 'id', id)
            // verify required parameter 'updateDepartmentBody' is not null or undefined
            assertParamExists('updateDepartment', 'updateDepartmentBody', updateDepartmentBody)
            const localVarPath = `/api/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDepartmentBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsApi - functional programming interface
 * @export
 */
export const DepartmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateDepartmentBody} createDepartmentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDepartment(createDepartmentBody: CreateDepartmentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartment(createDepartmentBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentsApi.createDepartment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartment(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentsApi.deleteDepartment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartment(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTODepartment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartment(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentsApi.getDepartment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentList(skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOPaginatedListDepartment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentList(skip, limit, sortField, sortDirection, filters, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentsApi.getDepartmentList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentListQuery(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOQueryFilterTemplateArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentListQuery(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentsApi.getDepartmentListQuery']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentMiniList(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTODepartmentArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentMiniList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentsApi.getDepartmentMiniList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateDepartmentBody} updateDepartmentBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDepartment(id: string, updateDepartmentBody: UpdateDepartmentBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartment(id, updateDepartmentBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DepartmentsApi.updateDepartment']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DepartmentsApi - factory interface
 * @export
 */
export const DepartmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsApiFp(configuration)
    return {
        /**
         * 
         * @param {DepartmentsApiCreateDepartmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartment(requestParameters: DepartmentsApiCreateDepartmentRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.createDepartment(requestParameters.createDepartmentBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiDeleteDepartmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartment(requestParameters: DepartmentsApiDeleteDepartmentRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.deleteDepartment(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiGetDepartmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartment(requestParameters: DepartmentsApiGetDepartmentRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTODepartment> {
            return localVarFp.getDepartment(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiGetDepartmentListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentList(requestParameters: DepartmentsApiGetDepartmentListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOPaginatedListDepartment> {
            return localVarFp.getDepartmentList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentListQuery(options?: AxiosRequestConfig): AxiosPromise<OutputDTOQueryFilterTemplateArray> {
            return localVarFp.getDepartmentListQuery(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentMiniList(options?: AxiosRequestConfig): AxiosPromise<OutputDTODepartmentArray> {
            return localVarFp.getDepartmentMiniList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DepartmentsApiUpdateDepartmentRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartment(requestParameters: DepartmentsApiUpdateDepartmentRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.updateDepartment(requestParameters.id, requestParameters.updateDepartmentBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDepartment operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiCreateDepartmentRequest
 */
export interface DepartmentsApiCreateDepartmentRequest {
    /**
     * 
     * @type {CreateDepartmentBody}
     * @memberof DepartmentsApiCreateDepartment
     */
    readonly createDepartmentBody: CreateDepartmentBody
}

/**
 * Request parameters for deleteDepartment operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiDeleteDepartmentRequest
 */
export interface DepartmentsApiDeleteDepartmentRequest {
    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiDeleteDepartment
     */
    readonly id: string
}

/**
 * Request parameters for getDepartment operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiGetDepartmentRequest
 */
export interface DepartmentsApiGetDepartmentRequest {
    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiGetDepartment
     */
    readonly id: string
}

/**
 * Request parameters for getDepartmentList operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiGetDepartmentListRequest
 */
export interface DepartmentsApiGetDepartmentListRequest {
    /**
     * 
     * @type {number}
     * @memberof DepartmentsApiGetDepartmentList
     */
    readonly skip: number

    /**
     * 
     * @type {number}
     * @memberof DepartmentsApiGetDepartmentList
     */
    readonly limit: number

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiGetDepartmentList
     */
    readonly sortField?: string

    /**
     * 
     * @type {SortDirection}
     * @memberof DepartmentsApiGetDepartmentList
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiGetDepartmentList
     */
    readonly filters?: string
}

/**
 * Request parameters for updateDepartment operation in DepartmentsApi.
 * @export
 * @interface DepartmentsApiUpdateDepartmentRequest
 */
export interface DepartmentsApiUpdateDepartmentRequest {
    /**
     * 
     * @type {string}
     * @memberof DepartmentsApiUpdateDepartment
     */
    readonly id: string

    /**
     * 
     * @type {UpdateDepartmentBody}
     * @memberof DepartmentsApiUpdateDepartment
     */
    readonly updateDepartmentBody: UpdateDepartmentBody
}

/**
 * DepartmentsApi - object-oriented interface
 * @export
 * @class DepartmentsApi
 * @extends {BaseAPI}
 */
export class DepartmentsApi extends BaseAPI {
    /**
     * 
     * @param {DepartmentsApiCreateDepartmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public createDepartment(requestParameters: DepartmentsApiCreateDepartmentRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).createDepartment(requestParameters.createDepartmentBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiDeleteDepartmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public deleteDepartment(requestParameters: DepartmentsApiDeleteDepartmentRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).deleteDepartment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiGetDepartmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartment(requestParameters: DepartmentsApiGetDepartmentRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartment(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiGetDepartmentListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartmentList(requestParameters: DepartmentsApiGetDepartmentListRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartmentList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartmentListQuery(options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartmentListQuery(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartmentMiniList(options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartmentMiniList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DepartmentsApiUpdateDepartmentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public updateDepartment(requestParameters: DepartmentsApiUpdateDepartmentRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).updateDepartment(requestParameters.id, requestParameters.updateDepartmentBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FTPApi - axios parameter creator
 * @export
 */
export const FTPApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectory: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('createDirectory', 'path', path)
            const localVarPath = `/api/ftp/directory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileUpload: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('createFileUpload', 'path', path)
            const localVarPath = `/api/ftp/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectory: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('deleteDirectory', 'path', path)
            const localVarPath = `/api/ftp/directory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('deleteFile', 'path', path)
            const localVarPath = `/api/ftp/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileDownload: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getFileDownload', 'path', path)
            const localVarPath = `/api/ftp/file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileList: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getFileList', 'path', path)
            const localVarPath = `/api/ftp/file/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (path !== undefined) {
                localVarQueryParameter['path'] = path;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} oldPath 
         * @param {string} newPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFilename: async (oldPath: string, newPath: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'oldPath' is not null or undefined
            assertParamExists('updateFilename', 'oldPath', oldPath)
            // verify required parameter 'newPath' is not null or undefined
            assertParamExists('updateFilename', 'newPath', newPath)
            const localVarPath = `/api/ftp/file/name`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (oldPath !== undefined) {
                localVarQueryParameter['oldPath'] = oldPath;
            }

            if (newPath !== undefined) {
                localVarQueryParameter['newPath'] = newPath;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FTPApi - functional programming interface
 * @export
 */
export const FTPApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FTPApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDirectory(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDirectory(path, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FTPApi.createDirectory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileUpload(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileUpload(path, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FTPApi.createFileUpload']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDirectory(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDirectory(path, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FTPApi.deleteDirectory']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFile(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFile(path, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FTPApi.deleteFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileDownload(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileDownload(path, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FTPApi.getFileDownload']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileList(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOFileInfoArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileList(path, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FTPApi.getFileList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} oldPath 
         * @param {string} newPath 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFilename(oldPath: string, newPath: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFilename(oldPath, newPath, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FTPApi.updateFilename']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FTPApi - factory interface
 * @export
 */
export const FTPApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FTPApiFp(configuration)
    return {
        /**
         * 
         * @param {FTPApiCreateDirectoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectory(requestParameters: FTPApiCreateDirectoryRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.createDirectory(requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FTPApiCreateFileUploadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileUpload(requestParameters: FTPApiCreateFileUploadRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.createFileUpload(requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FTPApiDeleteDirectoryRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectory(requestParameters: FTPApiDeleteDirectoryRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.deleteDirectory(requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FTPApiDeleteFileRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(requestParameters: FTPApiDeleteFileRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.deleteFile(requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FTPApiGetFileDownloadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileDownload(requestParameters: FTPApiGetFileDownloadRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.getFileDownload(requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FTPApiGetFileListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileList(requestParameters: FTPApiGetFileListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOFileInfoArray> {
            return localVarFp.getFileList(requestParameters.path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FTPApiUpdateFilenameRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFilename(requestParameters: FTPApiUpdateFilenameRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.updateFilename(requestParameters.oldPath, requestParameters.newPath, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createDirectory operation in FTPApi.
 * @export
 * @interface FTPApiCreateDirectoryRequest
 */
export interface FTPApiCreateDirectoryRequest {
    /**
     * 
     * @type {string}
     * @memberof FTPApiCreateDirectory
     */
    readonly path: string
}

/**
 * Request parameters for createFileUpload operation in FTPApi.
 * @export
 * @interface FTPApiCreateFileUploadRequest
 */
export interface FTPApiCreateFileUploadRequest {
    /**
     * 
     * @type {string}
     * @memberof FTPApiCreateFileUpload
     */
    readonly path: string
}

/**
 * Request parameters for deleteDirectory operation in FTPApi.
 * @export
 * @interface FTPApiDeleteDirectoryRequest
 */
export interface FTPApiDeleteDirectoryRequest {
    /**
     * 
     * @type {string}
     * @memberof FTPApiDeleteDirectory
     */
    readonly path: string
}

/**
 * Request parameters for deleteFile operation in FTPApi.
 * @export
 * @interface FTPApiDeleteFileRequest
 */
export interface FTPApiDeleteFileRequest {
    /**
     * 
     * @type {string}
     * @memberof FTPApiDeleteFile
     */
    readonly path: string
}

/**
 * Request parameters for getFileDownload operation in FTPApi.
 * @export
 * @interface FTPApiGetFileDownloadRequest
 */
export interface FTPApiGetFileDownloadRequest {
    /**
     * 
     * @type {string}
     * @memberof FTPApiGetFileDownload
     */
    readonly path: string
}

/**
 * Request parameters for getFileList operation in FTPApi.
 * @export
 * @interface FTPApiGetFileListRequest
 */
export interface FTPApiGetFileListRequest {
    /**
     * 
     * @type {string}
     * @memberof FTPApiGetFileList
     */
    readonly path: string
}

/**
 * Request parameters for updateFilename operation in FTPApi.
 * @export
 * @interface FTPApiUpdateFilenameRequest
 */
export interface FTPApiUpdateFilenameRequest {
    /**
     * 
     * @type {string}
     * @memberof FTPApiUpdateFilename
     */
    readonly oldPath: string

    /**
     * 
     * @type {string}
     * @memberof FTPApiUpdateFilename
     */
    readonly newPath: string
}

/**
 * FTPApi - object-oriented interface
 * @export
 * @class FTPApi
 * @extends {BaseAPI}
 */
export class FTPApi extends BaseAPI {
    /**
     * 
     * @param {FTPApiCreateDirectoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public createDirectory(requestParameters: FTPApiCreateDirectoryRequest, options?: AxiosRequestConfig) {
        return FTPApiFp(this.configuration).createDirectory(requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FTPApiCreateFileUploadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public createFileUpload(requestParameters: FTPApiCreateFileUploadRequest, options?: AxiosRequestConfig) {
        return FTPApiFp(this.configuration).createFileUpload(requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FTPApiDeleteDirectoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public deleteDirectory(requestParameters: FTPApiDeleteDirectoryRequest, options?: AxiosRequestConfig) {
        return FTPApiFp(this.configuration).deleteDirectory(requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FTPApiDeleteFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public deleteFile(requestParameters: FTPApiDeleteFileRequest, options?: AxiosRequestConfig) {
        return FTPApiFp(this.configuration).deleteFile(requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FTPApiGetFileDownloadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public getFileDownload(requestParameters: FTPApiGetFileDownloadRequest, options?: AxiosRequestConfig) {
        return FTPApiFp(this.configuration).getFileDownload(requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FTPApiGetFileListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public getFileList(requestParameters: FTPApiGetFileListRequest, options?: AxiosRequestConfig) {
        return FTPApiFp(this.configuration).getFileList(requestParameters.path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FTPApiUpdateFilenameRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FTPApi
     */
    public updateFilename(requestParameters: FTPApiUpdateFilenameRequest, options?: AxiosRequestConfig) {
        return FTPApiFp(this.configuration).updateFilename(requestParameters.oldPath, requestParameters.newPath, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FirewallApi - axios parameter creator
 * @export
 */
export const FirewallApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRecordBody} createRecordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecord: async (createRecordBody: CreateRecordBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRecordBody' is not null or undefined
            assertParamExists('createRecord', 'createRecordBody', createRecordBody)
            const localVarPath = `/api/firewall`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRecordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecord: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRecord', 'id', id)
            const localVarPath = `/api/firewall`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordList: async (skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getRecordList', 'skip', skip)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getRecordList', 'limit', limit)
            const localVarPath = `/api/firewall/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordListQuery: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/firewall/list/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FirewallApi - functional programming interface
 * @export
 */
export const FirewallApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FirewallApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRecordBody} createRecordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRecord(createRecordBody: CreateRecordBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOFirewallRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRecord(createRecordBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirewallApi.createRecord']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRecord(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRecord(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirewallApi.deleteRecord']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordList(skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOPaginatedListFirewallRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordList(skip, limit, sortField, sortDirection, filters, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirewallApi.getRecordList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecordListQuery(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOQueryFilterTemplateArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecordListQuery(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FirewallApi.getRecordListQuery']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FirewallApi - factory interface
 * @export
 */
export const FirewallApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FirewallApiFp(configuration)
    return {
        /**
         * 
         * @param {FirewallApiCreateRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecord(requestParameters: FirewallApiCreateRecordRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOFirewallRecord> {
            return localVarFp.createRecord(requestParameters.createRecordBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FirewallApiDeleteRecordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecord(requestParameters: FirewallApiDeleteRecordRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.deleteRecord(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FirewallApiGetRecordListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordList(requestParameters: FirewallApiGetRecordListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOPaginatedListFirewallRecord> {
            return localVarFp.getRecordList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecordListQuery(options?: AxiosRequestConfig): AxiosPromise<OutputDTOQueryFilterTemplateArray> {
            return localVarFp.getRecordListQuery(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRecord operation in FirewallApi.
 * @export
 * @interface FirewallApiCreateRecordRequest
 */
export interface FirewallApiCreateRecordRequest {
    /**
     * 
     * @type {CreateRecordBody}
     * @memberof FirewallApiCreateRecord
     */
    readonly createRecordBody: CreateRecordBody
}

/**
 * Request parameters for deleteRecord operation in FirewallApi.
 * @export
 * @interface FirewallApiDeleteRecordRequest
 */
export interface FirewallApiDeleteRecordRequest {
    /**
     * 
     * @type {string}
     * @memberof FirewallApiDeleteRecord
     */
    readonly id: string
}

/**
 * Request parameters for getRecordList operation in FirewallApi.
 * @export
 * @interface FirewallApiGetRecordListRequest
 */
export interface FirewallApiGetRecordListRequest {
    /**
     * 
     * @type {number}
     * @memberof FirewallApiGetRecordList
     */
    readonly skip: number

    /**
     * 
     * @type {number}
     * @memberof FirewallApiGetRecordList
     */
    readonly limit: number

    /**
     * 
     * @type {string}
     * @memberof FirewallApiGetRecordList
     */
    readonly sortField?: string

    /**
     * 
     * @type {SortDirection}
     * @memberof FirewallApiGetRecordList
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {string}
     * @memberof FirewallApiGetRecordList
     */
    readonly filters?: string
}

/**
 * FirewallApi - object-oriented interface
 * @export
 * @class FirewallApi
 * @extends {BaseAPI}
 */
export class FirewallApi extends BaseAPI {
    /**
     * 
     * @param {FirewallApiCreateRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirewallApi
     */
    public createRecord(requestParameters: FirewallApiCreateRecordRequest, options?: AxiosRequestConfig) {
        return FirewallApiFp(this.configuration).createRecord(requestParameters.createRecordBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FirewallApiDeleteRecordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirewallApi
     */
    public deleteRecord(requestParameters: FirewallApiDeleteRecordRequest, options?: AxiosRequestConfig) {
        return FirewallApiFp(this.configuration).deleteRecord(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FirewallApiGetRecordListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirewallApi
     */
    public getRecordList(requestParameters: FirewallApiGetRecordListRequest, options?: AxiosRequestConfig) {
        return FirewallApiFp(this.configuration).getRecordList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FirewallApi
     */
    public getRecordListQuery(options?: AxiosRequestConfig) {
        return FirewallApiFp(this.configuration).getRecordListQuery(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * InterfacesApi - axios parameter creator
 * @export
 */
export const InterfacesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterInterface: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interface/filter`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableInterface: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/interface/table`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InterfacesApi - functional programming interface
 * @export
 */
export const InterfacesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InterfacesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFilterInterface(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetFilterInterface200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFilterInterface(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InterfacesApi.getFilterInterface']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTableInterface(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedListAny>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTableInterface(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InterfacesApi.getTableInterface']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InterfacesApi - factory interface
 * @export
 */
export const InterfacesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InterfacesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFilterInterface(options?: AxiosRequestConfig): AxiosPromise<GetFilterInterface200Response> {
            return localVarFp.getFilterInterface(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTableInterface(options?: AxiosRequestConfig): AxiosPromise<PaginatedListAny> {
            return localVarFp.getTableInterface(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InterfacesApi - object-oriented interface
 * @export
 * @class InterfacesApi
 * @extends {BaseAPI}
 */
export class InterfacesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfacesApi
     */
    public getFilterInterface(options?: AxiosRequestConfig) {
        return InterfacesApiFp(this.configuration).getFilterInterface(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InterfacesApi
     */
    public getTableInterface(options?: AxiosRequestConfig) {
        return InterfacesApiFp(this.configuration).getTableInterface(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ItemApi - axios parameter creator
 * @export
 */
export const ItemApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateItemBody} createItemBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItem: async (createItemBody: CreateItemBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createItemBody' is not null or undefined
            assertParamExists('createItem', 'createItemBody', createItemBody)
            const localVarPath = `/api/item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createItemBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItem: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteItem', 'id', id)
            const localVarPath = `/api/item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetDocsTypeEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocs: async (type: GetDocsTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('getDocs', 'type', type)
            const localVarPath = `/api/item/docs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemList: async (skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getItemList', 'skip', skip)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getItemList', 'limit', limit)
            const localVarPath = `/api/item/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemListQuery: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/item/list/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateItemBody} updateItemBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItem: async (id: string, updateItemBody: UpdateItemBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateItem', 'id', id)
            // verify required parameter 'updateItemBody' is not null or undefined
            assertParamExists('updateItem', 'updateItemBody', updateItemBody)
            const localVarPath = `/api/item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateItemHandInBody} updateItemHandInBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemHandIn: async (id: string, updateItemHandInBody: UpdateItemHandInBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateItemHandIn', 'id', id)
            // verify required parameter 'updateItemHandInBody' is not null or undefined
            assertParamExists('updateItemHandIn', 'updateItemHandInBody', updateItemHandInBody)
            const localVarPath = `/api/item/handin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateItemHandInBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ItemApi - functional programming interface
 * @export
 */
export const ItemApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ItemApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateItemBody} createItemBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createItem(createItemBody: CreateItemBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createItem(createItemBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemApi.createItem']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteItem(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteItem(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemApi.deleteItem']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GetDocsTypeEnum} type 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocs(type: GetDocsTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocs(type, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemApi.getDocs']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemList(skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOPaginatedListItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemList(skip, limit, sortField, sortDirection, filters, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemApi.getItemList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemListQuery(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOQueryFilterTemplateArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemListQuery(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemApi.getItemListQuery']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateItemBody} updateItemBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItem(id: string, updateItemBody: UpdateItemBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItem(id, updateItemBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemApi.updateItem']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateItemHandInBody} updateItemHandInBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateItemHandIn(id: string, updateItemHandInBody: UpdateItemHandInBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateItemHandIn(id, updateItemHandInBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ItemApi.updateItemHandIn']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ItemApi - factory interface
 * @export
 */
export const ItemApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ItemApiFp(configuration)
    return {
        /**
         * 
         * @param {ItemApiCreateItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createItem(requestParameters: ItemApiCreateItemRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOItem> {
            return localVarFp.createItem(requestParameters.createItemBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ItemApiDeleteItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteItem(requestParameters: ItemApiDeleteItemRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.deleteItem(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ItemApiGetDocsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocs(requestParameters: ItemApiGetDocsRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getDocs(requestParameters.type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ItemApiGetItemListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemList(requestParameters: ItemApiGetItemListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOPaginatedListItem> {
            return localVarFp.getItemList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemListQuery(options?: AxiosRequestConfig): AxiosPromise<OutputDTOQueryFilterTemplateArray> {
            return localVarFp.getItemListQuery(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ItemApiUpdateItemRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItem(requestParameters: ItemApiUpdateItemRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOItem> {
            return localVarFp.updateItem(requestParameters.id, requestParameters.updateItemBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ItemApiUpdateItemHandInRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateItemHandIn(requestParameters: ItemApiUpdateItemHandInRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOItem> {
            return localVarFp.updateItemHandIn(requestParameters.id, requestParameters.updateItemHandInBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createItem operation in ItemApi.
 * @export
 * @interface ItemApiCreateItemRequest
 */
export interface ItemApiCreateItemRequest {
    /**
     * 
     * @type {CreateItemBody}
     * @memberof ItemApiCreateItem
     */
    readonly createItemBody: CreateItemBody
}

/**
 * Request parameters for deleteItem operation in ItemApi.
 * @export
 * @interface ItemApiDeleteItemRequest
 */
export interface ItemApiDeleteItemRequest {
    /**
     * 
     * @type {string}
     * @memberof ItemApiDeleteItem
     */
    readonly id: string
}

/**
 * Request parameters for getDocs operation in ItemApi.
 * @export
 * @interface ItemApiGetDocsRequest
 */
export interface ItemApiGetDocsRequest {
    /**
     * 
     * @type {'invoice' | 'delivery'}
     * @memberof ItemApiGetDocs
     */
    readonly type: GetDocsTypeEnum
}

/**
 * Request parameters for getItemList operation in ItemApi.
 * @export
 * @interface ItemApiGetItemListRequest
 */
export interface ItemApiGetItemListRequest {
    /**
     * 
     * @type {number}
     * @memberof ItemApiGetItemList
     */
    readonly skip: number

    /**
     * 
     * @type {number}
     * @memberof ItemApiGetItemList
     */
    readonly limit: number

    /**
     * 
     * @type {string}
     * @memberof ItemApiGetItemList
     */
    readonly sortField?: string

    /**
     * 
     * @type {SortDirection}
     * @memberof ItemApiGetItemList
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {string}
     * @memberof ItemApiGetItemList
     */
    readonly filters?: string
}

/**
 * Request parameters for updateItem operation in ItemApi.
 * @export
 * @interface ItemApiUpdateItemRequest
 */
export interface ItemApiUpdateItemRequest {
    /**
     * 
     * @type {string}
     * @memberof ItemApiUpdateItem
     */
    readonly id: string

    /**
     * 
     * @type {UpdateItemBody}
     * @memberof ItemApiUpdateItem
     */
    readonly updateItemBody: UpdateItemBody
}

/**
 * Request parameters for updateItemHandIn operation in ItemApi.
 * @export
 * @interface ItemApiUpdateItemHandInRequest
 */
export interface ItemApiUpdateItemHandInRequest {
    /**
     * 
     * @type {string}
     * @memberof ItemApiUpdateItemHandIn
     */
    readonly id: string

    /**
     * 
     * @type {UpdateItemHandInBody}
     * @memberof ItemApiUpdateItemHandIn
     */
    readonly updateItemHandInBody: UpdateItemHandInBody
}

/**
 * ItemApi - object-oriented interface
 * @export
 * @class ItemApi
 * @extends {BaseAPI}
 */
export class ItemApi extends BaseAPI {
    /**
     * 
     * @param {ItemApiCreateItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public createItem(requestParameters: ItemApiCreateItemRequest, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).createItem(requestParameters.createItemBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItemApiDeleteItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public deleteItem(requestParameters: ItemApiDeleteItemRequest, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).deleteItem(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItemApiGetDocsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public getDocs(requestParameters: ItemApiGetDocsRequest, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).getDocs(requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItemApiGetItemListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public getItemList(requestParameters: ItemApiGetItemListRequest, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).getItemList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public getItemListQuery(options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).getItemListQuery(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItemApiUpdateItemRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public updateItem(requestParameters: ItemApiUpdateItemRequest, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).updateItem(requestParameters.id, requestParameters.updateItemBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ItemApiUpdateItemHandInRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ItemApi
     */
    public updateItemHandIn(requestParameters: ItemApiUpdateItemHandInRequest, options?: AxiosRequestConfig) {
        return ItemApiFp(this.configuration).updateItemHandIn(requestParameters.id, requestParameters.updateItemHandInBody, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetDocsTypeEnum = {
    Invoice: 'invoice',
    Delivery: 'delivery'
} as const;
export type GetDocsTypeEnum = typeof GetDocsTypeEnum[keyof typeof GetDocsTypeEnum];


/**
 * LogApi - axios parameter creator
 * @export
 */
export const LogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogList: async (skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getLogList', 'skip', skip)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getLogList', 'limit', limit)
            const localVarPath = `/api/log/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogListQuery: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/log/list/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogApi - functional programming interface
 * @export
 */
export const LogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogList(skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOPaginatedListLog>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogList(skip, limit, sortField, sortDirection, filters, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LogApi.getLogList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogListQuery(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOQueryFilterTemplateArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogListQuery(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['LogApi.getLogListQuery']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * LogApi - factory interface
 * @export
 */
export const LogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogApiFp(configuration)
    return {
        /**
         * 
         * @param {LogApiGetLogListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogList(requestParameters: LogApiGetLogListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOPaginatedListLog> {
            return localVarFp.getLogList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogListQuery(options?: AxiosRequestConfig): AxiosPromise<OutputDTOQueryFilterTemplateArray> {
            return localVarFp.getLogListQuery(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getLogList operation in LogApi.
 * @export
 * @interface LogApiGetLogListRequest
 */
export interface LogApiGetLogListRequest {
    /**
     * 
     * @type {number}
     * @memberof LogApiGetLogList
     */
    readonly skip: number

    /**
     * 
     * @type {number}
     * @memberof LogApiGetLogList
     */
    readonly limit: number

    /**
     * 
     * @type {string}
     * @memberof LogApiGetLogList
     */
    readonly sortField?: string

    /**
     * 
     * @type {SortDirection}
     * @memberof LogApiGetLogList
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {string}
     * @memberof LogApiGetLogList
     */
    readonly filters?: string
}

/**
 * LogApi - object-oriented interface
 * @export
 * @class LogApi
 * @extends {BaseAPI}
 */
export class LogApi extends BaseAPI {
    /**
     * 
     * @param {LogApiGetLogListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public getLogList(requestParameters: LogApiGetLogListRequest, options?: AxiosRequestConfig) {
        return LogApiFp(this.configuration).getLogList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public getLogListQuery(options?: AxiosRequestConfig) {
        return LogApiFp(this.configuration).getLogListQuery(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateNotificationSubscriptionBody} createNotificationSubscriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription: async (createNotificationSubscriptionBody: CreateNotificationSubscriptionBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createNotificationSubscriptionBody' is not null or undefined
            assertParamExists('createSubscription', 'createNotificationSubscriptionBody', createNotificationSubscriptionBody)
            const localVarPath = `/api/notification/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createNotificationSubscriptionBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyNotifications: async (limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getMyNotifications', 'limit', limit)
            const localVarPath = `/api/notification/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateNotificationRead', 'id', id)
            const localVarPath = `/api/notification/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationsClear: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/notification/clear`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateNotificationSubscriptionBody} createNotificationSubscriptionBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSubscription(createNotificationSubscriptionBody: CreateNotificationSubscriptionBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSubscription(createNotificationSubscriptionBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotificationsApi.createSubscription']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyNotifications(limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOPaginatedListNotificationInstance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyNotifications(limit, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotificationsApi.getMyNotifications']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTONotificationInstance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationRead(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotificationsApi.updateNotificationRead']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNotificationsClear(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNotificationsClear(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotificationsApi.updateNotificationsClear']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @param {NotificationsApiCreateSubscriptionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubscription(requestParameters: NotificationsApiCreateSubscriptionRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.createSubscription(requestParameters.createNotificationSubscriptionBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiGetMyNotificationsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyNotifications(requestParameters: NotificationsApiGetMyNotificationsRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOPaginatedListNotificationInstance> {
            return localVarFp.getMyNotifications(requestParameters.limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {NotificationsApiUpdateNotificationReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationRead(requestParameters: NotificationsApiUpdateNotificationReadRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTONotificationInstance> {
            return localVarFp.updateNotificationRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotificationsClear(options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.updateNotificationsClear(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSubscription operation in NotificationsApi.
 * @export
 * @interface NotificationsApiCreateSubscriptionRequest
 */
export interface NotificationsApiCreateSubscriptionRequest {
    /**
     * 
     * @type {CreateNotificationSubscriptionBody}
     * @memberof NotificationsApiCreateSubscription
     */
    readonly createNotificationSubscriptionBody: CreateNotificationSubscriptionBody
}

/**
 * Request parameters for getMyNotifications operation in NotificationsApi.
 * @export
 * @interface NotificationsApiGetMyNotificationsRequest
 */
export interface NotificationsApiGetMyNotificationsRequest {
    /**
     * 
     * @type {number}
     * @memberof NotificationsApiGetMyNotifications
     */
    readonly limit: number
}

/**
 * Request parameters for updateNotificationRead operation in NotificationsApi.
 * @export
 * @interface NotificationsApiUpdateNotificationReadRequest
 */
export interface NotificationsApiUpdateNotificationReadRequest {
    /**
     * 
     * @type {string}
     * @memberof NotificationsApiUpdateNotificationRead
     */
    readonly id: string
}

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @param {NotificationsApiCreateSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public createSubscription(requestParameters: NotificationsApiCreateSubscriptionRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).createSubscription(requestParameters.createNotificationSubscriptionBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiGetMyNotificationsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getMyNotifications(requestParameters: NotificationsApiGetMyNotificationsRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getMyNotifications(requestParameters.limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {NotificationsApiUpdateNotificationReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotificationRead(requestParameters: NotificationsApiUpdateNotificationReadRequest, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotificationRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotificationsClear(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).updateNotificationsClear(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * OpenAIApi - axios parameter creator
 * @export
 */
export const OpenAIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateAskAiBody} createAskAiBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAskPrompt: async (createAskAiBody: CreateAskAiBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAskAiBody' is not null or undefined
            assertParamExists('createAskPrompt', 'createAskAiBody', createAskAiBody)
            const localVarPath = `/api/openai/askai`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAskAiBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateAssistantBody} createAssistantBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssistantPrompt: async (createAssistantBody: CreateAssistantBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createAssistantBody' is not null or undefined
            assertParamExists('createAssistantPrompt', 'createAssistantBody', createAssistantBody)
            const localVarPath = `/api/openai/assistant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createAssistantBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSummarizeBody} createSummarizeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSummarizePrompt: async (createSummarizeBody: CreateSummarizeBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSummarizeBody' is not null or undefined
            assertParamExists('createSummarizePrompt', 'createSummarizeBody', createSummarizeBody)
            const localVarPath = `/api/openai/summarize`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSummarizeBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThreadRequest: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/openai/thread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateTranslateBody} createTranslateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTranslatePrompt: async (createTranslateBody: CreateTranslateBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createTranslateBody' is not null or undefined
            assertParamExists('createTranslatePrompt', 'createTranslateBody', createTranslateBody)
            const localVarPath = `/api/openai/translate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTranslateBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OpenAIApi - functional programming interface
 * @export
 */
export const OpenAIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OpenAIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateAskAiBody} createAskAiBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAskPrompt(createAskAiBody: CreateAskAiBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAskPrompt(createAskAiBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OpenAIApi.createAskPrompt']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateAssistantBody} createAssistantBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAssistantPrompt(createAssistantBody: CreateAssistantBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOOpenAIBetaThreadsMessagesMessageContentArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAssistantPrompt(createAssistantBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OpenAIApi.createAssistantPrompt']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateSummarizeBody} createSummarizeBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSummarizePrompt(createSummarizeBody: CreateSummarizeBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSummarizePrompt(createSummarizeBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OpenAIApi.createSummarizePrompt']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createThreadRequest(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createThreadRequest(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OpenAIApi.createThreadRequest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateTranslateBody} createTranslateBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTranslatePrompt(createTranslateBody: CreateTranslateBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOString>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTranslatePrompt(createTranslateBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['OpenAIApi.createTranslatePrompt']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * OpenAIApi - factory interface
 * @export
 */
export const OpenAIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OpenAIApiFp(configuration)
    return {
        /**
         * 
         * @param {OpenAIApiCreateAskPromptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAskPrompt(requestParameters: OpenAIApiCreateAskPromptRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOString> {
            return localVarFp.createAskPrompt(requestParameters.createAskAiBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OpenAIApiCreateAssistantPromptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAssistantPrompt(requestParameters: OpenAIApiCreateAssistantPromptRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOOpenAIBetaThreadsMessagesMessageContentArray> {
            return localVarFp.createAssistantPrompt(requestParameters.createAssistantBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OpenAIApiCreateSummarizePromptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSummarizePrompt(requestParameters: OpenAIApiCreateSummarizePromptRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOString> {
            return localVarFp.createSummarizePrompt(requestParameters.createSummarizeBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createThreadRequest(options?: AxiosRequestConfig): AxiosPromise<OutputDTOString> {
            return localVarFp.createThreadRequest(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OpenAIApiCreateTranslatePromptRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTranslatePrompt(requestParameters: OpenAIApiCreateTranslatePromptRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOString> {
            return localVarFp.createTranslatePrompt(requestParameters.createTranslateBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAskPrompt operation in OpenAIApi.
 * @export
 * @interface OpenAIApiCreateAskPromptRequest
 */
export interface OpenAIApiCreateAskPromptRequest {
    /**
     * 
     * @type {CreateAskAiBody}
     * @memberof OpenAIApiCreateAskPrompt
     */
    readonly createAskAiBody: CreateAskAiBody
}

/**
 * Request parameters for createAssistantPrompt operation in OpenAIApi.
 * @export
 * @interface OpenAIApiCreateAssistantPromptRequest
 */
export interface OpenAIApiCreateAssistantPromptRequest {
    /**
     * 
     * @type {CreateAssistantBody}
     * @memberof OpenAIApiCreateAssistantPrompt
     */
    readonly createAssistantBody: CreateAssistantBody
}

/**
 * Request parameters for createSummarizePrompt operation in OpenAIApi.
 * @export
 * @interface OpenAIApiCreateSummarizePromptRequest
 */
export interface OpenAIApiCreateSummarizePromptRequest {
    /**
     * 
     * @type {CreateSummarizeBody}
     * @memberof OpenAIApiCreateSummarizePrompt
     */
    readonly createSummarizeBody: CreateSummarizeBody
}

/**
 * Request parameters for createTranslatePrompt operation in OpenAIApi.
 * @export
 * @interface OpenAIApiCreateTranslatePromptRequest
 */
export interface OpenAIApiCreateTranslatePromptRequest {
    /**
     * 
     * @type {CreateTranslateBody}
     * @memberof OpenAIApiCreateTranslatePrompt
     */
    readonly createTranslateBody: CreateTranslateBody
}

/**
 * OpenAIApi - object-oriented interface
 * @export
 * @class OpenAIApi
 * @extends {BaseAPI}
 */
export class OpenAIApi extends BaseAPI {
    /**
     * 
     * @param {OpenAIApiCreateAskPromptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAIApi
     */
    public createAskPrompt(requestParameters: OpenAIApiCreateAskPromptRequest, options?: AxiosRequestConfig) {
        return OpenAIApiFp(this.configuration).createAskPrompt(requestParameters.createAskAiBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OpenAIApiCreateAssistantPromptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAIApi
     */
    public createAssistantPrompt(requestParameters: OpenAIApiCreateAssistantPromptRequest, options?: AxiosRequestConfig) {
        return OpenAIApiFp(this.configuration).createAssistantPrompt(requestParameters.createAssistantBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OpenAIApiCreateSummarizePromptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAIApi
     */
    public createSummarizePrompt(requestParameters: OpenAIApiCreateSummarizePromptRequest, options?: AxiosRequestConfig) {
        return OpenAIApiFp(this.configuration).createSummarizePrompt(requestParameters.createSummarizeBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAIApi
     */
    public createThreadRequest(options?: AxiosRequestConfig) {
        return OpenAIApiFp(this.configuration).createThreadRequest(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OpenAIApiCreateTranslatePromptRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OpenAIApi
     */
    public createTranslatePrompt(requestParameters: OpenAIApiCreateTranslatePromptRequest, options?: AxiosRequestConfig) {
        return OpenAIApiFp(this.configuration).createTranslatePrompt(requestParameters.createTranslateBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SAPApi - axios parameter creator
 * @export
 */
export const SAPApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ticketId 
         * @param {CreateServiceCallBody} createServiceCallBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceCall: async (ticketId: string, createServiceCallBody: CreateServiceCallBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('createServiceCall', 'ticketId', ticketId)
            // verify required parameter 'createServiceCallBody' is not null or undefined
            assertParamExists('createServiceCall', 'createServiceCallBody', createServiceCallBody)
            const localVarPath = `/api/sap/serviceCall`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ticketId !== undefined) {
                localVarQueryParameter['ticketId'] = ticketId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createServiceCallBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sap/callType/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contactNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact: async (contactNo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'contactNo' is not null or undefined
            assertParamExists('getContact', 'contactNo', contactNo)
            const localVarPath = `/api/sap/contact`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (contactNo !== undefined) {
                localVarQueryParameter['contactNo'] = contactNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} customerNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts: async (customerNo: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'customerNo' is not null or undefined
            assertParamExists('getContacts', 'customerNo', customerNo)
            const localVarPath = `/api/sap/contact/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (customerNo !== undefined) {
                localVarQueryParameter['customerNo'] = customerNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sap/customer/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployees: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sap/employee/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriorities: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sap/priority/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sap/problemType/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceCalls: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sap/serviceCall/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} docNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCallID: async (docNum: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'docNum' is not null or undefined
            assertParamExists('updateCallID', 'docNum', docNum)
            const localVarPath = `/api/sap/callId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (docNum !== undefined) {
                localVarQueryParameter['docNum'] = docNum;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {number} callNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketAttachServiceCall: async (ticketId: string, callNo: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('updateTicketAttachServiceCall', 'ticketId', ticketId)
            // verify required parameter 'callNo' is not null or undefined
            assertParamExists('updateTicketAttachServiceCall', 'callNo', callNo)
            const localVarPath = `/api/sap/serviceCall`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ticketId !== undefined) {
                localVarQueryParameter['ticketId'] = ticketId;
            }

            if (callNo !== undefined) {
                localVarQueryParameter['callNo'] = callNo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SAPApi - functional programming interface
 * @export
 */
export const SAPApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SAPApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ticketId 
         * @param {CreateServiceCallBody} createServiceCallBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServiceCall(ticketId: string, createServiceCallBody: CreateServiceCallBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceCall(ticketId, createServiceCallBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.createServiceCall']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCallTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCallTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.getCallTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} contactNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContact(contactNo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContact(contactNo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.getContact']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} customerNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContacts(customerNo: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContacts(customerNo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.getContacts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCustomers(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCustomers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.getCustomers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEmployees(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEmployees(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.getEmployees']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPriorities(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPriorities(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.getPriorities']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProblemTypes(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProblemTypes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.getProblemTypes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getServiceCalls(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceCalls(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.getServiceCalls']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} docNum 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCallID(docNum: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCallID(docNum, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.updateCallID']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {number} callNo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicketAttachServiceCall(ticketId: string, callNo: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicketAttachServiceCall(ticketId, callNo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SAPApi.updateTicketAttachServiceCall']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SAPApi - factory interface
 * @export
 */
export const SAPApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SAPApiFp(configuration)
    return {
        /**
         * 
         * @param {SAPApiCreateServiceCallRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceCall(requestParameters: SAPApiCreateServiceCallRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.createServiceCall(requestParameters.ticketId, requestParameters.createServiceCallBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCallTypes(options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getCallTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SAPApiGetContactRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContact(requestParameters: SAPApiGetContactRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getContact(requestParameters.contactNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SAPApiGetContactsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContacts(requestParameters: SAPApiGetContactsRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getContacts(requestParameters.customerNo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCustomers(options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getCustomers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEmployees(options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getEmployees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPriorities(options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getPriorities(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProblemTypes(options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getProblemTypes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getServiceCalls(options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.getServiceCalls(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SAPApiUpdateCallIDRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCallID(requestParameters: SAPApiUpdateCallIDRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.updateCallID(requestParameters.docNum, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SAPApiUpdateTicketAttachServiceCallRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketAttachServiceCall(requestParameters: SAPApiUpdateTicketAttachServiceCallRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.updateTicketAttachServiceCall(requestParameters.ticketId, requestParameters.callNo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createServiceCall operation in SAPApi.
 * @export
 * @interface SAPApiCreateServiceCallRequest
 */
export interface SAPApiCreateServiceCallRequest {
    /**
     * 
     * @type {string}
     * @memberof SAPApiCreateServiceCall
     */
    readonly ticketId: string

    /**
     * 
     * @type {CreateServiceCallBody}
     * @memberof SAPApiCreateServiceCall
     */
    readonly createServiceCallBody: CreateServiceCallBody
}

/**
 * Request parameters for getContact operation in SAPApi.
 * @export
 * @interface SAPApiGetContactRequest
 */
export interface SAPApiGetContactRequest {
    /**
     * 
     * @type {string}
     * @memberof SAPApiGetContact
     */
    readonly contactNo: string
}

/**
 * Request parameters for getContacts operation in SAPApi.
 * @export
 * @interface SAPApiGetContactsRequest
 */
export interface SAPApiGetContactsRequest {
    /**
     * 
     * @type {string}
     * @memberof SAPApiGetContacts
     */
    readonly customerNo: string
}

/**
 * Request parameters for updateCallID operation in SAPApi.
 * @export
 * @interface SAPApiUpdateCallIDRequest
 */
export interface SAPApiUpdateCallIDRequest {
    /**
     * 
     * @type {string}
     * @memberof SAPApiUpdateCallID
     */
    readonly docNum: string
}

/**
 * Request parameters for updateTicketAttachServiceCall operation in SAPApi.
 * @export
 * @interface SAPApiUpdateTicketAttachServiceCallRequest
 */
export interface SAPApiUpdateTicketAttachServiceCallRequest {
    /**
     * 
     * @type {string}
     * @memberof SAPApiUpdateTicketAttachServiceCall
     */
    readonly ticketId: string

    /**
     * 
     * @type {number}
     * @memberof SAPApiUpdateTicketAttachServiceCall
     */
    readonly callNo: number
}

/**
 * SAPApi - object-oriented interface
 * @export
 * @class SAPApi
 * @extends {BaseAPI}
 */
export class SAPApi extends BaseAPI {
    /**
     * 
     * @param {SAPApiCreateServiceCallRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public createServiceCall(requestParameters: SAPApiCreateServiceCallRequest, options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).createServiceCall(requestParameters.ticketId, requestParameters.createServiceCallBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public getCallTypes(options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).getCallTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SAPApiGetContactRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public getContact(requestParameters: SAPApiGetContactRequest, options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).getContact(requestParameters.contactNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SAPApiGetContactsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public getContacts(requestParameters: SAPApiGetContactsRequest, options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).getContacts(requestParameters.customerNo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public getCustomers(options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).getCustomers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public getEmployees(options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).getEmployees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public getPriorities(options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).getPriorities(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public getProblemTypes(options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).getProblemTypes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public getServiceCalls(options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).getServiceCalls(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SAPApiUpdateCallIDRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public updateCallID(requestParameters: SAPApiUpdateCallIDRequest, options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).updateCallID(requestParameters.docNum, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SAPApiUpdateTicketAttachServiceCallRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SAPApi
     */
    public updateTicketAttachServiceCall(requestParameters: SAPApiUpdateTicketAttachServiceCallRequest, options?: AxiosRequestConfig) {
        return SAPApiFp(this.configuration).updateTicketAttachServiceCall(requestParameters.ticketId, requestParameters.callNo, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SettingsApi - axios parameter creator
 * @export
 */
export const SettingsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateSetupBody} createSetupBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSettingsSetup: async (createSetupBody: CreateSetupBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSetupBody' is not null or undefined
            assertParamExists('createSettingsSetup', 'createSetupBody', createSetupBody)
            const localVarPath = `/api/settings/setup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSetupBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} testEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSmtpTest: async (testEmail: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'testEmail' is not null or undefined
            assertParamExists('createSmtpTest', 'testEmail', testEmail)
            const localVarPath = `/api/settings/smtp`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (testEmail !== undefined) {
                localVarQueryParameter['testEmail'] = testEmail;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetup: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/settings/exists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppSettings} appSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings: async (appSettings: AppSettings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'appSettings' is not null or undefined
            assertParamExists('updateSettings', 'appSettings', appSettings)
            const localVarPath = `/api/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(appSettings, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SettingsApi - functional programming interface
 * @export
 */
export const SettingsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SettingsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateSetupBody} createSetupBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSettingsSetup(createSetupBody: CreateSetupBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOAppSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSettingsSetup(createSetupBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SettingsApi.createSettingsSetup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} testEmail 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSmtpTest(testEmail: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOAppSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSmtpTest(testEmail, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SettingsApi.createSmtpTest']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSettings(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOAppSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSettings(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SettingsApi.getSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSetup(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSetup(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SettingsApi.getSetup']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {AppSettings} appSettings 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSettings(appSettings: AppSettings, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOAppSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSettings(appSettings, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SettingsApi.updateSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SettingsApi - factory interface
 * @export
 */
export const SettingsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SettingsApiFp(configuration)
    return {
        /**
         * 
         * @param {SettingsApiCreateSettingsSetupRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSettingsSetup(requestParameters: SettingsApiCreateSettingsSetupRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOAppSettings> {
            return localVarFp.createSettingsSetup(requestParameters.createSetupBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettingsApiCreateSmtpTestRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSmtpTest(requestParameters: SettingsApiCreateSmtpTestRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOAppSettings> {
            return localVarFp.createSmtpTest(requestParameters.testEmail, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSettings(options?: AxiosRequestConfig): AxiosPromise<OutputDTOAppSettings> {
            return localVarFp.getSettings(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSetup(options?: AxiosRequestConfig): AxiosPromise<OutputDTOBoolean> {
            return localVarFp.getSetup(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SettingsApiUpdateSettingsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSettings(requestParameters: SettingsApiUpdateSettingsRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOAppSettings> {
            return localVarFp.updateSettings(requestParameters.appSettings, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createSettingsSetup operation in SettingsApi.
 * @export
 * @interface SettingsApiCreateSettingsSetupRequest
 */
export interface SettingsApiCreateSettingsSetupRequest {
    /**
     * 
     * @type {CreateSetupBody}
     * @memberof SettingsApiCreateSettingsSetup
     */
    readonly createSetupBody: CreateSetupBody
}

/**
 * Request parameters for createSmtpTest operation in SettingsApi.
 * @export
 * @interface SettingsApiCreateSmtpTestRequest
 */
export interface SettingsApiCreateSmtpTestRequest {
    /**
     * 
     * @type {string}
     * @memberof SettingsApiCreateSmtpTest
     */
    readonly testEmail: string
}

/**
 * Request parameters for updateSettings operation in SettingsApi.
 * @export
 * @interface SettingsApiUpdateSettingsRequest
 */
export interface SettingsApiUpdateSettingsRequest {
    /**
     * 
     * @type {AppSettings}
     * @memberof SettingsApiUpdateSettings
     */
    readonly appSettings: AppSettings
}

/**
 * SettingsApi - object-oriented interface
 * @export
 * @class SettingsApi
 * @extends {BaseAPI}
 */
export class SettingsApi extends BaseAPI {
    /**
     * 
     * @param {SettingsApiCreateSettingsSetupRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public createSettingsSetup(requestParameters: SettingsApiCreateSettingsSetupRequest, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).createSettingsSetup(requestParameters.createSetupBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettingsApiCreateSmtpTestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public createSmtpTest(requestParameters: SettingsApiCreateSmtpTestRequest, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).createSmtpTest(requestParameters.testEmail, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSettings(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getSettings(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public getSetup(options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).getSetup(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SettingsApiUpdateSettingsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SettingsApi
     */
    public updateSettings(requestParameters: SettingsApiUpdateSettingsRequest, options?: AxiosRequestConfig) {
        return SettingsApiFp(this.configuration).updateSettings(requestParameters.appSettings, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SubscriptionsApi - axios parameter creator
 * @export
 */
export const SubscriptionsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionList: async (skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getSubscriptionList', 'skip', skip)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getSubscriptionList', 'limit', limit)
            const localVarPath = `/api/subscription/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionListQuery: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/subscription/list/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionExecution: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateSubscriptionExecution', 'id', id)
            const localVarPath = `/api/subscription/execute`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubscriptionsApi - functional programming interface
 * @export
 */
export const SubscriptionsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubscriptionsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionList(skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOPaginatedListSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionList(skip, limit, sortField, sortDirection, filters, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SubscriptionsApi.getSubscriptionList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubscriptionListQuery(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOQueryFilterTemplateArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubscriptionListQuery(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SubscriptionsApi.getSubscriptionListQuery']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSubscriptionExecution(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOSubscription>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSubscriptionExecution(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SubscriptionsApi.updateSubscriptionExecution']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SubscriptionsApi - factory interface
 * @export
 */
export const SubscriptionsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubscriptionsApiFp(configuration)
    return {
        /**
         * 
         * @param {SubscriptionsApiGetSubscriptionListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionList(requestParameters: SubscriptionsApiGetSubscriptionListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOPaginatedListSubscription> {
            return localVarFp.getSubscriptionList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubscriptionListQuery(options?: AxiosRequestConfig): AxiosPromise<OutputDTOQueryFilterTemplateArray> {
            return localVarFp.getSubscriptionListQuery(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SubscriptionsApiUpdateSubscriptionExecutionRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubscriptionExecution(requestParameters: SubscriptionsApiUpdateSubscriptionExecutionRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOSubscription> {
            return localVarFp.updateSubscriptionExecution(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getSubscriptionList operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiGetSubscriptionListRequest
 */
export interface SubscriptionsApiGetSubscriptionListRequest {
    /**
     * 
     * @type {number}
     * @memberof SubscriptionsApiGetSubscriptionList
     */
    readonly skip: number

    /**
     * 
     * @type {number}
     * @memberof SubscriptionsApiGetSubscriptionList
     */
    readonly limit: number

    /**
     * 
     * @type {string}
     * @memberof SubscriptionsApiGetSubscriptionList
     */
    readonly sortField?: string

    /**
     * 
     * @type {SortDirection}
     * @memberof SubscriptionsApiGetSubscriptionList
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {string}
     * @memberof SubscriptionsApiGetSubscriptionList
     */
    readonly filters?: string
}

/**
 * Request parameters for updateSubscriptionExecution operation in SubscriptionsApi.
 * @export
 * @interface SubscriptionsApiUpdateSubscriptionExecutionRequest
 */
export interface SubscriptionsApiUpdateSubscriptionExecutionRequest {
    /**
     * 
     * @type {string}
     * @memberof SubscriptionsApiUpdateSubscriptionExecution
     */
    readonly id: string
}

/**
 * SubscriptionsApi - object-oriented interface
 * @export
 * @class SubscriptionsApi
 * @extends {BaseAPI}
 */
export class SubscriptionsApi extends BaseAPI {
    /**
     * 
     * @param {SubscriptionsApiGetSubscriptionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getSubscriptionList(requestParameters: SubscriptionsApiGetSubscriptionListRequest, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getSubscriptionList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public getSubscriptionListQuery(options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).getSubscriptionListQuery(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SubscriptionsApiUpdateSubscriptionExecutionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubscriptionsApi
     */
    public updateSubscriptionExecution(requestParameters: SubscriptionsApiUpdateSubscriptionExecutionRequest, options?: AxiosRequestConfig) {
        return SubscriptionsApiFp(this.configuration).updateSubscriptionExecution(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TasksApi - axios parameter creator
 * @export
 */
export const TasksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} ticketId 
         * @param {CreateTaskBody} createTaskBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask: async (ticketId: string, createTaskBody: CreateTaskBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('createTask', 'ticketId', ticketId)
            // verify required parameter 'createTaskBody' is not null or undefined
            assertParamExists('createTask', 'createTaskBody', createTaskBody)
            const localVarPath = `/api/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ticketId !== undefined) {
                localVarQueryParameter['ticketId'] = ticketId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateTaskUserBody} createTaskUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskUser: async (id: string, createTaskUserBody: CreateTaskUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createTaskUser', 'id', id)
            // verify required parameter 'createTaskUserBody' is not null or undefined
            assertParamExists('createTaskUser', 'createTaskUserBody', createTaskUserBody)
            const localVarPath = `/api/task/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createTaskUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTask', 'id', id)
            const localVarPath = `/api/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskUser: async (id: string, userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTaskUser', 'id', id)
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteTaskUser', 'userId', userId)
            const localVarPath = `/api/task/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTaskBody} updateTaskBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask: async (id: string, updateTaskBody: UpdateTaskBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTask', 'id', id)
            // verify required parameter 'updateTaskBody' is not null or undefined
            assertParamExists('updateTask', 'updateTaskBody', updateTaskBody)
            const localVarPath = `/api/task`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTaskStatusBody} updateTaskStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskStatus: async (id: string, updateTaskStatusBody: UpdateTaskStatusBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTaskStatus', 'id', id)
            // verify required parameter 'updateTaskStatusBody' is not null or undefined
            assertParamExists('updateTaskStatus', 'updateTaskStatusBody', updateTaskStatusBody)
            const localVarPath = `/api/task/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTaskStatusBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TasksApi - functional programming interface
 * @export
 */
export const TasksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TasksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} ticketId 
         * @param {CreateTaskBody} createTaskBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTask(ticketId: string, createTaskBody: CreateTaskBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTask(ticketId, createTaskBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi.createTask']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {CreateTaskUserBody} createTaskUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTaskUser(id: string, createTaskUserBody: CreateTaskUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTaskUser(id, createTaskUserBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi.createTaskUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTask(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTask(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi.deleteTask']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTaskUser(id: string, userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTaskUser(id, userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi.deleteTaskUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTaskBody} updateTaskBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTask(id: string, updateTaskBody: UpdateTaskBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTask(id, updateTaskBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi.updateTask']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTaskStatusBody} updateTaskStatusBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTaskStatus(id: string, updateTaskStatusBody: UpdateTaskStatusBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTask>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTaskStatus(id, updateTaskStatusBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TasksApi.updateTaskStatus']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TasksApi - factory interface
 * @export
 */
export const TasksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TasksApiFp(configuration)
    return {
        /**
         * 
         * @param {TasksApiCreateTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTask(requestParameters: TasksApiCreateTaskRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTask> {
            return localVarFp.createTask(requestParameters.ticketId, requestParameters.createTaskBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TasksApiCreateTaskUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTaskUser(requestParameters: TasksApiCreateTaskUserRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTask> {
            return localVarFp.createTaskUser(requestParameters.id, requestParameters.createTaskUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TasksApiDeleteTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTask(requestParameters: TasksApiDeleteTaskRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.deleteTask(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TasksApiDeleteTaskUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTaskUser(requestParameters: TasksApiDeleteTaskUserRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTask> {
            return localVarFp.deleteTaskUser(requestParameters.id, requestParameters.userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TasksApiUpdateTaskRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTask(requestParameters: TasksApiUpdateTaskRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTask> {
            return localVarFp.updateTask(requestParameters.id, requestParameters.updateTaskBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TasksApiUpdateTaskStatusRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTaskStatus(requestParameters: TasksApiUpdateTaskStatusRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTask> {
            return localVarFp.updateTaskStatus(requestParameters.id, requestParameters.updateTaskStatusBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTask operation in TasksApi.
 * @export
 * @interface TasksApiCreateTaskRequest
 */
export interface TasksApiCreateTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiCreateTask
     */
    readonly ticketId: string

    /**
     * 
     * @type {CreateTaskBody}
     * @memberof TasksApiCreateTask
     */
    readonly createTaskBody: CreateTaskBody
}

/**
 * Request parameters for createTaskUser operation in TasksApi.
 * @export
 * @interface TasksApiCreateTaskUserRequest
 */
export interface TasksApiCreateTaskUserRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiCreateTaskUser
     */
    readonly id: string

    /**
     * 
     * @type {CreateTaskUserBody}
     * @memberof TasksApiCreateTaskUser
     */
    readonly createTaskUserBody: CreateTaskUserBody
}

/**
 * Request parameters for deleteTask operation in TasksApi.
 * @export
 * @interface TasksApiDeleteTaskRequest
 */
export interface TasksApiDeleteTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiDeleteTask
     */
    readonly id: string
}

/**
 * Request parameters for deleteTaskUser operation in TasksApi.
 * @export
 * @interface TasksApiDeleteTaskUserRequest
 */
export interface TasksApiDeleteTaskUserRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiDeleteTaskUser
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof TasksApiDeleteTaskUser
     */
    readonly userId: string
}

/**
 * Request parameters for updateTask operation in TasksApi.
 * @export
 * @interface TasksApiUpdateTaskRequest
 */
export interface TasksApiUpdateTaskRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiUpdateTask
     */
    readonly id: string

    /**
     * 
     * @type {UpdateTaskBody}
     * @memberof TasksApiUpdateTask
     */
    readonly updateTaskBody: UpdateTaskBody
}

/**
 * Request parameters for updateTaskStatus operation in TasksApi.
 * @export
 * @interface TasksApiUpdateTaskStatusRequest
 */
export interface TasksApiUpdateTaskStatusRequest {
    /**
     * 
     * @type {string}
     * @memberof TasksApiUpdateTaskStatus
     */
    readonly id: string

    /**
     * 
     * @type {UpdateTaskStatusBody}
     * @memberof TasksApiUpdateTaskStatus
     */
    readonly updateTaskStatusBody: UpdateTaskStatusBody
}

/**
 * TasksApi - object-oriented interface
 * @export
 * @class TasksApi
 * @extends {BaseAPI}
 */
export class TasksApi extends BaseAPI {
    /**
     * 
     * @param {TasksApiCreateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public createTask(requestParameters: TasksApiCreateTaskRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).createTask(requestParameters.ticketId, requestParameters.createTaskBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TasksApiCreateTaskUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public createTaskUser(requestParameters: TasksApiCreateTaskUserRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).createTaskUser(requestParameters.id, requestParameters.createTaskUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TasksApiDeleteTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteTask(requestParameters: TasksApiDeleteTaskRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteTask(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TasksApiDeleteTaskUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public deleteTaskUser(requestParameters: TasksApiDeleteTaskUserRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).deleteTaskUser(requestParameters.id, requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TasksApiUpdateTaskRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTask(requestParameters: TasksApiUpdateTaskRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTask(requestParameters.id, requestParameters.updateTaskBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TasksApiUpdateTaskStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TasksApi
     */
    public updateTaskStatus(requestParameters: TasksApiUpdateTaskStatusRequest, options?: AxiosRequestConfig) {
        return TasksApiFp(this.configuration).updateTaskStatus(requestParameters.id, requestParameters.updateTaskStatusBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TicketsApi - axios parameter creator
 * @export
 */
export const TicketsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} title 
         * @param {string} details 
         * @param {string} location 
         * @param {TicketPriorityEnum} priority 
         * @param {string} departmentId 
         * @param {string} [voucherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket: async (title: string, details: string, location: string, priority: TicketPriorityEnum, departmentId: string, voucherId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'title' is not null or undefined
            assertParamExists('createTicket', 'title', title)
            // verify required parameter 'details' is not null or undefined
            assertParamExists('createTicket', 'details', details)
            // verify required parameter 'location' is not null or undefined
            assertParamExists('createTicket', 'location', location)
            // verify required parameter 'priority' is not null or undefined
            assertParamExists('createTicket', 'priority', priority)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('createTicket', 'departmentId', departmentId)
            const localVarPath = `/api/ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (title !== undefined) {
                localVarQueryParameter['title'] = title;
            }

            if (details !== undefined) {
                localVarQueryParameter['details'] = details;
            }

            if (location !== undefined) {
                localVarQueryParameter['location'] = location;
            }

            if (priority !== undefined) {
                localVarQueryParameter['priority'] = priority;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (voucherId !== undefined) {
                localVarQueryParameter['voucherId'] = voucherId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {string} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketResponse: async (ticketId: string, content: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('createTicketResponse', 'ticketId', ticketId)
            // verify required parameter 'content' is not null or undefined
            assertParamExists('createTicketResponse', 'content', content)
            const localVarPath = `/api/ticket/response`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ticketId !== undefined) {
                localVarQueryParameter['ticketId'] = ticketId;
            }

            if (content !== undefined) {
                localVarQueryParameter['content'] = content;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteTicket', 'id', id)
            const localVarPath = `/api/ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {string} responseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketResponse: async (ticketId: string, responseId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('deleteTicketResponse', 'ticketId', ticketId)
            // verify required parameter 'responseId' is not null or undefined
            assertParamExists('deleteTicketResponse', 'responseId', responseId)
            const localVarPath = `/api/ticket/response`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ticketId !== undefined) {
                localVarQueryParameter['ticketId'] = ticketId;
            }

            if (responseId !== undefined) {
                localVarQueryParameter['responseId'] = responseId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTicketList: async (skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getMyTicketList', 'skip', skip)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getMyTicketList', 'limit', limit)
            const localVarPath = `/api/ticket/my-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTicketListQuery: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ticket/my-list/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicket', 'id', id)
            const localVarPath = `/api/ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketList: async (skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getTicketList', 'skip', skip)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getTicketList', 'limit', limit)
            const localVarPath = `/api/ticket/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketListQuery: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ticket/list/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketPDF: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketPDF', 'id', id)
            const localVarPath = `/api/ticket/pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketServiceCallDeliveries: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTicketServiceCallDeliveries', 'id', id)
            const localVarPath = `/api/ticket/serviceCall/deliveries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceExpensesBody} updateServiceExpensesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceExpenses: async (id: string, updateServiceExpensesBody: UpdateServiceExpensesBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateServiceExpenses', 'id', id)
            // verify required parameter 'updateServiceExpensesBody' is not null or undefined
            assertParamExists('updateServiceExpenses', 'updateServiceExpensesBody', updateServiceExpensesBody)
            const localVarPath = `/api/ticket/service/expenses`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateServiceExpensesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceTimesBody} updateServiceTimesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceTimes: async (id: string, updateServiceTimesBody: UpdateServiceTimesBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateServiceTimes', 'id', id)
            // verify required parameter 'updateServiceTimesBody' is not null or undefined
            assertParamExists('updateServiceTimes', 'updateServiceTimesBody', updateServiceTimesBody)
            const localVarPath = `/api/ticket/service/times`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateServiceTimesBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceWorkersBody} updateServiceWorkersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceWorkers: async (id: string, updateServiceWorkersBody: UpdateServiceWorkersBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateServiceWorkers', 'id', id)
            // verify required parameter 'updateServiceWorkersBody' is not null or undefined
            assertParamExists('updateServiceWorkers', 'updateServiceWorkersBody', updateServiceWorkersBody)
            const localVarPath = `/api/ticket/service/workers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateServiceWorkersBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTicketBody} updateTicketBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicket: async (id: string, updateTicketBody: UpdateTicketBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTicket', 'id', id)
            // verify required parameter 'updateTicketBody' is not null or undefined
            assertParamExists('updateTicket', 'updateTicketBody', updateTicketBody)
            const localVarPath = `/api/ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTicketBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTicketDetailsBody} updateTicketDetailsBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketDetails: async (id: string, updateTicketDetailsBody: UpdateTicketDetailsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateTicketDetails', 'id', id)
            // verify required parameter 'updateTicketDetailsBody' is not null or undefined
            assertParamExists('updateTicketDetails', 'updateTicketDetailsBody', updateTicketDetailsBody)
            const localVarPath = `/api/ticket/details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTicketDetailsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {string} responseId 
         * @param {UpdateTicketResponseBody} updateTicketResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketResponse: async (ticketId: string, responseId: string, updateTicketResponseBody: UpdateTicketResponseBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('updateTicketResponse', 'ticketId', ticketId)
            // verify required parameter 'responseId' is not null or undefined
            assertParamExists('updateTicketResponse', 'responseId', responseId)
            // verify required parameter 'updateTicketResponseBody' is not null or undefined
            assertParamExists('updateTicketResponse', 'updateTicketResponseBody', updateTicketResponseBody)
            const localVarPath = `/api/ticket/response`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (ticketId !== undefined) {
                localVarQueryParameter['ticketId'] = ticketId;
            }

            if (responseId !== undefined) {
                localVarQueryParameter['responseId'] = responseId;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTicketResponseBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TicketsApi - functional programming interface
 * @export
 */
export const TicketsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TicketsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} title 
         * @param {string} details 
         * @param {string} location 
         * @param {TicketPriorityEnum} priority 
         * @param {string} departmentId 
         * @param {string} [voucherId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicket(title: string, details: string, location: string, priority: TicketPriorityEnum, departmentId: string, voucherId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicket(title, details, location, priority, departmentId, voucherId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.createTicket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {string} content 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTicketResponse(ticketId: string, content: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTicketResponse(ticketId, content, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.createTicketResponse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicket(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.deleteTicket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {string} responseId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTicketResponse(ticketId: string, responseId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTicketResponse(ticketId, responseId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.deleteTicketResponse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyTicketList(skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOPaginatedListTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyTicketList(skip, limit, sortField, sortDirection, filters, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.getMyTicketList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMyTicketListQuery(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOQueryFilterTemplateArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMyTicketListQuery(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.getMyTicketListQuery']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicket(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicket(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.getTicket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketList(skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOPaginatedListTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketList(skip, limit, sortField, sortDirection, filters, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.getTicketList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketListQuery(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOQueryFilterTemplateArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketListQuery(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.getTicketListQuery']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketPDF(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTicketServiceCallDeliveries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketPDF(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.getTicketPDF']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTicketServiceCallDeliveries(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTicketServiceCallDeliveries200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTicketServiceCallDeliveries(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.getTicketServiceCallDeliveries']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceExpensesBody} updateServiceExpensesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceExpenses(id: string, updateServiceExpensesBody: UpdateServiceExpensesBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceExpenses(id, updateServiceExpensesBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.updateServiceExpenses']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceTimesBody} updateServiceTimesBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceTimes(id: string, updateServiceTimesBody: UpdateServiceTimesBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceTimes(id, updateServiceTimesBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.updateServiceTimes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateServiceWorkersBody} updateServiceWorkersBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceWorkers(id: string, updateServiceWorkersBody: UpdateServiceWorkersBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceWorkers(id, updateServiceWorkersBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.updateServiceWorkers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTicketBody} updateTicketBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicket(id: string, updateTicketBody: UpdateTicketBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicket(id, updateTicketBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.updateTicket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateTicketDetailsBody} updateTicketDetailsBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicketDetails(id: string, updateTicketDetailsBody: UpdateTicketDetailsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicketDetails(id, updateTicketDetailsBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.updateTicketDetails']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} ticketId 
         * @param {string} responseId 
         * @param {UpdateTicketResponseBody} updateTicketResponseBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTicketResponse(ticketId: string, responseId: string, updateTicketResponseBody: UpdateTicketResponseBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOTicket>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTicketResponse(ticketId, responseId, updateTicketResponseBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TicketsApi.updateTicketResponse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TicketsApi - factory interface
 * @export
 */
export const TicketsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TicketsApiFp(configuration)
    return {
        /**
         * 
         * @param {TicketsApiCreateTicketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicket(requestParameters: TicketsApiCreateTicketRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.createTicket(requestParameters.title, requestParameters.details, requestParameters.location, requestParameters.priority, requestParameters.departmentId, requestParameters.voucherId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiCreateTicketResponseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketResponse(requestParameters: TicketsApiCreateTicketResponseRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.createTicketResponse(requestParameters.ticketId, requestParameters.content, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiDeleteTicketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicket(requestParameters: TicketsApiDeleteTicketRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.deleteTicket(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiDeleteTicketResponseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTicketResponse(requestParameters: TicketsApiDeleteTicketResponseRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.deleteTicketResponse(requestParameters.ticketId, requestParameters.responseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiGetMyTicketListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTicketList(requestParameters: TicketsApiGetMyTicketListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOPaginatedListTicket> {
            return localVarFp.getMyTicketList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyTicketListQuery(options?: AxiosRequestConfig): AxiosPromise<OutputDTOQueryFilterTemplateArray> {
            return localVarFp.getMyTicketListQuery(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiGetTicketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicket(requestParameters: TicketsApiGetTicketRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.getTicket(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiGetTicketListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketList(requestParameters: TicketsApiGetTicketListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOPaginatedListTicket> {
            return localVarFp.getTicketList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketListQuery(options?: AxiosRequestConfig): AxiosPromise<OutputDTOQueryFilterTemplateArray> {
            return localVarFp.getTicketListQuery(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiGetTicketPDFRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketPDF(requestParameters: TicketsApiGetTicketPDFRequest, options?: AxiosRequestConfig): AxiosPromise<GetTicketServiceCallDeliveries200Response> {
            return localVarFp.getTicketPDF(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiGetTicketServiceCallDeliveriesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicketServiceCallDeliveries(requestParameters: TicketsApiGetTicketServiceCallDeliveriesRequest, options?: AxiosRequestConfig): AxiosPromise<GetTicketServiceCallDeliveries200Response> {
            return localVarFp.getTicketServiceCallDeliveries(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiUpdateServiceExpensesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceExpenses(requestParameters: TicketsApiUpdateServiceExpensesRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.updateServiceExpenses(requestParameters.id, requestParameters.updateServiceExpensesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiUpdateServiceTimesRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceTimes(requestParameters: TicketsApiUpdateServiceTimesRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.updateServiceTimes(requestParameters.id, requestParameters.updateServiceTimesBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiUpdateServiceWorkersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceWorkers(requestParameters: TicketsApiUpdateServiceWorkersRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.updateServiceWorkers(requestParameters.id, requestParameters.updateServiceWorkersBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiUpdateTicketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicket(requestParameters: TicketsApiUpdateTicketRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.updateTicket(requestParameters.id, requestParameters.updateTicketBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiUpdateTicketDetailsRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketDetails(requestParameters: TicketsApiUpdateTicketDetailsRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.updateTicketDetails(requestParameters.id, requestParameters.updateTicketDetailsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {TicketsApiUpdateTicketResponseRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTicketResponse(requestParameters: TicketsApiUpdateTicketResponseRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOTicket> {
            return localVarFp.updateTicketResponse(requestParameters.ticketId, requestParameters.responseId, requestParameters.updateTicketResponseBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createTicket operation in TicketsApi.
 * @export
 * @interface TicketsApiCreateTicketRequest
 */
export interface TicketsApiCreateTicketRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiCreateTicket
     */
    readonly title: string

    /**
     * 
     * @type {string}
     * @memberof TicketsApiCreateTicket
     */
    readonly details: string

    /**
     * 
     * @type {string}
     * @memberof TicketsApiCreateTicket
     */
    readonly location: string

    /**
     * 
     * @type {TicketPriorityEnum}
     * @memberof TicketsApiCreateTicket
     */
    readonly priority: TicketPriorityEnum

    /**
     * 
     * @type {string}
     * @memberof TicketsApiCreateTicket
     */
    readonly departmentId: string

    /**
     * 
     * @type {string}
     * @memberof TicketsApiCreateTicket
     */
    readonly voucherId?: string
}

/**
 * Request parameters for createTicketResponse operation in TicketsApi.
 * @export
 * @interface TicketsApiCreateTicketResponseRequest
 */
export interface TicketsApiCreateTicketResponseRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiCreateTicketResponse
     */
    readonly ticketId: string

    /**
     * 
     * @type {string}
     * @memberof TicketsApiCreateTicketResponse
     */
    readonly content: string
}

/**
 * Request parameters for deleteTicket operation in TicketsApi.
 * @export
 * @interface TicketsApiDeleteTicketRequest
 */
export interface TicketsApiDeleteTicketRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiDeleteTicket
     */
    readonly id: string
}

/**
 * Request parameters for deleteTicketResponse operation in TicketsApi.
 * @export
 * @interface TicketsApiDeleteTicketResponseRequest
 */
export interface TicketsApiDeleteTicketResponseRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiDeleteTicketResponse
     */
    readonly ticketId: string

    /**
     * 
     * @type {string}
     * @memberof TicketsApiDeleteTicketResponse
     */
    readonly responseId: string
}

/**
 * Request parameters for getMyTicketList operation in TicketsApi.
 * @export
 * @interface TicketsApiGetMyTicketListRequest
 */
export interface TicketsApiGetMyTicketListRequest {
    /**
     * 
     * @type {number}
     * @memberof TicketsApiGetMyTicketList
     */
    readonly skip: number

    /**
     * 
     * @type {number}
     * @memberof TicketsApiGetMyTicketList
     */
    readonly limit: number

    /**
     * 
     * @type {string}
     * @memberof TicketsApiGetMyTicketList
     */
    readonly sortField?: string

    /**
     * 
     * @type {SortDirection}
     * @memberof TicketsApiGetMyTicketList
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {string}
     * @memberof TicketsApiGetMyTicketList
     */
    readonly filters?: string
}

/**
 * Request parameters for getTicket operation in TicketsApi.
 * @export
 * @interface TicketsApiGetTicketRequest
 */
export interface TicketsApiGetTicketRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiGetTicket
     */
    readonly id: string
}

/**
 * Request parameters for getTicketList operation in TicketsApi.
 * @export
 * @interface TicketsApiGetTicketListRequest
 */
export interface TicketsApiGetTicketListRequest {
    /**
     * 
     * @type {number}
     * @memberof TicketsApiGetTicketList
     */
    readonly skip: number

    /**
     * 
     * @type {number}
     * @memberof TicketsApiGetTicketList
     */
    readonly limit: number

    /**
     * 
     * @type {string}
     * @memberof TicketsApiGetTicketList
     */
    readonly sortField?: string

    /**
     * 
     * @type {SortDirection}
     * @memberof TicketsApiGetTicketList
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {string}
     * @memberof TicketsApiGetTicketList
     */
    readonly filters?: string
}

/**
 * Request parameters for getTicketPDF operation in TicketsApi.
 * @export
 * @interface TicketsApiGetTicketPDFRequest
 */
export interface TicketsApiGetTicketPDFRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiGetTicketPDF
     */
    readonly id: string
}

/**
 * Request parameters for getTicketServiceCallDeliveries operation in TicketsApi.
 * @export
 * @interface TicketsApiGetTicketServiceCallDeliveriesRequest
 */
export interface TicketsApiGetTicketServiceCallDeliveriesRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiGetTicketServiceCallDeliveries
     */
    readonly id: string
}

/**
 * Request parameters for updateServiceExpenses operation in TicketsApi.
 * @export
 * @interface TicketsApiUpdateServiceExpensesRequest
 */
export interface TicketsApiUpdateServiceExpensesRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiUpdateServiceExpenses
     */
    readonly id: string

    /**
     * 
     * @type {UpdateServiceExpensesBody}
     * @memberof TicketsApiUpdateServiceExpenses
     */
    readonly updateServiceExpensesBody: UpdateServiceExpensesBody
}

/**
 * Request parameters for updateServiceTimes operation in TicketsApi.
 * @export
 * @interface TicketsApiUpdateServiceTimesRequest
 */
export interface TicketsApiUpdateServiceTimesRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiUpdateServiceTimes
     */
    readonly id: string

    /**
     * 
     * @type {UpdateServiceTimesBody}
     * @memberof TicketsApiUpdateServiceTimes
     */
    readonly updateServiceTimesBody: UpdateServiceTimesBody
}

/**
 * Request parameters for updateServiceWorkers operation in TicketsApi.
 * @export
 * @interface TicketsApiUpdateServiceWorkersRequest
 */
export interface TicketsApiUpdateServiceWorkersRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiUpdateServiceWorkers
     */
    readonly id: string

    /**
     * 
     * @type {UpdateServiceWorkersBody}
     * @memberof TicketsApiUpdateServiceWorkers
     */
    readonly updateServiceWorkersBody: UpdateServiceWorkersBody
}

/**
 * Request parameters for updateTicket operation in TicketsApi.
 * @export
 * @interface TicketsApiUpdateTicketRequest
 */
export interface TicketsApiUpdateTicketRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiUpdateTicket
     */
    readonly id: string

    /**
     * 
     * @type {UpdateTicketBody}
     * @memberof TicketsApiUpdateTicket
     */
    readonly updateTicketBody: UpdateTicketBody
}

/**
 * Request parameters for updateTicketDetails operation in TicketsApi.
 * @export
 * @interface TicketsApiUpdateTicketDetailsRequest
 */
export interface TicketsApiUpdateTicketDetailsRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiUpdateTicketDetails
     */
    readonly id: string

    /**
     * 
     * @type {UpdateTicketDetailsBody}
     * @memberof TicketsApiUpdateTicketDetails
     */
    readonly updateTicketDetailsBody: UpdateTicketDetailsBody
}

/**
 * Request parameters for updateTicketResponse operation in TicketsApi.
 * @export
 * @interface TicketsApiUpdateTicketResponseRequest
 */
export interface TicketsApiUpdateTicketResponseRequest {
    /**
     * 
     * @type {string}
     * @memberof TicketsApiUpdateTicketResponse
     */
    readonly ticketId: string

    /**
     * 
     * @type {string}
     * @memberof TicketsApiUpdateTicketResponse
     */
    readonly responseId: string

    /**
     * 
     * @type {UpdateTicketResponseBody}
     * @memberof TicketsApiUpdateTicketResponse
     */
    readonly updateTicketResponseBody: UpdateTicketResponseBody
}

/**
 * TicketsApi - object-oriented interface
 * @export
 * @class TicketsApi
 * @extends {BaseAPI}
 */
export class TicketsApi extends BaseAPI {
    /**
     * 
     * @param {TicketsApiCreateTicketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public createTicket(requestParameters: TicketsApiCreateTicketRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).createTicket(requestParameters.title, requestParameters.details, requestParameters.location, requestParameters.priority, requestParameters.departmentId, requestParameters.voucherId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiCreateTicketResponseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public createTicketResponse(requestParameters: TicketsApiCreateTicketResponseRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).createTicketResponse(requestParameters.ticketId, requestParameters.content, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiDeleteTicketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public deleteTicket(requestParameters: TicketsApiDeleteTicketRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).deleteTicket(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiDeleteTicketResponseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public deleteTicketResponse(requestParameters: TicketsApiDeleteTicketResponseRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).deleteTicketResponse(requestParameters.ticketId, requestParameters.responseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiGetMyTicketListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getMyTicketList(requestParameters: TicketsApiGetMyTicketListRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getMyTicketList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getMyTicketListQuery(options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getMyTicketListQuery(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiGetTicketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getTicket(requestParameters: TicketsApiGetTicketRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getTicket(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiGetTicketListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getTicketList(requestParameters: TicketsApiGetTicketListRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getTicketList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getTicketListQuery(options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getTicketListQuery(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiGetTicketPDFRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getTicketPDF(requestParameters: TicketsApiGetTicketPDFRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getTicketPDF(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiGetTicketServiceCallDeliveriesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public getTicketServiceCallDeliveries(requestParameters: TicketsApiGetTicketServiceCallDeliveriesRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).getTicketServiceCallDeliveries(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiUpdateServiceExpensesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public updateServiceExpenses(requestParameters: TicketsApiUpdateServiceExpensesRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).updateServiceExpenses(requestParameters.id, requestParameters.updateServiceExpensesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiUpdateServiceTimesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public updateServiceTimes(requestParameters: TicketsApiUpdateServiceTimesRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).updateServiceTimes(requestParameters.id, requestParameters.updateServiceTimesBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiUpdateServiceWorkersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public updateServiceWorkers(requestParameters: TicketsApiUpdateServiceWorkersRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).updateServiceWorkers(requestParameters.id, requestParameters.updateServiceWorkersBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiUpdateTicketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public updateTicket(requestParameters: TicketsApiUpdateTicketRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).updateTicket(requestParameters.id, requestParameters.updateTicketBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiUpdateTicketDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public updateTicketDetails(requestParameters: TicketsApiUpdateTicketDetailsRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).updateTicketDetails(requestParameters.id, requestParameters.updateTicketDetailsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {TicketsApiUpdateTicketResponseRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TicketsApi
     */
    public updateTicketResponse(requestParameters: TicketsApiUpdateTicketResponseRequest, options?: AxiosRequestConfig) {
        return TicketsApiFp(this.configuration).updateTicketResponse(requestParameters.ticketId, requestParameters.responseId, requestParameters.updateTicketResponseBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateRegisteredUserBody} createRegisteredUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegisteredUser: async (createRegisteredUserBody: CreateRegisteredUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRegisteredUserBody' is not null or undefined
            assertParamExists('createRegisteredUser', 'createRegisteredUserBody', createRegisteredUserBody)
            const localVarPath = `/api/user/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRegisteredUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserBody} createUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (createUserBody: CreateUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserBody' is not null or undefined
            assertParamExists('createUser', 'createUserBody', createUserBody)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPasswordChangeConfirmation: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('createUserPasswordChangeConfirmation', 'email', email)
            const localVarPath = `/api/user/passwordConfirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUser', 'id', id)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserList: async (skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getUserList', 'skip', skip)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getUserList', 'limit', limit)
            const localVarPath = `/api/user/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserListQuery: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user/list/query`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMyUserBody} updateMyUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyUser: async (updateMyUserBody: UpdateMyUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMyUserBody' is not null or undefined
            assertParamExists('updateMyUser', 'updateMyUserBody', updateMyUserBody)
            const localVarPath = `/api/user/my`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMyUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserBody} updateUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser: async (id: string, updateUserBody: UpdateUserBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUser', 'id', id)
            // verify required parameter 'updateUserBody' is not null or undefined
            assertParamExists('updateUser', 'updateUserBody', updateUserBody)
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserActivation: async (code: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('updateUserActivation', 'code', code)
            const localVarPath = `/api/user/activate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} email 
         * @param {UpdateUserPasswordBody} updateUserPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword: async (email: string, updateUserPasswordBody: UpdateUserPasswordBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('updateUserPassword', 'email', email)
            // verify required parameter 'updateUserPasswordBody' is not null or undefined
            assertParamExists('updateUserPassword', 'updateUserPasswordBody', updateUserPasswordBody)
            const localVarPath = `/api/user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPasswordBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateRegisteredUserBody} createRegisteredUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRegisteredUser(createRegisteredUserBody: CreateRegisteredUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRegisteredUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRegisteredUser(createRegisteredUserBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.createRegisteredUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CreateUserBody} createUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(createUserBody: CreateUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(createUserBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.createUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUserPasswordChangeConfirmation(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUserPasswordChangeConfirmation(email, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.createUserPasswordChangeConfirmation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.deleteUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserList(skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOPaginatedListUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserList(skip, limit, sortField, sortDirection, filters, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.getUserList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserListQuery(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOQueryFilterTemplateArray>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserListQuery(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.getUserListQuery']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateMyUserBody} updateMyUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMyUser(updateMyUserBody: UpdateMyUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMyUser(updateMyUserBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.updateMyUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserBody} updateUserBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUser(id: string, updateUserBody: UpdateUserBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUser(id, updateUserBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.updateUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserActivation(code: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateRegisteredUser200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserActivation(code, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.updateUserActivation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} email 
         * @param {UpdateUserPasswordBody} updateUserPasswordBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserPassword(email: string, updateUserPasswordBody: UpdateUserPasswordBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserPassword(email, updateUserPasswordBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UsersApi.updateUserPassword']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {UsersApiCreateRegisteredUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegisteredUser(requestParameters: UsersApiCreateRegisteredUserRequest, options?: AxiosRequestConfig): AxiosPromise<CreateRegisteredUser200Response> {
            return localVarFp.createRegisteredUser(requestParameters.createRegisteredUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiCreateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(requestParameters: UsersApiCreateUserRequest, options?: AxiosRequestConfig): AxiosPromise<CreateUser200Response> {
            return localVarFp.createUser(requestParameters.createUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiCreateUserPasswordChangeConfirmationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUserPasswordChangeConfirmation(requestParameters: UsersApiCreateUserPasswordChangeConfirmationRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.createUserPasswordChangeConfirmation(requestParameters.email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiDeleteUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(requestParameters: UsersApiDeleteUserRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOUser> {
            return localVarFp.deleteUser(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiGetUserListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserList(requestParameters: UsersApiGetUserListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOPaginatedListUser> {
            return localVarFp.getUserList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserListQuery(options?: AxiosRequestConfig): AxiosPromise<OutputDTOQueryFilterTemplateArray> {
            return localVarFp.getUserListQuery(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUpdateMyUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyUser(requestParameters: UsersApiUpdateMyUserRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.updateMyUser(requestParameters.updateMyUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUpdateUserRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUser(requestParameters: UsersApiUpdateUserRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.updateUser(requestParameters.id, requestParameters.updateUserBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUpdateUserActivationRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserActivation(requestParameters: UsersApiUpdateUserActivationRequest, options?: AxiosRequestConfig): AxiosPromise<CreateRegisteredUser200Response> {
            return localVarFp.updateUserActivation(requestParameters.code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UsersApiUpdateUserPasswordRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserPassword(requestParameters: UsersApiUpdateUserPasswordRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTO> {
            return localVarFp.updateUserPassword(requestParameters.email, requestParameters.updateUserPasswordBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createRegisteredUser operation in UsersApi.
 * @export
 * @interface UsersApiCreateRegisteredUserRequest
 */
export interface UsersApiCreateRegisteredUserRequest {
    /**
     * 
     * @type {CreateRegisteredUserBody}
     * @memberof UsersApiCreateRegisteredUser
     */
    readonly createRegisteredUserBody: CreateRegisteredUserBody
}

/**
 * Request parameters for createUser operation in UsersApi.
 * @export
 * @interface UsersApiCreateUserRequest
 */
export interface UsersApiCreateUserRequest {
    /**
     * 
     * @type {CreateUserBody}
     * @memberof UsersApiCreateUser
     */
    readonly createUserBody: CreateUserBody
}

/**
 * Request parameters for createUserPasswordChangeConfirmation operation in UsersApi.
 * @export
 * @interface UsersApiCreateUserPasswordChangeConfirmationRequest
 */
export interface UsersApiCreateUserPasswordChangeConfirmationRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiCreateUserPasswordChangeConfirmation
     */
    readonly email: string
}

/**
 * Request parameters for deleteUser operation in UsersApi.
 * @export
 * @interface UsersApiDeleteUserRequest
 */
export interface UsersApiDeleteUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiDeleteUser
     */
    readonly id: string
}

/**
 * Request parameters for getUserList operation in UsersApi.
 * @export
 * @interface UsersApiGetUserListRequest
 */
export interface UsersApiGetUserListRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUserList
     */
    readonly skip: number

    /**
     * 
     * @type {number}
     * @memberof UsersApiGetUserList
     */
    readonly limit: number

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserList
     */
    readonly sortField?: string

    /**
     * 
     * @type {SortDirection}
     * @memberof UsersApiGetUserList
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {string}
     * @memberof UsersApiGetUserList
     */
    readonly filters?: string
}

/**
 * Request parameters for updateMyUser operation in UsersApi.
 * @export
 * @interface UsersApiUpdateMyUserRequest
 */
export interface UsersApiUpdateMyUserRequest {
    /**
     * 
     * @type {UpdateMyUserBody}
     * @memberof UsersApiUpdateMyUser
     */
    readonly updateMyUserBody: UpdateMyUserBody
}

/**
 * Request parameters for updateUser operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserRequest
 */
export interface UsersApiUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUpdateUser
     */
    readonly id: string

    /**
     * 
     * @type {UpdateUserBody}
     * @memberof UsersApiUpdateUser
     */
    readonly updateUserBody: UpdateUserBody
}

/**
 * Request parameters for updateUserActivation operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserActivationRequest
 */
export interface UsersApiUpdateUserActivationRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUpdateUserActivation
     */
    readonly code: string
}

/**
 * Request parameters for updateUserPassword operation in UsersApi.
 * @export
 * @interface UsersApiUpdateUserPasswordRequest
 */
export interface UsersApiUpdateUserPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUpdateUserPassword
     */
    readonly email: string

    /**
     * 
     * @type {UpdateUserPasswordBody}
     * @memberof UsersApiUpdateUserPassword
     */
    readonly updateUserPasswordBody: UpdateUserPasswordBody
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {UsersApiCreateRegisteredUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createRegisteredUser(requestParameters: UsersApiCreateRegisteredUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createRegisteredUser(requestParameters.createRegisteredUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiCreateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUser(requestParameters: UsersApiCreateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUser(requestParameters.createUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiCreateUserPasswordChangeConfirmationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public createUserPasswordChangeConfirmation(requestParameters: UsersApiCreateUserPasswordChangeConfirmationRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).createUserPasswordChangeConfirmation(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiDeleteUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public deleteUser(requestParameters: UsersApiDeleteUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).deleteUser(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiGetUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserList(requestParameters: UsersApiGetUserListRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getUserListQuery(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).getUserListQuery(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUpdateMyUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateMyUser(requestParameters: UsersApiUpdateMyUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateMyUser(requestParameters.updateMyUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUpdateUserRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUser(requestParameters: UsersApiUpdateUserRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUser(requestParameters.id, requestParameters.updateUserBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUpdateUserActivationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserActivation(requestParameters: UsersApiUpdateUserActivationRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserActivation(requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUpdateUserPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateUserPassword(requestParameters: UsersApiUpdateUserPasswordRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).updateUserPassword(requestParameters.email, requestParameters.updateUserPasswordBody, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * VouchersApi - axios parameter creator
 * @export
 */
export const VouchersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {CreateVoucherBody} createVoucherBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVoucher: async (createVoucherBody: CreateVoucherBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createVoucherBody' is not null or undefined
            assertParamExists('createVoucher', 'createVoucherBody', createVoucherBody)
            const localVarPath = `/api/voucher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createVoucherBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVoucher: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteVoucher', 'id', id)
            const localVarPath = `/api/voucher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoucher: async (id: string, ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVoucher', 'id', id)
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('getVoucher', 'ticketId', ticketId)
            const localVarPath = `/api/voucher`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }

            if (ticketId !== undefined) {
                localVarQueryParameter['ticketId'] = ticketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoucherList: async (skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'skip' is not null or undefined
            assertParamExists('getVoucherList', 'skip', skip)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getVoucherList', 'limit', limit)
            const localVarPath = `/api/voucher/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (sortField !== undefined) {
                localVarQueryParameter['sortField'] = sortField;
            }

            if (sortDirection !== undefined) {
                localVarQueryParameter['sortDirection'] = sortDirection;
            }

            if (filters !== undefined) {
                localVarQueryParameter['filters'] = filters;
            }

            if (skip !== undefined) {
                localVarQueryParameter['skip'] = skip;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateVoucherLimitBody} updateVoucherLimitBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoucherLimit: async (id: string, updateVoucherLimitBody: UpdateVoucherLimitBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVoucherLimit', 'id', id)
            // verify required parameter 'updateVoucherLimitBody' is not null or undefined
            assertParamExists('updateVoucherLimit', 'updateVoucherLimitBody', updateVoucherLimitBody)
            const localVarPath = `/api/voucher/limit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVoucherLimitBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoucherTicket: async (id: string, ticketId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVoucherTicket', 'id', id)
            // verify required parameter 'ticketId' is not null or undefined
            assertParamExists('updateVoucherTicket', 'ticketId', ticketId)
            const localVarPath = `/api/voucher/ticket`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }

            if (ticketId !== undefined) {
                localVarQueryParameter['ticketId'] = ticketId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateVoucherUsageBody} updateVoucherUsageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoucherUsage: async (id: string, updateVoucherUsageBody: UpdateVoucherUsageBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVoucherUsage', 'id', id)
            // verify required parameter 'updateVoucherUsageBody' is not null or undefined
            assertParamExists('updateVoucherUsage', 'updateVoucherUsageBody', updateVoucherUsageBody)
            const localVarPath = `/api/voucher/usage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['_id'] = id;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateVoucherUsageBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VouchersApi - functional programming interface
 * @export
 */
export const VouchersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VouchersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {CreateVoucherBody} createVoucherBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVoucher(createVoucherBody: CreateVoucherBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOVoucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVoucher(createVoucherBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VouchersApi.createVoucher']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteVoucher(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOVoucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteVoucher(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VouchersApi.deleteVoucher']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoucher(id: string, ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOVoucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoucher(id, ticketId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VouchersApi.getVoucher']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} skip 
         * @param {number} limit 
         * @param {string} [sortField] 
         * @param {SortDirection} [sortDirection] 
         * @param {string} [filters] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVoucherList(skip: number, limit: number, sortField?: string, sortDirection?: SortDirection, filters?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOPaginatedListVoucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVoucherList(skip, limit, sortField, sortDirection, filters, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VouchersApi.getVoucherList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateVoucherLimitBody} updateVoucherLimitBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVoucherLimit(id: string, updateVoucherLimitBody: UpdateVoucherLimitBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOVoucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVoucherLimit(id, updateVoucherLimitBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VouchersApi.updateVoucherLimit']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {string} ticketId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVoucherTicket(id: string, ticketId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOVoucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVoucherTicket(id, ticketId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VouchersApi.updateVoucherTicket']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateVoucherUsageBody} updateVoucherUsageBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVoucherUsage(id: string, updateVoucherUsageBody: UpdateVoucherUsageBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDTOVoucher>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVoucherUsage(id, updateVoucherUsageBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['VouchersApi.updateVoucherUsage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * VouchersApi - factory interface
 * @export
 */
export const VouchersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VouchersApiFp(configuration)
    return {
        /**
         * 
         * @param {VouchersApiCreateVoucherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVoucher(requestParameters: VouchersApiCreateVoucherRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOVoucher> {
            return localVarFp.createVoucher(requestParameters.createVoucherBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiDeleteVoucherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteVoucher(requestParameters: VouchersApiDeleteVoucherRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOVoucher> {
            return localVarFp.deleteVoucher(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiGetVoucherRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoucher(requestParameters: VouchersApiGetVoucherRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOVoucher> {
            return localVarFp.getVoucher(requestParameters.id, requestParameters.ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiGetVoucherListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVoucherList(requestParameters: VouchersApiGetVoucherListRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOPaginatedListVoucher> {
            return localVarFp.getVoucherList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiUpdateVoucherLimitRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoucherLimit(requestParameters: VouchersApiUpdateVoucherLimitRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOVoucher> {
            return localVarFp.updateVoucherLimit(requestParameters.id, requestParameters.updateVoucherLimitBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiUpdateVoucherTicketRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoucherTicket(requestParameters: VouchersApiUpdateVoucherTicketRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOVoucher> {
            return localVarFp.updateVoucherTicket(requestParameters.id, requestParameters.ticketId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VouchersApiUpdateVoucherUsageRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVoucherUsage(requestParameters: VouchersApiUpdateVoucherUsageRequest, options?: AxiosRequestConfig): AxiosPromise<OutputDTOVoucher> {
            return localVarFp.updateVoucherUsage(requestParameters.id, requestParameters.updateVoucherUsageBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createVoucher operation in VouchersApi.
 * @export
 * @interface VouchersApiCreateVoucherRequest
 */
export interface VouchersApiCreateVoucherRequest {
    /**
     * 
     * @type {CreateVoucherBody}
     * @memberof VouchersApiCreateVoucher
     */
    readonly createVoucherBody: CreateVoucherBody
}

/**
 * Request parameters for deleteVoucher operation in VouchersApi.
 * @export
 * @interface VouchersApiDeleteVoucherRequest
 */
export interface VouchersApiDeleteVoucherRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiDeleteVoucher
     */
    readonly id: string
}

/**
 * Request parameters for getVoucher operation in VouchersApi.
 * @export
 * @interface VouchersApiGetVoucherRequest
 */
export interface VouchersApiGetVoucherRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetVoucher
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetVoucher
     */
    readonly ticketId: string
}

/**
 * Request parameters for getVoucherList operation in VouchersApi.
 * @export
 * @interface VouchersApiGetVoucherListRequest
 */
export interface VouchersApiGetVoucherListRequest {
    /**
     * 
     * @type {number}
     * @memberof VouchersApiGetVoucherList
     */
    readonly skip: number

    /**
     * 
     * @type {number}
     * @memberof VouchersApiGetVoucherList
     */
    readonly limit: number

    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetVoucherList
     */
    readonly sortField?: string

    /**
     * 
     * @type {SortDirection}
     * @memberof VouchersApiGetVoucherList
     */
    readonly sortDirection?: SortDirection

    /**
     * 
     * @type {string}
     * @memberof VouchersApiGetVoucherList
     */
    readonly filters?: string
}

/**
 * Request parameters for updateVoucherLimit operation in VouchersApi.
 * @export
 * @interface VouchersApiUpdateVoucherLimitRequest
 */
export interface VouchersApiUpdateVoucherLimitRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiUpdateVoucherLimit
     */
    readonly id: string

    /**
     * 
     * @type {UpdateVoucherLimitBody}
     * @memberof VouchersApiUpdateVoucherLimit
     */
    readonly updateVoucherLimitBody: UpdateVoucherLimitBody
}

/**
 * Request parameters for updateVoucherTicket operation in VouchersApi.
 * @export
 * @interface VouchersApiUpdateVoucherTicketRequest
 */
export interface VouchersApiUpdateVoucherTicketRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiUpdateVoucherTicket
     */
    readonly id: string

    /**
     * 
     * @type {string}
     * @memberof VouchersApiUpdateVoucherTicket
     */
    readonly ticketId: string
}

/**
 * Request parameters for updateVoucherUsage operation in VouchersApi.
 * @export
 * @interface VouchersApiUpdateVoucherUsageRequest
 */
export interface VouchersApiUpdateVoucherUsageRequest {
    /**
     * 
     * @type {string}
     * @memberof VouchersApiUpdateVoucherUsage
     */
    readonly id: string

    /**
     * 
     * @type {UpdateVoucherUsageBody}
     * @memberof VouchersApiUpdateVoucherUsage
     */
    readonly updateVoucherUsageBody: UpdateVoucherUsageBody
}

/**
 * VouchersApi - object-oriented interface
 * @export
 * @class VouchersApi
 * @extends {BaseAPI}
 */
export class VouchersApi extends BaseAPI {
    /**
     * 
     * @param {VouchersApiCreateVoucherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public createVoucher(requestParameters: VouchersApiCreateVoucherRequest, options?: AxiosRequestConfig) {
        return VouchersApiFp(this.configuration).createVoucher(requestParameters.createVoucherBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiDeleteVoucherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public deleteVoucher(requestParameters: VouchersApiDeleteVoucherRequest, options?: AxiosRequestConfig) {
        return VouchersApiFp(this.configuration).deleteVoucher(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiGetVoucherRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public getVoucher(requestParameters: VouchersApiGetVoucherRequest, options?: AxiosRequestConfig) {
        return VouchersApiFp(this.configuration).getVoucher(requestParameters.id, requestParameters.ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiGetVoucherListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public getVoucherList(requestParameters: VouchersApiGetVoucherListRequest, options?: AxiosRequestConfig) {
        return VouchersApiFp(this.configuration).getVoucherList(requestParameters.skip, requestParameters.limit, requestParameters.sortField, requestParameters.sortDirection, requestParameters.filters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiUpdateVoucherLimitRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public updateVoucherLimit(requestParameters: VouchersApiUpdateVoucherLimitRequest, options?: AxiosRequestConfig) {
        return VouchersApiFp(this.configuration).updateVoucherLimit(requestParameters.id, requestParameters.updateVoucherLimitBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiUpdateVoucherTicketRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public updateVoucherTicket(requestParameters: VouchersApiUpdateVoucherTicketRequest, options?: AxiosRequestConfig) {
        return VouchersApiFp(this.configuration).updateVoucherTicket(requestParameters.id, requestParameters.ticketId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VouchersApiUpdateVoucherUsageRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VouchersApi
     */
    public updateVoucherUsage(requestParameters: VouchersApiUpdateVoucherUsageRequest, options?: AxiosRequestConfig) {
        return VouchersApiFp(this.configuration).updateVoucherUsage(requestParameters.id, requestParameters.updateVoucherUsageBody, options).then((request) => request(this.axios, this.basePath));
    }
}



