import { Box, Paper, Typography, useTheme } from "@mui/material";
import { BarChart, BarItem } from "@mui/x-charts";
import { DashboardData, DateRange } from "../../../services/swagger";

interface DashboardTicketCountProps {
  data: DashboardData;
}

export default function DashboardTicketCount(props: DashboardTicketCountProps) {
  const { data } = props;
  const ticketCount = data.ticketCount;
  const theme = useTheme();
  return (
    <Paper sx={{ bgcolor: "background.default" }}>
      <Typography
        color="primary"
        variant="h3"
        fontSize={20}
        fontWeight="bold"
        pb={1}
        pt={4}
        pl={4}
      >
        Tickets
      </Typography>
      <BarChart
        series={[{ data: ticketCount.map((e) => e.count) }]}
        barLabel="value"
        yAxis={[{ tickMinStep: 1, disableTicks: true }]}
        xAxis={[
          {
            data: ticketCount.map((e) => e.label),
            scaleType: "band",
            disableTicks: true,
          },
        ]}
        borderRadius={4}
        colors={[theme.palette.primary.main]}
        height={300}
      />
    </Paper>
  );
}
