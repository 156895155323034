import { Box, ButtonBase, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import PdfIcon from "../../assets/pdf-icon.webp";
import FileIcon from "../../assets/Files-High-Quality-PNG.png";
import useDownload from "../../hooks/useDownload";
import { Attachment as ResponseAttachment } from "../../services/swagger";
import ImageDialog from "./ImageDialog";
import settings from "../../settings";

interface AttachmentProps {
  file?: File;
  attachment?: ResponseAttachment;
  upload?: boolean;
  src?: string;
}

export default function Attachment(props: AttachmentProps) {
  const [open, setOpen] = useState(false);
  const [src, setSrc] = useState("");
  const [downloadPath, setDownloadPath] = useState("");
  const [filename, setFilename] = useState("");

  const { download, DownloadLink } = useDownload();

  useEffect(() => {
    if (!props.file && !props.attachment) return;
    if (props.upload) {
      if (props.file?.name) setFilename(props.file.name);
      if (props.file?.type?.startsWith("image"))
        setSrc(URL.createObjectURL(props.file));
      else if (props.file?.type === "application/pdf") setSrc(PdfIcon);
      else if (props.src) setSrc(props.src);
      else setSrc(FileIcon);
    } else if (props.attachment) {
      if (props.attachment.name) {
        setFilename(props.attachment.name);
        setDownloadPath(props.attachment.path);
      }
      if (props.attachment.type === "image")
        setSrc(
          `${settings.baseUrl}/api/ftp/file?path=${encodeURI(
            props.attachment.path
          )}`
        );
      else if (props.attachment.type === "pdf") setSrc(PdfIcon);
      else if (props.src) setSrc(props.src);
      else setSrc(FileIcon);
    }
  }, [props.file, props.attachment]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickImage = () => {
    if ((props.file || props.attachment)?.type?.startsWith("image"))
      setOpen(true);
    else if (!props.upload) download(downloadPath);
  };

  return (
    <>
      {!props.upload && <DownloadLink />}
      <ImageDialog
        filename={props.file?.name || props.attachment?.name || ""}
        open={open}
        src={src}
        downloadPath={downloadPath}
        onClose={handleClose}
      />
      <Box>
        <ButtonBase onClick={handleClickImage} sx={{ borderRadius: 1 }}>
          <img
            src={src}
            style={{
              height: 100,
              width: "100%",
              objectFit: "cover",
              borderRadius: "inherit",
            }}
          />
        </ButtonBase>
        <br />
        {filename && (
          <Typography align="center" textOverflow="ellipsis" noWrap>
            {filename}
          </Typography>
        )}
      </Box>
    </>
  );
}
