import {
  Box,
  CircularProgress,
  Divider,
  Menu,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import DateAndName from "../../molecules/DateAndName";
import { Ticket } from "../../../services/swagger";
import Authorized from "../../atoms/Authorized";
import { MouseEvent, useEffect, useState } from "react";
import useRefresh from "../../../hooks/useRefresh";
import useConfirm from "../../../hooks/useConfirm";
import { OpenAiService, TicketService } from "../../../services/api";
import FormInput from "../../molecules/FormInput";
import EditButton from "../../molecules/EditButton";
import TranslateButton from "../../atoms/TranslateButton";
import IconAvatar from "../../atoms/IconAvatar";
import CloseIcon from "@mui/icons-material/Close";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import SummarizeButton from "../../atoms/SummarizeButton";
import AskAiButton from "../../atoms/AskAiButton";
import React from "react";
import AskAiResponseDialog from "../../molecules/AskAiResponseDialog";
import AskAiDialog from "../../molecules/AskAiDialog";
import AiMenu from "../../molecules/AiMenu";
import useBreakpoint from "../../../hooks/useBreakpoint";

interface TicketDetailsProps {
  ticket?: Ticket;
}

export default function TicketDetails(props: TicketDetailsProps) {
  const [editMode, setEditMode] = useState(false);
  const [aiResponseMode, setAiResponseMode] = useState(false);
  const [aiResponseText, setAiResponseText] = useState("");
  const [isAIDialogLoading, setIsAIDialogLoading] = useState(false);
  const [isAIResponseDialogLoading, setIsAIResponseDialogLoading] =
    useState(false);
  const [content, setContent] = useState<string>(props.ticket?.details || "");
  const [openAskAIDialog, setOpenAskAIDialog] = useState(false);
  const [openAskAIResponseDialog, setOpenAskAIResponseDialog] = useState(false);
  const refresh = useRefresh();
  const { ConfirmDialog, confirm } = useConfirm();

  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (props.ticket?.details) setContent(props.ticket.details);
  }, [props.ticket]);

  // const handleEdit = () => {
  //   setEditMode(true);
  // };

  const handleResponseClose = () => {
    setAiResponseMode(false);
  };

  const handleTranslate = async () => {
    try {
      setIsAIDialogLoading(true);
      const response = await OpenAiService.createTranslatePrompt({
        createTranslateBody: {
          language: "en",
          message: content,
        },
      });

      setIsAIDialogLoading(false);
      setAiResponseText(response?.data?.data || "");
      setAiResponseMode(true);
    } catch (error) {
      console.error("Error translating text:", error);
      return "Translation error"; // Handle errors gracefully
    }
  };

  const handleSummarize = async () => {
    try {
      setIsAIDialogLoading(true);
      const response = await OpenAiService.createSummarizePrompt({
        createSummarizeBody: {
          language: "en",
          message: content,
        },
      });

      setIsAIDialogLoading(false);
      setAiResponseText(response?.data?.data || "");
      setAiResponseMode(true);
    } catch (error) {
      console.error("Error summarizing text:", error);
      return "Summarization error"; // Handle errors gracefully
    }
  };

  const handleAskAi = async (prompt: string) => {
    try {
      setOpenAskAIResponseDialog(true);
      setIsAIResponseDialogLoading(true);
      const response = await OpenAiService.createAskPrompt({
        createAskAiBody: {
          language: "en",
          message: prompt,
        },
      });

      setIsAIResponseDialogLoading(false);
      return response.data.data;
    } catch (error) {
      console.error("Error asking AI:", error);
      return "Asking AI error"; // Handle errors gracefully
    }
  };

  const handleClickOpen = () => {
    setOpenAskAIDialog(true);
  };

  const handleDialogClose = () => {
    setOpenAskAIDialog(false);
  };

  const handleSecClickOpen = () => {
    setOpenAskAIResponseDialog(true);
  };
  const handleSecDialogClose = () => {
    setOpenAskAIResponseDialog(false);
  };

  const handleClickSave = () => {
    confirm(handleUpdateDetails);
  };

  const handleUpdateDetails = async () => {
    if (!props.ticket?._id) return;
    const res = await TicketService.updateTicketDetails({
      id: props.ticket?._id,
      updateTicketDetailsBody: {
        content,
      },
    });
    setEditMode(false);
    setAiResponseMode(false);
    if (res?.data?.success) refresh();
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Paper sx={{ p: 2, bgcolor: "background.default" }}>
      <ConfirmDialog />
      <Stack spacing={1}>
        <Typography fontSize="large" fontWeight={600}>
          {props.ticket?.title}
        </Typography>
        {editMode ? (
          <FormInput
            field="content"
            value={content}
            onChange={(v) => setContent(v as string)}
            multiline
            label=""
            type="string"
            rows={4}
            maxRows={16}
          />
        ) : (
          <Typography whiteSpace="pre-wrap">{props.ticket?.details}</Typography>
        )}

        {isAIDialogLoading ? (
          <CircularProgress size={25} />
        ) : aiResponseMode ? (
          <Paper sx={{ p: 2, bgcolor: "background.default" }}>
            <Box display={"flex"} justifyContent="space-between">
              <Typography whiteSpace="pre-wrap">{aiResponseText}</Typography>
              <IconAvatar
                icon={CloseIcon}
                size={18}
                onClick={handleResponseClose}
              />
            </Box>
          </Paper>
        ) : (
          <></>
        )}

        <Box display="flex" alignItems="center" justifyContent="space-between">
          <DateAndName
            date={props.ticket?.date}
            name={props.ticket?.user?.name || props.ticket?.person}
          />
          <Stack spacing={1} direction="row">
            <Authorized role="agent">
              {!editMode && (
                <>
                  <IconAvatar
                    icon={AutoAwesomeOutlinedIcon}
                    size={30}
                    onClick={handleClick}
                    tooltip="AI Features"
                  />

                  <AiMenu
                    aiResponseMode={aiResponseMode}
                    editMode={editMode}
                    anchorEl={anchorEl}
                    onAsk={handleClickOpen}
                    onClose={handleClose}
                    onSummarize={handleSummarize}
                    onTranslate={handleTranslate}
                  />
                </>
              )}

              <AskAiDialog
                openDialog={openAskAIDialog}
                isDialogLoading={isAIResponseDialogLoading}
                onClose={handleDialogClose}
                content={content}
                onSubmit={async (event: React.FormEvent<HTMLFormElement>) => {
                  event.preventDefault();
                  const formData = new FormData(event.currentTarget);
                  const formJson = Object.fromEntries(
                    (formData as any).entries()
                  );
                  const aiprompt = formJson.aiprompt;
                  const aiResponse = await handleAskAi(aiprompt);
                  if (aiResponse) setAiResponseText(aiResponse);
                }}
              />

              <AskAiResponseDialog
                open={openAskAIResponseDialog}
                onClose={handleSecDialogClose}
                isDialogLoading={isAIResponseDialogLoading}
                response={aiResponseText}
              />

              <EditButton
                editMode={editMode}
                onSave={handleClickSave}
                onCancel={() => setEditMode(false)}
                onEdit={() => {
                  setEditMode(true);
                }}
              />
            </Authorized>
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );
}
