import { Box, Paper, Stack, Typography, useTheme } from "@mui/material";
import { PieChart } from "@mui/x-charts";
import { DashboardData } from "../../../services/swagger";
import CircleIcon from "@mui/icons-material/Circle";

interface DashboardTicketResolutionProps {
  data: DashboardData;
}

export default function DashboardTicketResolution(
  props: DashboardTicketResolutionProps
) {
  const { data } = props;
  const unsolvedTickets = data.unsolvedTickets;
  const solvedTickets = data.solvedTickets;
  const theme = useTheme();
  return (
    <Paper sx={{ bgcolor: "background.default" }}>
      <Typography
        color="primary"
        variant="h3"
        fontSize={20}
        fontWeight="bold"
        pt={4}
        pl={4}
      >
        Tickets Resolved
      </Typography>
      <Box pl={12} display="flex" justifyContent="flex-end">
        <PieChart
          series={[
            {
              arcLabel: (item) => `${item.value}`,
              // arcLabelRadius: 100,
              highlightScope: { faded: "global", highlighted: "item" },
              faded: {
                innerRadius: 10,
                additionalRadius: -10,
                color: theme.palette.divider,
              },
              data: [
                { value: unsolvedTickets, label: "Unsolved Tickets" },
                { value: solvedTickets, label: "Solved Tickets" },
              ],
            },
          ]}
          colors={[theme.palette.background.paper, theme.palette.primary.main]}
          legend={{
            hidden: true,
          }}
          width={300}
          height={300}
        />
      </Box>
      <Stack direction="row" spacing={2} justifyContent="center" mt={-4} pb={2}>
        <Typography fontWeight="light" display="flex" alignItems="center">
          <CircleIcon sx={{ color: theme.palette.background.paper, mr: 1 }} />
          Unsolved Tickets
        </Typography>
        <Typography fontWeight="light" display="flex" alignItems="center">
          <CircleIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
          Solved Tickets
        </Typography>
      </Stack>
    </Paper>
  );
}
