import { Box, ButtonBase, Grid } from "@mui/material";
import useBreakpoint from "../../hooks/useBreakpoint";
import { DateRange } from "../../services/swagger";
import { Paper } from "@mui/material";

interface DateRangeSelectorProps {
  range: DateRange;
  onChange?: (range: DateRange) => void;
}

export default function DateRangeSelector(props: DateRangeSelectorProps) {
  const breakpoint = useBreakpoint();
  return (
    <Box p={breakpoint.md ? undefined : 2}>
      <Grid container spacing={2}>
        <Grid item xs={6} md={3}>
          <Paper>
            <ButtonBase
              onClick={() => props.onChange?.(DateRange.Week)}
              sx={{
                borderRadius: "inherit",
                fontWeight: "bold",
                fontSize: 16,
                color:
                  props.range === DateRange.Week
                    ? "primary.contrastText"
                    : "primary.main",
                width: 1,
                height: 1,
                bgcolor:
                  props.range === DateRange.Week
                    ? "primary.main"
                    : "background.default",
                p: 2,
              }}
            >
              WEEK
            </ButtonBase>
          </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          <Paper>
            <ButtonBase
              onClick={() => props.onChange?.(DateRange.Month)}
              sx={{
                borderRadius: "inherit",
                fontWeight: "bold",
                fontSize: 16,
                color:
                  props.range === DateRange.Month
                    ? "primary.contrastText"
                    : "primary.main",
                width: 1,
                height: 1,
                bgcolor:
                  props.range === DateRange.Month
                    ? "primary.main"
                    : "background.default",
                p: 2,
              }}
            >
              MONTH
            </ButtonBase>
          </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          <Paper>
            <ButtonBase
              onClick={() => props.onChange?.(DateRange.Year)}
              sx={{
                borderRadius: "inherit",
                fontWeight: "bold",
                fontSize: 16,
                color:
                  props.range === DateRange.Year
                    ? "primary.contrastText"
                    : "primary.main",
                width: 1,
                height: 1,
                bgcolor:
                  props.range === DateRange.Year
                    ? "primary.main"
                    : "background.default",
                p: 2,
              }}
            >
              YEAR
            </ButtonBase>
          </Paper>
        </Grid>
        <Grid item xs={6} md={3}>
          <Paper>
            <ButtonBase
              onClick={() => props.onChange?.(DateRange.AllTime)}
              sx={{
                borderRadius: "inherit",
                fontWeight: "bold",
                fontSize: 16,
                color:
                  props.range === DateRange.AllTime
                    ? "primary.contrastText"
                    : "primary.main",
                width: 1,
                height: 1,
                bgcolor:
                  props.range === DateRange.AllTime
                    ? "primary.main"
                    : "background.default",
                p: 2,
              }}
            >
              ALL TIME
            </ButtonBase>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
