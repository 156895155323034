import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface AskAiResponseDialogProps {
  open: boolean;
  isDialogLoading: boolean;
  onClose: () => any;
  response: string;
}

export default function AskAiResponseDialog(props: AskAiResponseDialogProps) {
  return (
    <>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>AI Response</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            {props.isDialogLoading ? (
              <CircularProgress size={25} />
            ) : props.response ? (
              props.response
            ) : (
              <></>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
