import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { ServiceCall } from "../../../services/swagger";
import { useEffect, useState } from "react";
import FormTemplate from "../../templates/FormTemplate";
import { formatMilliseconds } from "../../../helpers/date";
import { SapService } from "../../../services/api";

interface TicketOldServiceReportDialogProps {
  serviceCall: ServiceCall;
}

export default function TicketOldServiceReportDialog(
  props: TicketOldServiceReportDialogProps
) {
  const { serviceCall } = props;
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [employees, setEmployees] = useState<any[]>([]);

  const loadEmployees = async () => {
    const res = await SapService.getEmployees();
    if (!res?.data?.data) return;
    setEmployees(res.data.data);
    console.log(res.data.data);
  };

  useEffect(() => {
    if (!props) return;
    loadEmployees();
  }, [open]);

  return (
    <Box>
      <Dialog open={open} onClose={handleClose}>
        <FormTemplate
          title={`Service Call # ${serviceCall.call_id}`}
          onCancel={handleClose}
          cancelButtonText="Close"
          disableSubmitButton
        >
          <Stack spacing={2}>
            <Box>
              <Typography fontWeight="bold">Expenses</Typography>
              <Divider />
            </Box>
            {serviceCall.expenses.map((expense, i) => {
              return (
                <Box>
                  <Stack spacing={1}>
                    <Typography fontWeight="bold">#{i + 1}</Typography>
                    <Box>
                      <Typography fontWeight="bold">Item No.</Typography>
                      <Typography>{expense.item_no}</Typography>
                    </Box>
                    <Box>
                      <Typography fontWeight="bold">Serial No.</Typography>
                      <Typography>{expense.serial_no}</Typography>
                    </Box>
                    <Box>
                      <Typography fontWeight="bold">Quantity</Typography>
                      <Typography>{expense.quantity}</Typography>
                    </Box>
                    {expense.description && (
                      <Typography variant="caption">
                        Description: {expense.description}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              );
            })}
            <Box>
              <Typography fontWeight="bold">Times</Typography>
              <Divider />
            </Box>
            {serviceCall.times.map((time, i) => {
              return (
                <Box>
                  <Stack spacing={1}>
                    <Typography fontWeight="bold">#{i + 1}</Typography>
                    <Typography>
                      {new Date(time.start).toLocaleString("tr")} -{" "}
                      {new Date(time.end).toLocaleString("tr")}
                    </Typography>
                    <Typography>
                      Duration:{" "}
                      {formatMilliseconds(
                        new Date(time.end).getTime() -
                          new Date(time.start).getTime(),
                        { ignoreMilliseconds: true }
                      )}
                    </Typography>
                    {time.remarks && (
                      <Typography variant="caption">
                        Remarks: {time.remarks}
                      </Typography>
                    )}
                  </Stack>
                </Box>
              );
            })}
            <Box>
              <Stack spacing={2}>
                <Box>
                  <Typography fontWeight="bold">Workers</Typography>
                  <Divider />
                </Box>
                {serviceCall.workers.map((e) => {
                  const employee = employees.find((emp) => emp.empID === e);
                  if (!employee) return null;
                  return (
                    <Typography>
                      {employee.firstName} {employee.lastName}
                    </Typography>
                  );
                })}
              </Stack>
            </Box>
          </Stack>
        </FormTemplate>
      </Dialog>
      <Button fullWidth variant="outlined" onClick={() => setOpen(true)}>
        View
      </Button>
    </Box>
  );
}
