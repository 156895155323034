import { useRef, useState } from "react";
import { TicketService } from "../../../services/api";
import DataTable, { QueryParameters } from "../../organisms/DataTable";
import { Button, Stack, TableCell } from "@mui/material";
import ManagementTemplate from "../../templates/ManagementTemplate";
import { PaginatedListTicket, Ticket } from "../../../services/swagger";
import { useNavigate } from "react-router";
import PriorityChip from "../../atoms/PriorityChip";
import AvatarWithInitials from "../../atoms/AvatarWithInitials";
import useRefresh from "../../../hooks/useRefresh";
import TicketStatus from "../../atoms/TicketStatus";

export default function TicketsPage() {
  const navigate = useNavigate();
  const lastQuery = useRef<QueryParameters>();
  const [tickets, setTickets] = useState<PaginatedListTicket>({
    count: 0,
    docs: [],
  });

  const loadTickets = async (query: QueryParameters) => {
    lastQuery.current = query;
    const res = await TicketService.getTicketList({
      limit: query.limit,
      skip: query.skip,
      filters: query.filters,
      sortField: query.sortField,
      sortDirection: query.sortDirection,
    });
    if (res?.data?.data) setTickets(res?.data?.data);
  };

  useRefresh(() => {
    if (lastQuery.current) loadTickets(lastQuery.current);
  }, "ticket");

  const handleDataTableChange = (query: QueryParameters) => {
    loadTickets(query);
  };

  return (
    <ManagementTemplate paper>
      <DataTable
        name="Tickets"
        button={{
          text: "New Ticket",
          onClick: () => navigate(`/m/ticket/create`),
        }}
        pagination
        content={tickets}
        headers={[
          {
            field: "person",
            name: "Person",
            type: "other",
            renderHeaderCell: () => <TableCell width={20}>Person</TableCell>,
            renderRowCell: (row: Ticket) => {
              return (
                <TableCell>
                  <AvatarWithInitials>
                    {row.user?.name || row.person || ""}
                  </AvatarWithInitials>
                </TableCell>
              );
            },
          },
          {
            field: "id",
            name: "ID",
            type: "number",
          },
          {
            field: "date",
            name: "Date",
            type: "date",
          },
          {
            field: "company",
            name: "Company",
            type: "string",
          },
          {
            field: "title",
            name: "Title",
            type: "string",
          },
          {
            field: "department",
            name: "Department",
            type: "other",
            renderRowCell: (row: Ticket) => {
              console.log(row);
              return <TableCell>{row?.department?.name}</TableCell>;
            },
          },
          {
            field: "status",
            name: "Status",
            type: "other",
            renderRowCell: (row: Ticket) => {
              return (
                <TableCell>
                  <TicketStatus status={row.status} />
                </TableCell>
              );
            },
          },
          {
            field: "service_call.call_id",
            name: "Service Call Number",
            type: "other",
            renderRowCell: (row: Ticket) => {
              return (
                <TableCell>
                  {row.service_call?.call_id || "Not Available"}
                </TableCell>
              );
            },
          },
          {
            field: "location",
            name: "Location",
            type: "other",
            renderRowCell: (row: Ticket) => (
              <TableCell>{row.city || row.location}</TableCell>
            ),
          },
          {
            field: "priority",
            name: "Priority",
            type: "other",
            renderHeaderCell: () => (
              <TableCell width={50} align="center">
                Priority
              </TableCell>
            ),
            renderRowCell: (row: Ticket) => {
              return (
                <TableCell align="center">
                  <PriorityChip priority={row.priority} />
                </TableCell>
              );
            },
          },
          {
            field: "delete",
            name: "",
            type: "other",
            renderHeaderCell: () => (
              <TableCell align="center" width={70}>
                Action
              </TableCell>
            ),
            renderRowCell: (row: Ticket) => (
              <TableCell>
                <Stack direction="row" spacing={1}>
                  <Button
                    fullWidth
                    size="small"
                    variant="contained"
                    onClick={() => navigate(`/m/ticket/${row._id}`)}
                  >
                    Manage
                  </Button>
                </Stack>
              </TableCell>
            ),
          },
        ]}
        queryFunction={TicketService.getTicketListQuery}
        onChange={handleDataTableChange}
      />
    </ManagementTemplate>
  );
}
