import { useEffect, useState } from "react";
import DateRangeSelector from "../molecules/DateRangeSelector";
import ManagementTemplate from "../templates/ManagementTemplate";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { DashboardService } from "../../services/api";
import { DashboardData, DateRange } from "../../services/swagger";
import DashboardTicketCount from "../organisms/Dashboard/DashboardTicketCount";
import DashboardTicketResolution from "../organisms/Dashboard/DashboardTicketResolution";
import DashboardStatusOverview from "../organisms/Dashboard/DashboardStatusOverview";
import DashboardTicketStats from "../organisms/Dashboard/DashboardTicketStats";
import useBreakpoint from "../../hooks/useBreakpoint";
import DashboardTasks from "../organisms/Dashboard/DashboardTasks";
import useRefresh from "../../hooks/useRefresh";

export default function DashboardPage() {
  const [range, setRange] = useState<DateRange>(DateRange.Month);
  const [data, setData] = useState<DashboardData>();
  const breakpoint = useBreakpoint();

  const loadDashboardData = async () => {
    const res = await DashboardService.getDashboard({ dateRange: range });
    if (res?.data?.data) {
      setData(res.data.data);
    }
  };

  const handleChangeRange = (range: DateRange) => {
    setRange(range);
  };

  useEffect(() => {
    loadDashboardData();
  }, [range]);

  useRefresh(loadDashboardData);

  if (!data) return null;

  return (
    <ManagementTemplate noPadding={!breakpoint.md}>
      <Box
        display="flex"
        flexDirection="column"
        height={1}
        sx={{
          WebkitUserSelect: "none",
          msUserSelect: "none",
          userSelect: "none",
        }}
      >
        <DateRangeSelector range={range} onChange={handleChangeRange} />
        <Box
          pt={2}
          flexGrow={breakpoint.md ? 1 : undefined}
          overflow={breakpoint.md ? "auto" : undefined}
        >
          <Box width={1} overflow="hidden" position="relative">
            {!breakpoint.md && (
              <Box height={1} width={1} zIndex={100} position="absolute"></Box>
            )}
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={4}>
                <DashboardTicketCount data={data} />
              </Grid>
              <Grid item xs={12} lg={4}>
                <DashboardStatusOverview data={data} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DashboardTicketResolution data={data} />
              </Grid>
              <Grid item xs={12}>
                <DashboardTicketStats data={data} />
              </Grid>
              <Grid item xs={12}>
                <Typography align="center" variant="h3">
                  Active Tasks
                </Typography>
                <DashboardTasks data={data} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </ManagementTemplate>
  );
}
