import PersonOffIcon from "@mui/icons-material/PersonOff";
import { Avatar, AvatarProps } from "@mui/material";

interface AvatarWithInitialsProps {
  size?: number;
  children: string;
}

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name: string, size?: number): AvatarProps {
  return {
    sx: {
      height: size || 32,
      width: size || 32,
      fontSize: (size || 32) / 2,
      bgcolor: name ? stringToColor(name) : undefined,
    },
    children: name ? (
      `${name
        .split(" ")
        .map((e) => e.charAt(0))
        .join("")}`
    ) : (
      <PersonOffIcon />
    ),
  };
}

export default function AvatarWithInitials(props: AvatarWithInitialsProps) {
  return <Avatar {...stringAvatar(props.children, props.size)} />;
}
