import {
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Switch,
  Typography,
} from "@mui/material";
import { FilterValueType, UserRole } from "../../services/swagger";
import Dropdown, { DropDownItem } from "./Dropdown";
import { HTMLInputTypeAttribute, useState } from "react";
import useRole from "../../hooks/useRole";
import { capitalizeFirstLetter } from "../../helpers/string";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export interface FormInputProps {
  label: string;
  field: string;
  type: FilterValueType | "chip-list" | "switch";
  placeholder?: string;
  inputType?: HTMLInputTypeAttribute;
  required?: boolean;
  multiline?: boolean;
  rows?: number;
  role?: UserRole;
  maxRows?: number;
  minRows?: number;
  readOnly?: boolean;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  onEnter?: () => any;
  list?: DropDownItem[];
  onChange?: (value: string | number | boolean | string[]) => void;
  value?: string | number | boolean | string[];
}

const booleanList: DropDownItem[] = [
  { name: "True", value: true },
  { name: "False", value: false },
];

export default function FormInput(props: FormInputProps) {
  const role = useRole();
  const [chipListMenu, setChipListMenu] = useState<HTMLElement | null>(null);
  const [value, setValue] = useState<string | number | boolean>("");
  const [visible, setVisible] = useState(false);

  const handleAddChip = () => {
    if (!Array.isArray(props.value)) return;
    if (!value) return;
    if (props.value.includes(value as string)) return;
    props.onChange?.([...props.value, value as string]);
    setValue("");
    setChipListMenu(null);
  };

  const handleDeleteChip = (index: number) => {
    if (!Array.isArray(props.value)) return;
    const copyChipList = [...props.value];
    copyChipList.splice(index, 1);
    props.onChange?.(copyChipList);
  };

  if (props.role && !role(props.role)) {
    if (!props.value) return null;
    if (props.type === "list" || props.type === "id") {
      return (
        <Box width={1}>
          <Typography
            fontWeight={400}
            fontSize={16}
            color="primary"
            gutterBottom
          >
            {props.label}
          </Typography>
          <Typography>
            {props.list?.find((e) => e.value === props.value)?.name || ""}
          </Typography>
        </Box>
      );
    } else if (props.type === "boolean") {
      return (
        <Box width={1}>
          <Typography
            fontWeight={400}
            fontSize={16}
            color="primary"
            gutterBottom
          >
            {props.label}
          </Typography>
          <Typography>
            {capitalizeFirstLetter(props.value.toString())}
          </Typography>
        </Box>
      );
    } else if (props.type === "date") {
      return (
        <Box width={1}>
          <Typography
            fontWeight={400}
            fontSize={16}
            color="primary"
            gutterBottom
          >
            {props.label}
          </Typography>
          <Typography>
            {new Date(props.value as string).toLocaleString("tr")}
          </Typography>
        </Box>
      );
    } else
      return (
        <Box width={1}>
          <Typography
            fontWeight={400}
            fontSize={16}
            color="primary"
            gutterBottom
          >
            {props.label}
          </Typography>
          <Typography>{props.value}</Typography>
        </Box>
      );
  } else if (props.type === FilterValueType.List)
    return (
      <Box width={1}>
        <Typography fontWeight={400} fontSize={16} color="primary" gutterBottom>
          {props.label}
          {props.required && (
            <Typography sx={{ display: "inline-block", color: "primary" }}>
              *
            </Typography>
          )}
        </Typography>
        <Dropdown
          endAdornment={props.endAdornment}
          readOnly={props.readOnly}
          unselectedValue={props.required ? undefined : "Any"}
          value={props.list?.find((e) => e.value === props.value) || ""}
          items={props.list || []}
          fullWidth
          size="small"
          placeholder={props.placeholder}
          disabled={props.disabled}
          required={props.required}
          onChange={(e, item?: DropDownItem) => {
            if (props.required && item) props.onChange?.(item.value);
            else if (!props.required) props.onChange?.(item?.value || "");
          }}
        />
      </Box>
    );
  else if (props.type === FilterValueType.Boolean)
    return (
      <Box width={1}>
        <Typography fontWeight={400} fontSize={16} color="primary" gutterBottom>
          {props.label}
          {props.required && (
            <Typography sx={{ display: "inline-block", color: "primary" }}>
              *
            </Typography>
          )}
        </Typography>
        <Dropdown
          endAdornment={props.endAdornment}
          readOnly={props.readOnly}
          unselectedValue={props.required ? undefined : "Any"}
          value={booleanList?.find((e) => e.value === props.value)}
          items={booleanList}
          fullWidth
          size="small"
          disabled={props.disabled}
          placeholder={props.placeholder}
          required
          onChange={(e, item: DropDownItem) => {
            if (item?.value !== undefined) props.onChange?.(item?.value);
          }}
        />
      </Box>
    );
  else if (props.type === "chip-list")
    return (
      <Box width={1}>
        <Menu
          disableAutoFocus
          disableAutoFocusItem
          anchorEl={chipListMenu}
          onClose={() => setChipListMenu(null)}
          onFocus={() => chipListMenu?.focus()}
          open={Boolean(chipListMenu)}
        >
          <MenuItem onClick={handleAddChip}>Add {value}...</MenuItem>
        </Menu>
        <Typography fontWeight={400} fontSize={16} color="primary" gutterBottom>
          {props.label}
          {props.required && (
            <Typography sx={{ display: "inline-block", color: "primary" }}>
              *
            </Typography>
          )}
        </Typography>
        <InputBase
          endAdornment={props.endAdornment}
          size="small"
          placeholder={props.placeholder}
          fullWidth
          value={value}
          disabled={props.disabled}
          required={props.required}
          onKeyDown={(e) => {
            if (e.code === "Enter") handleAddChip();
            if (e.code === "Backspace") {
              if (value === "")
                handleDeleteChip((props.value as string[]).length - 1);
            }
          }}
          onChange={(e) => {
            if (!e.target.value) setChipListMenu(null);
            else setChipListMenu(e.target);
            setValue(e.target.value);
          }}
          readOnly={props.readOnly}
          startAdornment={
            (props.value as string[]).length > 0 && (
              <Box
                mt={1}
                mb={1}
                mr={1}
                width="fit-content"
                maxWidth={3 / 4}
                display="flex"
                flexShrink={0}
                flexWrap="wrap"
                gap={1}
              >
                {(props.value as string[]).map((e, i) => (
                  <Chip
                    label={e}
                    key={e}
                    onDelete={() => handleDeleteChip(i)}
                  />
                ))}
              </Box>
            )
          }
        />
      </Box>
    );
  else if (props.type === "switch")
    return (
      <Box width={1}>
        <FormControlLabel
          control={
            <Switch
              onChange={(e) => props.onChange?.(e.target.checked)}
              defaultChecked={props.value as boolean}
            />
          }
          disabled={props.disabled}
          label={props.label}
          key={props.field}
          value={props.value}
          required={props.required}
        />
      </Box>
    );
  else
    return (
      <Box width={1}>
        <Typography fontWeight={400} fontSize={16} color="primary" gutterBottom>
          {props.label}
          {props.required && (
            <Typography sx={{ display: "inline-block", color: "primary" }}>
              *
            </Typography>
          )}
        </Typography>
        <InputBase
          size="small"
          placeholder={props.placeholder}
          fullWidth
          required={props.required}
          multiline={props.multiline}
          value={props.value}
          rows={props.rows}
          disabled={props.disabled}
          maxRows={props.maxRows}
          minRows={props.minRows}
          onKeyDown={(e) => {
            if (e.code === "Enter") props.onEnter?.();
          }}
          type={
            (props.inputType === "password"
              ? visible
                ? "text"
                : "password"
              : props.inputType) ??
            (props.type === FilterValueType.String ? "search" : props.type)
          }
          endAdornment={
            props.endAdornment
              ? props.endAdornment
              : props.inputType === "password" && (
                  <IconButton
                    sx={{ m: 0, p: 0 }}
                    onClick={() => setVisible((v) => !v)}
                  >
                    {visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                )
          }
          readOnly={props.readOnly}
          onChange={(e) => props.onChange?.(e.target.value)}
        />
      </Box>
    );
}
